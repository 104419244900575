import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import Confirmable from '../../components/Confirmable';
import PublicPagePermissionsDialog from './PublicPagePermissionsDialog';
import CustomThemeDialog from './CustomThemeDialog';
import { services } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: {},
  pos: {
    marginBottom: 12,
  },
  [theme.breakpoints.up('md')]: {
    list: {
      columnCount: 2,
    },
  },
  listItem: {
    display: 'inline-block', // Prevent wrapping inside the list item
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

function PublicPageCard({
  enabled,
  passportId,
  profile,
  onEnable,
  onDisable,
  onChangePermissions,
  onChangeTheme,
}) {
  const [permissionsMode, setPermissionsMode] = useState(undefined);
  const [themeOpen, setThemeOpen] = useState(false);

  const classes = useStyles();

  const handlePermissionsClose = () => setPermissionsMode(undefined);
  const handlePermissionsSuccess = (published, serviceOptions) => {
    setPermissionsMode(undefined);
    if (permissionsMode === 'ENABLE') {
      onEnable(published, serviceOptions);
    } else {
      onChangePermissions(published, serviceOptions);
    }
  };

  const handleThemeClose = () => setThemeOpen(false);
  const handleThemeSuccess = (published) => {
    setThemeOpen(false);
    onChangeTheme(published);
  };

  const includedInSearch =
    enabled &&
    Boolean(
      profile.enabledServices.find((s) => s.name === services.PUBLIC_PAGE)
        ?.searchEnabled
    );

  const publicPageURL = `https://publicpage.creativepassport.net/page/${passportId}/${profile.id}`;

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography component="h3" variant="h5">
            {profile.artistName}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {enabled
              ? `Enabled (${
                  includedInSearch
                    ? 'included in search'
                    : 'excluded from search'
                })`
              : 'Disabled'}
          </Typography>
          <Typography color="textSecondary">
            Use the buttons below to manage the public page for this persona.
          </Typography>
        </CardContent>
        <Grid container className={classes.actions} wrap="wrap">
          {enabled ? (
            <>
              <Grid item xs={12} md={6}>
                <Button
                  size="small"
                  color="primary"
                  href={publicPageURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Public Page
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setPermissionsMode('EDIT')}
                >
                  Edit permissions
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setThemeOpen(true)}
                >
                  Edit Theme
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Confirmable
                  title="Disable Public Page"
                  message="Are you sure you want to disable your public page?"
                  confirmButton="Disable Public Page"
                  onConfirm={onDisable}
                  render={(handleClick) => (
                    <Button size="small" color="primary" onClick={handleClick}>
                      Disable public page
                    </Button>
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button
                size="small"
                color="primary"
                onClick={() => setPermissionsMode('ENABLE')}
              >
                Enable public page
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
      <PublicPagePermissionsDialog
        mode={permissionsMode}
        onEnable={handlePermissionsSuccess}
        onClose={handlePermissionsClose}
        profile={profile}
      />
      <CustomThemeDialog
        open={themeOpen}
        profile={profile}
        onConfirm={handleThemeSuccess}
        onClose={handleThemeClose}
      />
    </>
  );
}

export default PublicPageCard;
