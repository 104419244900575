import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#DEE1E5" transform="translate(-312 -495)">
          <g transform="translate(300 487)">
            <g transform="translate(12 8)">
              <circle cx="12" cy="12" r="12"></circle>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
