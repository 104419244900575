import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import countries from 'i18n-iso-countries';
import AtomVerification from '../icons/atomVerification';
import { usePublicProfileContext } from '../containers/public-profile';
import { makeStyles, useTheme } from '@mui/styles';
import LocationIcon from '../icons/location';
import GenderIcon from '../icons/gender';
import DateofBirthIcon from '../icons/birthday';
import emptyAvatar from '../images/emptyavatar.svg';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const useStyles = makeStyles((theme) => ({
  bioSection: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
    display: 'flex',
    padding: '2rem',
    marginBottom: '2rem',
  },
  bioText: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      alignItems: 'center',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatarContainer: {
    [theme.breakpoints.only('xs')]: {
      width: '50%',
      paddingBottom: '50%',
      margin: 'auto',
    },
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    //padding: '0px',
    //display: 'flex',
    //justifyContent: 'left',
  },
  avatarWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    //width: '256px',
    //height: '256px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: '4px solid transparent',
    background:
      'linear-gradient(315deg, #FF2667 0%, #73AEE6 51%, #00FFCC 100%) border-box',
    maskImage:
      'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    '-webkitMaskComposite': 'destination-out',
  },
  avatarImage: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
    border: `5px solid ${theme.palette.background.paper}`,
  },
  artistName: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '36px',
    justifyContent: 'left',
  },
  verifiedIcon: {
    marginLeft: '1rem',
    height: '29px',
    width: '29px',
  },
  musicRole: {
    fontWeight: '500',
    fontSize: '28px',
    textTransform: 'capitalize',
  },
  location: {
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  gender: {
    fontSize: '18px',
  },
  dateOfBirth: {
    fontSize: '18px',
  },
  bioIcon: {
    marginRight: '0.5rem',
    width: '16px',
    height: '16px',
  },
}));

const InfoSection = () => {
  const theme = useTheme();
  const publicProfileData = usePublicProfileContext();
  const classes = useStyles();
  if (!publicProfileData) return null;
  const avatar = publicProfileData?.profileImage?.url || '';

  return (
    <Box>
      {/* Public Profile Page Personal Summary Section */}
      <Box className={classes.bioSection}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <div className={classes.avatarContainer}>
              <div className={classes.avatarWrapper}>
                <Box
                  component="img"
                  className={classes.avatarImage}
                  src={
                    publicProfileData?.profileImage?.url || avatar || undefined
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = emptyAvatar;
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            gap={3}
            item
            xs={12}
            sm={8}
            md={9}
            lg={9}
            className={classes.bioText}
          >
            <Typography className={classes.artistName}>
              {publicProfileData.artistName}
              {publicProfileData?.verifiedFields?.ipn?.toUpperCase() ===
              'PPL' ? (
                <AtomVerification className={classes.verifiedIcon} />
              ) : null}
            </Typography>

            {publicProfileData.musicRoles?.length && (
              <Typography className={classes.musicRole}>
                {publicProfileData.musicRoles.map((role, index) => (
                  <span key={index}>
                    {index <= 2 && (
                      <span>
                        {role}
                        {index !== 2 && ' | '}
                      </span>
                    )}
                  </span>
                ))}
              </Typography>
            )}

            {publicProfileData.features?.length > 0 ? (
              <Typography className={classes.musicRole}>
                {publicProfileData.features.slice(0, 3).join(' | ')}
              </Typography>
            ) : null}

            {publicProfileData.city || publicProfileData.countryAlpha2 ? (
              <Typography className={classes.location}>
                <LocationIcon
                  color={theme.palette.primary.hint}
                  className={classes.bioIcon}
                />
                {publicProfileData.city}
                {publicProfileData.city && publicProfileData.countryAlpha2
                  ? ', '
                  : ''}
                {countries.getName(publicProfileData.countryAlpha2, 'EN')}
              </Typography>
            ) : null}

            {publicProfileData.gender || publicProfileData.pronoun ? (
              <Typography className={classes.gender}>
                <GenderIcon
                  color={theme.palette.primary.hint}
                  className={classes.bioIcon}
                />
                {publicProfileData.gender}
                {publicProfileData.gender && publicProfileData.pronoun
                  ? ' | '
                  : ''}
                {publicProfileData.pronoun}
              </Typography>
            ) : null}

            {publicProfileData.dateOfBirth && (
              <Typography className={classes.dateOfBirth}>
                <DateofBirthIcon
                  color={theme.palette.primary.hint}
                  className={classes.bioIcon}
                />
                {publicProfileData.dateOfBirth}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InfoSection;
