import { buttonStyles } from '../../../layout/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  types: {
    transition: '0.3s',
    border: '1px solid #00000010',
    //'&:hover': {
    // border: '1px solid #757575',
    //},
    borderRadius: '8px',
    padding: '0.5rem 0.25rem',
    width: '95%',
    [theme.breakpoints.down('md')]: {
      width: '93%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  add: {
    borderRadius: '1rem',
  },
  ...buttonStyles,
});

export default styles;
