import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CPEditButton from './CPEditButton';

const styles = (theme) => ({
  topInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
});

const useStyles = makeStyles(styles);

const TitleInfo = ({ title, isNotEmpty, message, onClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.topInfo}>
      <Typography variant="h3">{title}</Typography>
      {isNotEmpty && <CPEditButton title={message} onClick={onClick} />}
    </Box>
  );
};

export default TitleInfo;
