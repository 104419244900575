import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Highlight } from './Highlight';
import LocationIcon from '../../icons/location';
import LinkIcon from '../../icons/link';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {},
  loc: {},
  result: {
    textTransform: 'capitalize',
  },
  content: {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 0,
  },
  locationTitle: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    color: '#181919',
    gap: 5,
    fontSize: '14px',
  },
  buttons: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    padding: '0.4rem',
  },
  bottomIconsChildIcon: {
    fontSize: '14px',
    color: '#626466',
  },
}));

const SearchCard = ({ persona }) => {
  const classes = useStyles();
  const publicProfileLink = `/page/${persona?.passportId}/${persona.id}`;

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        title={
          <Grid container justifyContent="space-between">
            <Grid item xs={11}>
              <Typography variant="h2" className={classes.name}>
                {persona.artistName}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="View public page">
                <IconButton
                  className={classes.buttons}
                  href={publicProfileLink}
                  target="_blank"
                >
                  <LinkIcon className={classes.bottomIconsChildIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        }
        subheader={
          <>
            {persona.city || persona.country ? (
              <Typography mt={2} className={classes.locationTitle}>
                <LocationIcon sx={{ width: '16px' }} />
                {[persona.city, persona.country].filter(Boolean).join(', ')}
              </Typography>
            ) : null}
          </>
        }
      />
      <CardContent>
        {persona._highlights ? (
          <Highlight highlights={persona._highlights} />
        ) : null}
      </CardContent>
      {/*<CardActions disableSpacing></CardActions>*/}
    </Card>
  );
};

export default SearchCard;
