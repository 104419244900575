import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import CPVisibleIcon from '../../CPVisibleIcon';
import OwnedKeyIcon from '../../../icons/ownedKey';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const useStyles = makeStyles(styles);

const Info = ({ Icon, title, titles, usedTitles, ownedTitles, dataField }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h4" className={classes.headingText}>
          <Icon />
          {title}
        </Typography>
        <CPVisibleIcon dataField={dataField} />
      </div>
      <Box className={classes.items}>
        {titles?.length ? (
          <div className={classes.items}>
            {titles.map((el, i) => (
              <div key={i} className={classes.itemFull}>
                <div className={classes.item}>{el}</div>
              </div>
            ))}
          </div>
        ) : null}
        {usedTitles?.length ? (
          <>
            <div className={classes.items}>
              {usedTitles.map((el, i) => (
                <div key={i} className={classes.itemFull}>
                  <div className={classes.item}>{el}</div>
                </div>
              ))}
            </div>
          </>
        ) : null}
        {ownedTitles && ownedTitles.length > 0 ? (
          <>
            <div className={classes.items}>
              {ownedTitles.map((el, i) => (
                <div key={i} className={classes.itemFull}>
                  <div className={classes.itemOwned}>
                    {el.title}
                    <OwnedKeyIcon className={classes.itemKey} />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </Box>
    </div>
  );
};

export default Info;
