import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CPVisibleIcon from '../../CPVisibleIcon';
import LabelBox from '../../LabelBox';

export const Id = ({ item }) => {
  return (
    <>
      {item.value && (
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: '1rem',
            '@media (max-width: 600px)': {
              borderRadius: '0rem',
            },
            padding: '1.5rem',
          }}
        >
          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <LabelBox>{item.fieldName}</LabelBox>
            <CPVisibleIcon dataField={item} />
          </Stack>
          <Typography
            variant="h5"
            sx={{
              mt: 1,
            }}
          >
            {item.value}
          </Typography>
        </Box>
      )}
    </>
  );
};
