import React, { useState } from 'react';
import {
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from 'react-query';
import { apiBase } from '../../scripts';
import SearchCard from './SearchCard';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/lab/PaginationItem';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SearchIcon from '../../icons/searchIcon.js';
import { useAuth } from '../auth/AuthProvider';
import Header from '../Header';
import PublicNavbar from '../PublicNavbar';
import Skeleton from '@mui/material/Skeleton';

const searchUrl = apiBase + '/public/1.0/search';

function useSearchQuery(query, { page }) {
  const queryPresent = Boolean(query) && query.length > 0;
  return useQuery(
    ['search-basic', query, page],
    () =>
      axios
        .get(searchUrl + '/basic', {
          params: { query, page },
        })
        .then((res) => res.data),
    {
      keepPreviousData: false,
      enabled: queryPresent,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
}

const useStyles = makeStyles((theme) => ({
  searchRow: {
    width: '100%',
    display: 'flex',
    marginBottom: 'theme.spacing(2)',
    paddingTop: '1rem',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '0rem',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'stretch',
  },
  searchField: {
    width: '100%',
    height: '50px',
    transition: '0.3s',
    //boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.075)',
    '&:hover': {
      boxShadow: 'none',
      borderBottom: '1px solid #000',

      //backgroundColor: theme.palette.background.paper,
      //borderRadius: '0.75rem',
      //borderBottom: '0px solid #C5C8CC',
    },
    borderBottom: '1px solid #C5C8CC',
  },
  input: {
    paddingLeft: '0px',
    backgroundColor: '#000!important',
  },
  searchButton: {
    marginLeft: '1rem',
    padding: '0rem 2rem',
    color: '#626467',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.background.paper,
  },
  searchicon: {
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    color: 'grey',
    fontSize: 18,
  },
  skeleton: {
    borderRadius: '1rem',
    width: '100%',
    height: '203px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  query: {
    marginLeft: '0rem',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1rem',
    },
  },
}));

function SearchPortal() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { authState } = useAuth();
  const urlParams = new URLSearchParams(location.search);
  const page = parseInt(urlParams.get('page') || '1', 10);
  const query = urlParams.get('q');
  const [searchField, setSearchField] = useState(query || '');

  const { data, isPreviousData, status } = useSearchQuery(query, { page });

  const handleSearchChange = (e) => {
    setSearchField(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchField && searchField !== '') {
      urlParams.set('q', searchField);
      urlParams.delete('page');
      history.push(`${location.pathname}?${urlParams.toString()}`);
    } else {
      history.push(location.pathname);
    }
  };

  const pagination =
    data && data.total > data.pageSize ? (
      <Grid item xs={12}>
        <Pagination
          disabled={isPreviousData}
          page={page}
          count={Math.ceil(data.total / data.pageSize)}
          renderItem={(item) => {
            if (item.page > 1) {
              urlParams.set('page', item.page);
            } else {
              urlParams.delete('page');
            }
            return (
              <PaginationItem
                component={Link}
                to={`${location.pathname}?${urlParams.toString()}`}
                {...item}
              />
            );
          }}
        />
      </Grid>
    ) : null;

  return (
    <>
      {authState === 'LOGGED_IN' ? (
        <Header signedIn={true} />
      ) : (
        <PublicNavbar />
      )}
      <Container>
        <form onSubmit={handleSubmit}>
          <div className={classes.searchRow}>
            <TextField
              sx={{
                '& fieldset': { border: 'none' },
              }}
              fullWidth
              id="standard-search"
              className={classes.searchField}
              value={searchField}
              placeholder="Search for professionals, artists, bands, and so on"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ paddingRight: '5px' }} position="start">
                    <SearchIcon className={classes.searchicon} />
                  </InputAdornment>
                ),
              }}
            />
            <Button className={classes.searchButton} type="submit">
              Go
            </Button>
          </div>
        </form>
        {status === 'loading' ? (
          <>
            <Typography my={2}>
              Searching passports for <b>{query}</b>…
            </Typography>
            <Grid container spacing={2} mb={4}>
              <Grid item md={6} xs={12}>
                <Skeleton variant="rounded" className={classes.skeleton} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Skeleton variant="rounded" className={classes.skeleton} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Skeleton variant="rounded" className={classes.skeleton} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Skeleton variant="rounded" className={classes.skeleton} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Skeleton variant="rounded" className={classes.skeleton} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Skeleton variant="rounded" className={classes.skeleton} />
              </Grid>
            </Grid>
          </>
        ) : data ? (
          <div>
            {data && (
              <div>
                <Typography my={2} className={classes.query}>
                  Search results for <b>{query}</b>:
                </Typography>
                {data.total > 0 ? (
                  <Grid container spacing={2} mb={4}>
                    {pagination}
                    {data.profiles.length > 0 &&
                      data.profiles.map((persona) => (
                        <Grid
                          item
                          key={persona.id}
                          md={6}
                          xs={12}
                          className={classes.gridItem}
                        >
                          <SearchCard persona={persona} />
                        </Grid>
                      ))}
                    {pagination}
                  </Grid>
                ) : (
                  <Typography>No Profiles Found</Typography>
                )}
              </div>
            )}
          </div>
        ) : null}
      </Container>
    </>
  );
}

export default SearchPortal;
