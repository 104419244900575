import { useTheme, makeStyles } from '@mui/styles';
import styles from './styles';
import Button from '../../button';
import useDisclosure from '../../../../hooks/useDisclosure';
import { Grid, IconButton, Box, Typography } from '@mui/material';
import TitleInfo from '../../../../components/Title';
import ToolTip from '../../../../icons/atomsTooltip.js';
import AboutProfiles from '../../../modals/AboutProfiles.js';

const useStyles = makeStyles(styles);

const WelcomePage = ({ moveForward }) => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    isOpen: isAboutProfilesOpen,
    onOpen: onAboutProfilesOpen,
    onClose: onAboutProfilesClose,
  } = useDisclosure(false);
  return (
    <div className={classes.root}>
      <AboutProfiles
        isOpen={isAboutProfilesOpen}
        onClose={onAboutProfilesClose}
      />
      <div className={classes.main}>
        <Box mb={3} className={classes.navbar}>
          <Box className={classes.navbarEl} sx={{ width: '35%' }}>
            <span className={classes.navbarElNum}>1</span>
            <p className={classes.navbarElP}>your profiles</p>
          </Box>
          <Box className={classes.navbarDiv}></Box>
          <Box className={classes.navbarEl} sx={{ width: '35%' }}>
            <span className={classes.navbarElNum}>2</span>
            <p className={classes.navbarElP}>basic information</p>
          </Box>
          <Box className={classes.navbarDiv}></Box>
          <Box className={classes.navbarEl} sx={{ width: '35%' }}>
            <span className={classes.navbarElNum}>3</span>
            <p className={classes.navbarElP}>public pages</p>
          </Box>
        </Box>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item>
            <TitleInfo title="Create Your Profiles" />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                cursor: 'pointer',
                [theme.breakpoints.only('xs')]: {
                  marginRight: '1.5rem',
                },
              }}
              onClick={onAboutProfilesOpen}
              className={classes.link}
            >
              <IconButton
                sx={{ height: '16px', width: '16px', marginRight: '0.5rem' }}
                disableRipple
                variant="outlined"
                aria-label="Help"
                size="medium"
              >
                <ToolTip
                  sx={{ height: '16px', width: '16px', color: '#5079A1' }}
                />
              </IconButton>
              What can I do with my profiles?
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.welcomeContainer}>
          <Typography variant="h5">
            Welcome to The Creative Passport, it's great to have you on board!
          </Typography>
          <Typography variant="body1">
            Let's get started by creating your profile(s). We'll start with some
            basic information, but you can add more advanced data, and edit
            things later.
          </Typography>
        </Box>
        <Box mt={3} className={classes.buttons}>
          <Button
            onClick={moveForward}
            width={{ xs: '320px', lg: '400px' }}
            marginBottom="40px"
            sx={{
              marginBottom: '90px',
            }}
          >
            Get Started
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default WelcomePage;
