const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: 'inherit',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
  },
  main: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1536px',
    },
    width: '100%',
    margin: '0rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      margin: '0rem 1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      margin: '0rem 0rem',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    [theme.breakpoints.only('sm')]: {
      margin: '1rem 0rem',
    },
    [theme.breakpoints.only('xs')]: {
      margin: '0rem 1rem',
    },
  },
  navbarEl: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    backgroundColor: theme.palette.background.dark,
    border: '2px solid #dee1e5',
    borderRadius: '0.75rem',
    height: '38px',
    minWidth: '38px',
    textTransform: 'capitalize',
    color: '#777777',
    [theme.breakpoints.down('md')]: {
      width: '38px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  navbarElP: {
    width: 'calc(100% - 36px)',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  navbarElNum: {
    width: '18px',
    height: '18px',
    fontSize: '14px',
    fontWeight: '700',
    alignSelf: 'center',
    color: '#626466',
  },
  navbarDiv: {
    backgroundColor: '#dbdbdb',
    height: '2px',
    [theme.breakpoints.up('md')]: {
      width: '12%',
    },
    width: '50%',
  },
  link: {
    transition: '0.3s',
    display: 'block',
    textDecoration: 'underline',
    color: '#5079A1',
    textDecorationColor: '#5078A150',
    '&:hover': {
      textDecorationColor: '#5079A1',
    },
  },
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
});

export default styles;
