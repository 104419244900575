import React from 'react';
import cx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';

const propTypes = {};
const defaultProps = {};

const CPDialog = (props) => {
  return <Dialog {...props}>{props.children}</Dialog>;
};

const Title = withStyles(styles)(({ children, classes, onClose, ...rest }) => {
  const classnames = cx({ [classes.title_box]: true });
  return (
    <MuiDialogTitle className={classnames} {...rest}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(({ children, classes, ...rest }) => {
  return (
    <MuiDialogContent {...rest}>
      <Typography className={classes.dialog_text} gutterBottom>
        {children}
      </Typography>
    </MuiDialogContent>
  );
});

CPDialog.displayName = 'Dialog';
CPDialog.propTypes = propTypes;
CPDialog.defaultProps = defaultProps;
CPDialog.Title = Title;
CPDialog.Content = Content;

export default CPDialog;
