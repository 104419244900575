import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Layout from './layout';
import Welcome from './pages/welcome';
import YourProfile from './pages/YourProfile';
import BasicInfo from './pages/BasicInfo';
import PublicPages from './pages/PublicPages';
import styles from './styles';
import { useProfileContext } from '../passportData/context';

const useStyles = makeStyles(styles);

const MAX_PAGES = 3;

const NewUser = () => {
  const [stage, setStage] = useState(0);
  const classes = useStyles();
  const { state } = useProfileContext();

  const moveForward = () => {
    setStage((state) => (state >= MAX_PAGES ? MAX_PAGES : state + 1));
  };
  const moveBackward = () => {
    setStage((state) => (state === 0 ? 0 : state - 1));
  };

  const getPageContent = (page) => {
    switch (page) {
      case 1:
        return {
          title: 'Create Your Professional Profile',
          description:
            'This is Your Professional Profile. Please introduce yourself 😊',
          link: '#',
          linkText: 'More about Professional Profile and Artistic Profile(s)',
          children: (
            <YourProfile
              artistProfiles={state.artistProfiles}
              personalData={state.personalData}
              next={moveForward}
              back={moveBackward}
            />
          ),
          section: 'personal',
        };
      case 2:
        return {
          title: 'Basic Info',
          description: 'Add some basic information about your Profile(s).',
          link: null,
          linkText: null,
          editable: true,
          children: (
            <BasicInfo
              next={moveForward}
              back={moveBackward}
              artistProfiles={state.artistProfiles}
            />
          ),
          section: 'basic',
        };
      case 3:
        return {
          title: 'Publish Your Profile(s)',
          description:
            "If you want to share your Profiles with others, enable your Public Pages below. You can always do this later if you don't feel like enabling your Profiles now.",
          link: '#',
          linkText: 'More about public pages and information privacy settings',
          children: (
            <PublicPages
              next={moveForward}
              back={moveBackward}
              artistProfiles={state.artistProfiles}
              personalData={state.personalData}
            />
          ),
          section: 'public',
        };
      default:
        throw new Error('Invalid page number :(');
    }
  };
  return (
    <div className={classes.root}>
      {stage === 0 ? (
        <Welcome moveForward={moveForward} />
      ) : (
        <Layout page={stage} {...getPageContent(stage)}></Layout>
      )}
    </div>
  );
};

export default NewUser;
