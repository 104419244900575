import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#7B7D7F" transform="translate(-312 -642)">
          <g transform="translate(300 630)">
            <g transform="translate(12 12)">
              <path
                d="M11.373 2l11.111 10-11.11 10L10 20.474 18.303 13H2v-2h16.305L10 3.526 11.373 2z"
                transform="matrix(-1 0 0 1 24.484 0)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
