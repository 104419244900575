import { useQuery } from 'react-query';
import { useAuth, useAuthManager } from '../components/auth/AuthProvider';
import { getProfileData } from '../scripts';
function useServerProfile() {
  // This should be used only at page load and for read-only data like verified
  const authManager = useAuthManager();
  const { authState } = useAuth();
  const { isLoading, isIdle, isSuccess, isError, status, error, data } =
    useQuery(
      'profileData',
      () =>
        authManager
          .getJwtToken()
          .then((jwtToken) => getProfileData(jwtToken))
          .catch()
          .then((res) => res.data),
      {
        enabled: authState === 'LOGGED_IN',
        staleTime: 30000,
      }
    );
  return {
    isLoading: isLoading || isIdle,
    isSuccess,
    isError,
    status,
    error,
    profile: data,
  };
}
export default useServerProfile;
