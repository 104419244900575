import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '1.5rem',
    height: '2.5rem',
    color: '#626466',
    fontSize: 12,
    backgroundColor: '#FFFFFF',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textTransform: 'uppercase',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      fontSize: 10,
    },
  },
  small: {
    height: '2rem',
    color: '#626466',
    fontSize: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textTransform: 'uppercase',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  large: {
    width: '150px',
    height: '2.5rem',
    borderRadius: '1.5rem',
  },
  cancel: {
    width: '100px',
    height: '20px',
    backgroundColor: theme.palette.background.dark,
    fontSize: '13px',
  },
}));

const CPButton = ({
  variant = 'contained',
  size = 'standard',
  onClick,
  children,
  className = 'standard',
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      className={clsx(
        className,
        size === 'small'
          ? classes.small
          : size === 'large'
          ? classes.large
          : classes.root
      )}
      disableElevation
      onClick={onClick}
      variant={variant}
    >
      {children}
    </Button>
  );
};

CPButton.displayName = 'Button';

export default CPButton;
