import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@mui/material/CssBaseline';
import NotificationProvider from './components/NotificationProvider';
import { Toaster } from 'react-hot-toast';

import awsmobile from './aws-exports';
import BaseThemeProvider from './themes/main';
import * as containers from './containers';
import './styles/App.css';
import 'antd/lib/switch/style/index.css'; // Just import switch CSS
import { AuthProvider } from './components/auth/AuthProvider';
import { AuthManager } from './components/auth/AuthManager';
import SignedInRoutes from './components/SignedInRoutes';
import Router from './components/Router';
import DialogProvider from './components/DialogProvider';
import PublicRoute from './components/PublicRoute';
import SearchPortal from './components/search-portal/SearchPortal';
import PublicPage from './containers/PublicPage';
import TrackingCode from './fathomtracking';

Auth.configure(awsmobile);

const queryClient = new QueryClient();
const authManager = new AuthManager(queryClient);

const App = () => {
  return (
    <BaseThemeProvider>
      <CssBaseline />
      <TrackingCode />
      <AuthProvider authManager={authManager}>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <Toaster position="top-center" />
            <DialogProvider>
              <Router>
                <Switch>
                  <PublicRoute
                    exact
                    path="/signin"
                    component={containers.login}
                  />
                  <PublicRoute
                    exact
                    path="/register"
                    component={containers.login}
                  />
                  <PublicRoute
                    exact
                    path="/reset_password"
                    component={containers.passwordResetPage}
                  />
                  <Route exact path="/search" component={SearchPortal} />
                  <Route
                    path="/page/:passportId/:personaId"
                    component={PublicPage}
                  />
                  <Route component={SignedInRoutes} />
                </Switch>
              </Router>
            </DialogProvider>
          </NotificationProvider>
        </QueryClientProvider>
      </AuthProvider>
    </BaseThemeProvider>
  );
};

export default App;
