import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, IconButton } from '@mui/material';
import CheckTik from '../../icons/atomIconsTick';
import { useProfileContext } from '../../containers/passportData/context';
import useFetchImage from '../../hooks/useFetchImage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '16px',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    marginBottom: '0.7rem',
  },
  progresscontainer: {
    position: 'relative',
    backgroundColor: theme.palette.background.dark,
    width: '100%',
    borderRadius: '100rem',
    marginTop: '8px',
    marginBottom: '5px',
  },
  progress: {
    backgroundImage: 'linear-gradient(to right, #FF2667, #73AEE6, #00FFCC)',
    padding: '0.3rem',
    borderRadius: '100rem',
    backgroundSize: '100%',
    transition: '0.3s',
    [theme.breakpoints.only('xl')]: {
      backgroundSize: '363px',
    },
    [theme.breakpoints.down('xl')]: {
      backgroundSize: 'calc(33vw - 8rem)',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'calc(100vw - 64px)',
    },
  },
  iconBtn: {
    backgroundColor: theme.palette.background.dark,
    marginRight: '0.5rem',
    width: '32px',
    height: '32px',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '0.7rem',
  },
  text: {
    textAlign: 'center',
  },
}));

const ProfileCompletion = () => {
  const classes = useStyles();
  const { state } = useProfileContext();
  const { artistProfiles, activeTab } = state;
  const currentProfile = artistProfiles[activeTab];
  const { thumbnail } = useFetchImage('profile', currentProfile?.id);

  let percentage = 0.0;

  if (currentProfile) {
    if (currentProfile.artistName) percentage += 12.5;
    if (thumbnail) percentage += 12.5;
    if (currentProfile.officialLinks?.value?.length) percentage += 12.5;
    if (currentProfile.otherSkills?.value?.length) percentage += 12.5;
    if (
      currentProfile.musicRoles?.value?.length ||
      currentProfile.features?.value?.length
    ) {
      percentage += 12.5;
    }
    if (
      currentProfile.biography &&
      Object.values(currentProfile.biography).some((data) => data.value)
    ) {
      percentage += 12.5;
    }
    if (
      [
        ...(currentProfile.isni?.value || []),
        ...(currentProfile.ipn?.value || []),
        ...(currentProfile.ipi?.value || []),
      ].length
    ) {
      percentage += 12.5;
    }
    if (currentProfile.isProfessionalProfile) {
      if (
        currentProfile.instrumentsPlayed?.value?.length ||
        currentProfile.instrumentsOwned?.value?.length ||
        currentProfile.toolsUsed?.value?.length ||
        currentProfile.toolsOwned?.value?.length
      ) {
        percentage += 12.5;
      }
    } else {
      if (currentProfile.genres?.value?.length) {
        percentage += 12.5;
      }
    }
  }

  return (
    <Box className={classes.root}>
      <div className={classes.progresscontainer}>
        <div
          className={classes.progress}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <Box className={classes.textContainer}>
        <IconButton disableRipple size="small" className={classes.iconBtn}>
          <CheckTik sx={{ height: '16px', width: '16px' }} />
        </IconButton>
        <Typography>Your Profile is {percentage}% complete</Typography>
      </Box>
    </Box>
  );
};

export default ProfileCompletion;
