import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import countries from 'i18n-iso-countries';
import toast from 'react-hot-toast';
import ProfileImage from '../ProfileImage';
import styles from './styles';
import { useProfileContext } from '../../containers/passportData/context';
import { useForm } from 'react-hook-form';
import SummaryEdit from '../SummaryEdit';
import CPAddButton from '../CPAddButton';
import CPEditButton from '../CPEditButton';
import {
  useMutatePatchPersonal,
  useMutatePatchProfile,
  useMutatePostProfile,
} from '../../hooks/useMutateServerProfile';
import useFetchImage from '../../hooks/useFetchImage';
import CPRadio from '../CPRadio';
import CPDeleteButton from '../CPDeleteButton';
import CPVisibleToggle from '../CPVisibleToggle';
import CPVisibleIcon from '../CPVisibleIcon';
import CPAutocomplete from '../CPAutocomplete';
import LocationIcon from '../../icons/location';
import GenderIcon from '../../icons/gender';
import DateofBirthIcon from '../../icons/birthday';
import { uploadImage, deleteProfileImage } from '../../scripts';
import { isPublished } from '../../services.js';
import { useAuthManager } from '../auth/AuthProvider';
import SoloProject from '../../icons/atomSoloProject';
import Drum from '../../icons/atomDrum';
import OrchestraIcon from '../../icons/atomOrchestra';
import GroupIcon from '../../icons/atomGroup';
import Puzzle from '../../icons/atomPuzzle';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
const useStyles = makeStyles(styles);

const TabSummary = ({
  id,
  artistProfile,
  personalData,
  isProfessionalProfile,
  onDelete,
  isDeleting,
  isNew,
  onNoLongerNew,
}) => {
  const { dispatch } = useProfileContext();
  const authManager = useAuthManager();
  const [isEditMode, setIsEditMode] = useState(isNew);
  const [openAlias, setOpenAlias] = useState(false);
  const [openGender, setOpenGender] = useState(false);
  const [openPronouns, setOpenPronouns] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  const [openDateOfBirth, setOpenDateOfBirth] = useState(false);
  const { handleSubmit, register, unregister, setValue } = useForm();
  const [aliasShowBothDisplay, setAliasShowBothDisplay] = useState(
    artistProfile?.aliasShowBoth ? 'display_both' : 'display_alias'
  );
  const [state, setState] = useState({
    showAlias: aliasShowBothDisplay === 'display_both' ? true : false, // XXX
    countryAlpha2: artistProfile?.countryAlpha2,
    gender: artistProfile?.gender,
    city: artistProfile?.city,
    musicRoles: artistProfile?.musicRoles,
    pronoun: artistProfile?.pronoun,
    dateOfBirth: artistProfile?.dateOfBirth,
    aliases: artistProfile?.aliases,
    projectType: artistProfile?.type, // XXX
    artistName: artistProfile?.artistName, // XXX
    features: artistProfile?.features,
  });
  const [imageToBeUploaded, setImageToBeUploaded] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const { mutateAsync: patchProfileAsync, isLoading: isPatchingProfile } =
    useMutatePatchProfile();
  const { postProfileAsync, isPostingProfile } = useMutatePostProfile();
  const { patchPersonalAsync, isPatchingPersonal } = useMutatePatchPersonal();
  const { thumbnail, isFetching } = useFetchImage('profile', id);
  const queryClient = useQueryClient();

  const classes = useStyles();

  const projectTypes = [
    { name: 'Solo project', slug: 'solo', icon: SoloProject },
    { name: 'Band', slug: 'band', icon: Drum },
    { name: 'Orchestra', slug: 'orchestra', icon: OrchestraIcon },
    { name: 'Collective', slug: 'collective', icon: GroupIcon },
    { name: 'Other', slug: 'other', icon: Puzzle },
  ];

  const handleImageChange = async (image) => {
    setImageToBeUploaded(image);
  };

  const handleImageDelete = async () => {
    try {
      setImageToBeUploaded(null);
      if (thumbnail) {
        await deleteProfileImage(id, await authManager.getJwtToken());
        queryClient.invalidateQueries(['profileImage', id]);
        queryClient.refetchQueries(['profileImage', id]);
        setTimeout(() => {
          queryClient.invalidateQueries(['profileImage', id]);
          queryClient.refetchQueries(['profileImage', id]);
        }, 2000);
      }
      setUploadedImage(null);
    } catch (err) {
      toast.error('Error deleting profile image');
    }
  };

  const handleChange = (value, type, valueInObject = true) => {
    if (valueInObject) {
      setState({ ...state, [type]: { ...state[type], value: value } });
    } else {
      setState({ ...state, [type]: value });
    }
  };

  const handleAliasClick = (e) => {
    setAliasShowBothDisplay(e.target.value);

    if (e.target.value === 'display_both') {
      handleChange(true, 'showAlias', false);
    } else {
      handleChange(false, 'showAlias', false);
    }
  };

  const removeItem = (type) => {
    unregister(type, true);
    if (type === 'countryAlpha2') {
      setState({ ...state, [type]: { value: '', publishers: [] } });
    } else {
      setState({ ...state, [type]: '' });
    }
  };

  const handleSave = async (cred) => {
    try {
      const profilePatch = {
        id,
        dateOfBirth: { ...state.dateOfBirth, value: cred.dob },
        city: { ...state.city, value: cred.city || '' },
        countryAlpha2: state.countryAlpha2,
        aliasShowBoth: state.showAlias,
      };
      if (isProfessionalProfile) {
        profilePatch['aliases'] = {
          ...state.aliases,
          value: cred.alias ? [cred.alias] : [],
        };
        // profilePatch['gender'] = { ...state.gender, value: cred.gender };
        profilePatch['gender'] =
          typeof state.gender === 'object' ? state.gender : {};
        profilePatch['pronoun'] = { ...state.pronoun, value: cred.pronoun };
        profilePatch['artistName'] = [cred.firstName, ' ', cred.lastName].join(
          ''
        );
        profilePatch['musicRoles'] = state.musicRoles;
      } else {
        profilePatch['artistName'] = cred.artistName;
        profilePatch['features'] = state.features;
        if (state.projectType) profilePatch['type'] = state.projectType;
      }
      if (isNew) {
        await postProfileAsync(profilePatch);
        onNoLongerNew();
      } else {
        await patchProfileAsync(profilePatch);
      }
      dispatch({
        type: 'PATCH_PROFILE',
        payload: profilePatch,
      });

      if (isProfessionalProfile) {
        const personalPatch = {
          firstName: cred.firstName,
          lastName: cred.lastName,
          gender: state.gender?.value,
          dateOfBirth: cred.dob,
          country: state.countryAlpha2?.value,
        };
        await patchPersonalAsync(personalPatch);
        dispatch({
          type: 'PATCH_PERSONAL',
          payload: personalPatch,
        });
      }

      setState({
        ...state,
        artistName: cred.artistName,
        pronoun: { ...state.pronoun, value: cred.pronoun },
        dateOfBirth: { ...state.dob, value: cred.dob },
        gender: { ...state.gender, value: cred.gender },
        city: { ...state.city, value: cred.city },
      });

      if (imageToBeUploaded) {
        await uploadImage(
          'profile',
          id,
          imageToBeUploaded,
          await authManager.getJwtToken()
        );
        queryClient.invalidateQueries(['profileImage', id]);
        setUploadedImage(URL.createObjectURL(imageToBeUploaded));
        setImageToBeUploaded(null);
        queryClient.refetchQueries(['profileImage', id]);
      }
      handleClose();
      if (isNew) onNoLongerNew();
      toast.success('Personal summary updated successfully');
    } catch (err) {
      const message = err.response?.data?.Error || err.message;
      toast.error(message);
    }
  };

  const handleClose = () => {
    setIsEditMode(false);
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('artistName', '');
    setValue('alias', '');
    setValue('city', '');
  };

  const handleCancel = () => {
    handleClose();
    if (isNew) {
      onNoLongerNew();
      onDelete(id, (isNew = true));
    }
  };

  const handlePrivacy = (key) => {
    const newPublishers = isPublished(state[key]) ? [] : ['publicPage'];
    setState({ ...state, [key]: { ...state[key], publishers: newPublishers } });
  };

  const handleCountry = (e, newValue) => {
    if (newValue) {
      setState({
        ...state,
        city: { value: '', publishers: [] },
        countryAlpha2: { ...state.countryAlpha2, value: newValue },
      });
    } else {
      setState({
        ...state,
        city: { value: '', publishers: [] },
        countryAlpha2: { value: '', publishers: [] },
      });
    }
  };

  const rolesOrFeatures = isProfessionalProfile ? 'musicRoles' : 'features';
  return (
    <>
      {/*** START OF EDITOR ***/}
      {/* TODO: stick the editor children & state in SummaryEdit */}
      <SummaryEdit
        isOpen={isEditMode}
        handleCancel={() => handleCancel()}
        handleSubmit={handleSubmit(handleSave)}
        onDelete={() => onDelete(id)}
        isLoading={isPatchingProfile || isPostingProfile || isPatchingPersonal}
        className={classes.editPersonalSummary}
        isProfessionalProfile={isProfessionalProfile}
      >
        <Grid container className={classes.editForm}>
          {/* Editor: Avatar */}
          <Grid item xs={12} sm={4} className={classes.image}>
            <ProfileImage
              src={uploadedImage || thumbnail}
              alt={artistProfile?.artistName}
              handleImageChange={handleImageChange}
              handleImageDelete={handleImageDelete}
              isBusy={isFetching}
            />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.modalForm}>
            {isProfessionalProfile ? (
              <>
                {/*** <PROFESSIONAL> ***/}
                {/* Professional Profile Editor: Person Name */}
                <Box
                  className={classes.fields}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Professional Profile Editor: First Name */}
                  <div style={{ width: '48%' }}>
                    <p className={classes.myLabel}>First name</p>
                    <TextField
                      className={classes.nameField}
                      variant="standard"
                      sx={{ width: '100% !important' }}
                      placeholder="First Name"
                      data-testid={`first-name-${id}-edit`}
                      required
                      {...register('firstName')}
                    />
                  </div>
                  {/* Professional Profile Editor: Last Name */}
                  <div style={{ width: '48%' }}>
                    <p className={classes.myLabel}>Surname</p>
                    <TextField
                      className={classes.nameField}
                      variant="standard"
                      sx={{ width: '100% !important' }}
                      placeholder="Surname"
                      data-testid={`last-name-${id}-edit`}
                      required
                      {...register('lastName')}
                    />
                  </div>
                </Box>
                {/* nickname */}
                {state.aliases?.value && !openAlias ? (
                  <CPAddButton
                    onClick={() => {
                      setOpenAlias(true);
                    }}
                  >
                    Add an alias/stage name/nickname
                  </CPAddButton>
                ) : (
                  <div>
                    <Typography
                      className={classes.myLabel}
                      data-testid={`first-last-name-${id}-edit`}
                    >
                      Alias / Stage Name / Nickname
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <TextField
                        className={classes.inputField}
                        placeholder="Alias/Nickname/Moniker"
                        variant="standard"
                        {...register('alias')}
                      />
                      <CPDeleteButton
                        onClick={() => {
                          setOpenAlias(false);
                          removeItem('alias');
                          setValue('alias', '');
                        }}
                      />
                    </Box>

                    <FormControl sx={{ width: '100%' }}>
                      <RadioGroup
                        value={aliasShowBothDisplay}
                        onChange={handleAliasClick}
                        defaultValue="display_both"
                        aria-label="display-alias-option"
                        name="display-alias-option"
                        className={classes.displayAlias}
                        data-testid={`alias-show-${id}-group`}
                      >
                        <Grid
                          container
                          className={classes.fields}
                          spacing={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Grid item xs={6}>
                            <FormControlLabel
                              className={classes.eachAlias}
                              value="display_both"
                              control={
                                <CPRadio
                                  inputProps={{
                                    'data-testid': `alias-show-${id}-display-both`,
                                  }}
                                />
                              }
                              label="Display both"
                              style={{
                                width: '100%',

                                backgroundColor:
                                  aliasShowBothDisplay === 'display_both'
                                    ? '#F7F8F9'
                                    : 'transparent',
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              className={classes.eachAlias}
                              value="display_alias"
                              control={
                                <CPRadio
                                  inputProps={{
                                    'data-testid': `alias-show-${id}-display-alias`,
                                  }}
                                />
                              }
                              label="Display Alias only"
                              style={{
                                width: '100%',

                                backgroundColor:
                                  aliasShowBothDisplay === 'display_both'
                                    ? 'transparent'
                                    : '#F7F8F9',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                {/*** </PROFESSIONAL> ***/}
              </>
            ) : (
              <>
                {/*** <ARTISTIC> ***/}
                {/* Artist Profile Editor: Name */}
                <div>
                  <Typography className={classes.myLabel}>Name</Typography>
                  <TextField
                    defaultValue={state.artistName}
                    variant="standard"
                    placeholder="Name"
                    InputLabelProps={{ shrink: true }}
                    className={classes.inputField}
                    fullWidth
                    {...register('artistName')}
                  />
                </div>
                {/* Artist Profile Editor: Type */}
                <div style={{ marginBottom: '23px' }}>
                  <Typography className={classes.myLabel}>Type</Typography>
                  <FormControl>
                    <RadioGroup
                      value={state.projectType}
                      onChange={(e) =>
                        handleChange(e.target.value, 'projectType', false)
                      }
                      defaultValue="band"
                      aria-label="artistic-project-type"
                      name="artistic-project-type"
                      className={classes.types}
                    >
                      {projectTypes.map((project) => {
                        const BaseIcon = project.icon;
                        return (
                          <FormControlLabel
                            className={
                              state.projectType === project.slug
                                ? classes.radioSelected
                                : classes.radio
                            }
                            value={project.slug}
                            control={
                              <Radio
                                icon={<BaseIcon />}
                                checkedIcon={<BaseIcon hasGradient={true} />}
                                disableRipple
                                sx={{
                                  '&.MuiButtonBase-root:hover': {
                                    bgcolor: 'transparent',
                                  },
                                }}
                                className={
                                  state.projectType === project.slug
                                    ? classes.radioIconSelected
                                    : classes.radioIcon
                                }
                              />
                            }
                            label={project.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
                {/*** </ARTISTIC> ***/}
              </>
            )}

            {/*** <SHARED> ***/}
            {/* Country */}
            <div>
              {!state?.countryAlpha2?.value && !openCountry ? (
                <CPAddButton
                  data-testid={`edit-${id}-country-button`}
                  onClick={() => setOpenCountry(true)}
                >
                  Add Country
                </CPAddButton>
              ) : (
                <>
                  <p className={classes.myLabel}>Country</p>
                  <div
                    className={classes.eyes}
                    data-testid={`edit-${id}-country`}
                  >
                    <CPAutocomplete
                      data-testid="autocomplete-select"
                      options={Object.keys(countries.getNames('EN'))}
                      value={state.countryAlpha2?.value}
                      getOptionLabel={(x) => countries.getName(x, 'EN') ?? ''}
                      onChange={handleCountry}
                    ></CPAutocomplete>
                    <CPVisibleToggle
                      onClick={() => handlePrivacy('countryAlpha2')}
                      dataField={state.countryAlpha2}
                    />
                    <CPDeleteButton
                      onClick={() => {
                        setOpenCountry(false);
                        removeItem('countryAlpha2');
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            {/* City Name */}
            <div>
              {!state?.city?.value && !openCity ? (
                <CPAddButton
                  onClick={() => setOpenCity(true)}
                  data-testid={`add-${id}-city-button`}
                >
                  Add City
                </CPAddButton>
              ) : (
                <>
                  <p className={classes.myLabel}>City</p>
                  <div className={classes.eyes}>
                    <TextField
                      className={classes.inputField}
                      placeholder="City"
                      variant="standard"
                      {...register('city')}
                    />
                    <CPVisibleToggle
                      onClick={() => handlePrivacy('city')}
                      dataField={state.city}
                    />
                    <CPDeleteButton
                      onClick={() => {
                        setOpenCity(false);
                        removeItem('city');
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            {/* Professional Profile Editor: Birthday */}
            {!state.dateOfBirth?.value && !openDateOfBirth ? (
              <CPAddButton onClick={() => setOpenDateOfBirth(true)}>
                Add {isProfessionalProfile ? 'date of birth' : 'founding date'}
              </CPAddButton>
            ) : (
              <div>
                <p className={classes.myLabel}>
                  {isProfessionalProfile ? 'Date of birth' : 'Founding date'}
                </p>
                <div className={classes.eyes}>
                  <TextField
                    className={classes.inputField}
                    variant="standard"
                    type="date"
                    placeholder={
                      isProfessionalProfile ? 'Date of birth' : 'Founding date'
                    }
                    defaultValue={state.dateOfBirth?.value}
                    {...register('dob')}
                  />
                  <CPVisibleToggle
                    onClick={() => handlePrivacy('dateOfBirth')}
                    dataField={state.dateOfBirth}
                  />
                  <CPDeleteButton
                    onClick={() => {
                      setOpenDateOfBirth(false);
                      removeItem('dateOfBirth');
                    }}
                  />
                </div>
              </div>
            )}

            {/*** </SHARED> ***/}
            {isProfessionalProfile ? (
              <>
                {/*** <Professional 2> ***/}

                {/* Professional Profile Editor: Gender */}
                <div>
                  {!state.gender?.value && !openGender ? (
                    <CPAddButton
                      onClick={() => setOpenGender(true)}
                      data-testid={`edit-${id}-gender-button`}
                    >
                      Add Gender
                    </CPAddButton>
                  ) : (
                    <div>
                      <p className={classes.myLabel}>Gender</p>
                      <div
                        className={classes.eyes}
                        data-testid={`edit-${id}-gender`}
                      >
                        <CPAutocomplete
                          data-testid={`autocomplete-${id}-gender`}
                          options={[
                            'Male',
                            'Female',
                            'Non-Binary',
                            'Genderfluid',
                            'Agender',
                          ]}
                          value={state.gender?.value}
                          onChange={(e, value) => handleChange(value, 'gender')}
                          freeSolo
                        ></CPAutocomplete>
                        <CPVisibleToggle
                          onClick={() => handlePrivacy('gender')}
                          dataField={state.gender}
                        />
                        <CPDeleteButton
                          onClick={() => {
                            setOpenGender(false);
                            removeItem('gender');
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Professional Profile Editor: Pronouns */}
                <div>
                  {!state.pronoun?.value && !openPronouns ? (
                    <CPAddButton onClick={() => setOpenPronouns(true)}>
                      Add Pronouns
                    </CPAddButton>
                  ) : (
                    <div>
                      <p className={classes.myLabel}>Pronouns</p>
                      <div className={classes.eyes}>
                        <Select
                          className={classes.pronouns}
                          variant="standard"
                          placeholder="Pronouns"
                          defaultValue={
                            state.pronoun?.value ? state.pronoun.value : ''
                          }
                          {...register('pronoun')}
                        >
                          <MenuItem value="she/her">she/her</MenuItem>
                          <MenuItem value="he/him">he/him</MenuItem>
                          <MenuItem value="they/them">they/them</MenuItem>
                          <MenuItem value="it/its">it/its</MenuItem>
                          <MenuItem value="co/cos">co/cos</MenuItem>
                          <MenuItem value="hy/hym">hy/hym</MenuItem>
                          <MenuItem value="xe/xem">xe/xem</MenuItem>
                          <MenuItem value="ze/hir">ze/hir</MenuItem>
                        </Select>
                        <CPVisibleToggle
                          onClick={() => handlePrivacy('pronoun')}
                          dataField={state.pronoun}
                        />
                        <CPDeleteButton
                          onClick={() => {
                            setOpenPronouns(false);
                            removeItem('pronoun');
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/*** </Professional 2> ***/}
              </>
            ) : null}
          </Grid>
        </Grid>
      </SummaryEdit>

      {/*** END OF EDITOR ***/}
      {/*** START OF DISPLAY ***/}
      <div className={classes.root}>
        <div className={classes.topInfo}>
          <CPEditButton
            title={`Edit ${
              isProfessionalProfile ? 'Personal' : 'Artist Project'
            } Summary`}
            onClick={() => {
              setState({
                showAlias:
                  aliasShowBothDisplay === 'display_both' ? true : false,
                countryAlpha2: artistProfile?.countryAlpha2,
                gender: artistProfile?.gender,
                city: artistProfile?.city,
                musicRoles: artistProfile?.musicRoles,
                pronoun: artistProfile?.pronoun,
                dateOfBirth: artistProfile?.dateOfBirth,
                aliases: artistProfile?.aliases,
                artistName: artistProfile?.artistName,
                projectType: artistProfile?.type,
                features: artistProfile?.features,
              });
              setValue('firstName', personalData?.firstName);
              setValue('lastName', personalData?.lastName);
              setValue('artistName', artistProfile?.artistName);
              setValue(
                'alias',
                artistProfile?.aliases?.value?.length
                  ? artistProfile.aliases.value[0]
                  : undefined
              );
              setValue('city', artistProfile?.city?.value);
              setIsEditMode(true);
            }}
            data-testid={`edit-${id}-button`}
          />
        </div>
        <div className={classes.body}>
          {/*** <SHARED> ***/}
          {/* Display: Avatar*/}
          <ProfileImage
            src={uploadedImage || thumbnail}
            alt={artistProfile.artistName}
            disableUpload
            isBusy={isFetching}
            isVerified={
              0 /*profile?.VerifiedData ? Object.keys(profile.VerifiedData).length : 0*/
            }
          />
          <div className={classes.bodyInfo}>
            {/*Profile Display: Name*/}
            <Typography
              variant="h1"
              className={classes.name}
              data-testid={`first-last-name-${id}-display`}
            >
              {isProfessionalProfile && artistProfile.aliases?.value?.length ? (
                <>
                  {artistProfile.aliasShowBoth
                    ? `${artistProfile.artistName} (${artistProfile.aliases?.value[0]})`
                    : artistProfile.aliases?.value[0]}
                </>
              ) : (
                `${artistProfile?.artistName}`
              )}
            </Typography>
            <div className={classes.talents}>
              {rolesOrFeatures in artistProfile &&
              artistProfile[rolesOrFeatures].value?.length ? (
                <Typography
                  className={classes.talent}
                  data-testid={`features-roles-${id}-display`}
                >
                  {artistProfile[rolesOrFeatures]?.value
                    .slice(0, 3)
                    .map((el, index, arr) => (
                      <React.Fragment key={el}>
                        {el} {index !== arr.length - 1 && '|'}{' '}
                      </React.Fragment>
                    ))}
                </Typography>
              ) : (
                <Typography />
              )}
            </div>
            <Box sx={{ mt: '1rem' }}>
              <Grid container direction="column" spacing={2}>
                {/*Profile Display: Location*/}
                {(artistProfile.countryAlpha2?.value ||
                  artistProfile.city?.value) && (
                  <Grid item className={classes.location}>
                    <Typography
                      className={classes.locationTitle}
                      data-testid={`country-${id}-display`}
                    >
                      {(artistProfile.countryAlpha2?.value ||
                        artistProfile.city?.value) && (
                        <LocationIcon sx={{ width: '16px' }} />
                      )}
                      {artistProfile.city?.value}
                      {artistProfile.city?.value &&
                      artistProfile?.countryAlpha2?.value
                        ? ', '
                        : ''}
                      {countries.getName(
                        artistProfile?.countryAlpha2?.value,
                        'EN'
                      )}
                    </Typography>
                    <CPVisibleIcon
                      dataField={artistProfile.countryAlpha2}
                      fontSizePublic="16px"
                      fontSizePrivate="16px"
                    />
                  </Grid>
                )}
              </Grid>
              {/*** </SHARED> ***/}
              {isProfessionalProfile &&
              (artistProfile.gender?.value || artistProfile.pronoun?.value) ? (
                <Grid item className={classes.location}>
                  {/*** <PROFESSIONAL> ***/}
                  {/*Professional Profile Display: Gender*/}
                  <Typography
                    className={classes.locationTitle}
                    data-testid={`gender-${id}-display`}
                  >
                    <GenderIcon sx={{ width: '16px', color: '#626466' }} />
                    {artistProfile.gender?.value} |{' '}
                    {artistProfile.pronoun?.value}
                  </Typography>
                  <CPVisibleIcon
                    dataField={artistProfile.gender}
                    fontSizePublic="16px"
                    fontSizePrivate="16px"
                  />
                  {/*** </PROFESSIONAL> ***/}
                </Grid>
              ) : null}

              {/*Profile Display: Birthday/Founding*/}
              {artistProfile.dateOfBirth?.value ? (
                <Grid item className={classes.location}>
                  <Typography className={classes.locationTitle}>
                    <DateofBirthIcon sx={{ width: '16px' }} />
                    {artistProfile.dateOfBirth?.value}
                  </Typography>
                  <CPVisibleIcon
                    dataField={artistProfile.dateOfBirth}
                    fontSizePublic="16px"
                    fontSizePrivate="16px"
                  />
                </Grid>
              ) : null}
            </Box>
          </div>
        </div>
      </div>
      {/*** END OF DISPLAY ***/}
    </>
  );
};

export default TabSummary;
