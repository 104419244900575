import { makeStyles } from '@mui/styles';
import Verification from '../verify/VerifyNew';
import PublicProfile from '../PublicProfile';
import ConnectionsBlock from '../Connections/ConnectionsBlock';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      margin: 0,
    },
  },
  child: {
    display: 'flex',
  },
}));

const AllMedium = ({ profile, artistProfile }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3.5} className={classes.child}>
        <ConnectionsBlock />
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.child}>
        <Verification profile={profile} />
      </Grid>
      <Grid item xs={12} sm={6} md={4.5} className={classes.child}>
        <PublicProfile artistProfile={artistProfile} allowOnMap={true} />
      </Grid>
    </Grid>
  );
};

export default AllMedium;
