import React from 'react';
import GuidanceAndSupport from '../GuidanceAndSupport';
import ProfileCompletion from '../ProfileCompletion';
import PublicPageVisualization from '../PublicPageVisualization';
import WhatElseCanYouDo from '../WhatElseCanYouDo';

const ConnectionsProfileTab = ({ personaId }) => {
  return (
    <>
      <ProfileCompletion />
      <PublicPageVisualization personaId={personaId} />
      <WhatElseCanYouDo />
      <GuidanceAndSupport />
    </>
  );
};

export default ConnectionsProfileTab;
