import React, { useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDialog } from './DialogProvider';

const Router = ({ children }) => {
  const showDialog = useDialog();
  const getUserConfirmation = useCallback(
    (dialog, callback) => {
      const { title, message, confirmButton, cancelButton } =
        JSON.parse(dialog);
      showDialog(title, message, {
        onConfirm: () => callback(true),
        onCancel: () => callback(false),
        confirmButton,
        cancelButton,
      });
    },
    [showDialog]
  );

  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      {children}
    </BrowserRouter>
  );
};

export default Router;
