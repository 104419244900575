import React, { useState } from 'react';
import { TextField, Chip, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Close from '../../icons/close';
import {
  DndContext,
  useSensor,
  PointerSensor,
  closestCenter,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
}));

const SortableChip = ({ id, value, index, onDelete, name }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : '',
    transition: isDragging ? transition : 'none',
    padding: '2px 2px',
    margin: '0px 6px 6px 0px',
    border:
      name === 'roles' && index < 3 ? 'solid 2px #9b91c3' : 'solid 2px #00FFCC',
    background: name === 'roles' && index < 3 ? '#5100ff30' : '#00FFCC30',
    borderRadius: '4px',
    color: '#313233',
    fontSize: '14px',
    fontWeight: '500',
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  return (
    <Chip
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      label={value}
      onDelete={onDelete}
      style={style}
      deleteIcon={
        <Close
          sx={{
            width: '14px',
            height: '14px',
            borderRadius: '1rem',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        />
      }
    />
  );
};

const CPAutocomplete = ({
  options,
  value,
  name,
  onChange,
  onInputChange,
  placeholder,
  multiple,
  dataTestId,
  ...other
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(value || []);

  const sensors = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) {
      return;
    }

    const oldIndex = selected.findIndex((item) => item === active.id);
    const newIndex = selected.findIndex((item) => item === over.id);

    if (oldIndex !== -1 && newIndex !== -1) {
      const newArray = arrayMove(selected, oldIndex, newIndex);
      setSelected(newArray);
      if (onChange) {
        onChange({ target: { name, value: newArray } }, newArray);
      }
    }
  };

  return (
    <DndContext
      sensors={[sensors]}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <Autocomplete
        {...other}
        multiple={multiple}
        fullWidth
        size="small"
        options={options}
        value={selected}
        name={name}
        onChange={(event, newValue) => {
          setSelected(newValue || []);
          if (onChange) {
            onChange(event, newValue);
          }
        }}
        className={classes.root}
        data-testid="autocomplete-select"
        renderTags={(value, getTagProps) => (
          <SortableContext items={value}>
            {value.map((option, index) => (
              <SortableChip
                name={name}
                key={option}
                id={option}
                value={option}
                index={index}
                {...getTagProps({ index })}
                onDelete={() => {
                  const newSelected = selected.filter(
                    (item) => item !== option
                  );
                  setSelected(newSelected);
                  if (onChange) {
                    onChange(null, newSelected);
                  }
                }}
              />
            ))}
          </SortableContext>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.input}
            placeholder={placeholder}
            variant="standard"
          />
        )}
      />
    </DndContext>
  );
};

export default CPAutocomplete;
