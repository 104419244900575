import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EditProject from './EditProject';

const DraggableProjectItem = ({
  id,
  projectData,
  handleChange,
  handleDelete,
  handlePrivacy,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <EditProject
        fieldName={projectData.fieldName}
        value={projectData.value}
        handleChange={(patch) =>
          handleChange({ ...patch, uniqueKey: projectData.uniqueKey })
        }
        onDelete={() => handleDelete(projectData.uniqueKey)}
        setIsShow={() => handlePrivacy(projectData.uniqueKey)}
        dataField={projectData}
      />
    </div>
  );
};

export default DraggableProjectItem;
