import React from 'react';
import { usePublicProfileContext } from '../../containers/public-profile';
import { Typography, Box, Tab } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import TitleInfo from '../Title';

const Bios = () => {
  const theme = useTheme();
  const publicProfileData = usePublicProfileContext();
  const filteredEntries = Object.entries(
    publicProfileData?.biography || {}
  ).filter(([_, value]) => value);
  const initialTab = filteredEntries.length > 0 ? filteredEntries[0][0] : null;
  const [tab, setTab] = React.useState(initialTab);
  React.useEffect(() => {
    setTab(initialTab);
  }, [initialTab]);
  const title = filteredEntries.length === 1 ? 'Bio' : 'Bios';
  const styles = {
    tabs: {
      width: '100%',
      minHeight: '100%',
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden !important',
    },
    tab: {
      marginLeft: '0px',
      justifyContent: 'center',
      borderRadius: '0.75rem',
      marginBottom: '20px',
      flexGrow: 1,
      height: '50px',
      paddingTop: '15px',
      '&.MuiTab-root': {
        minHeight: 0,
        verticalAlign: 'middle',
      },
      '&.Mui-selected': {
        backgroundColor: '#000',
        borderRadius: '0.75rem',
        fontFamily: 'Roboto, Sans-serif',
        color: '#313233',
        textStroke: '0.3px black',
        WebkitTextStroke: '0.3px black',
        strokeLinejoin: 'round',
        '&:hover': {
          backgroundColor: '#000',
        },
      },
      '&#simple-tab-headline': {
        marginRight: '0px !important',
        // marginLeft: 'auto',
      },
      '&#simple-tab-short': {
        margin: '0px !important',
      },
      '&#simple-tab-long': {
        marginRight: '0px !important',
      },
      textTransform: 'capitalize',
      // marginLeft: 'auto',
    },
    tabPanel: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      border: 'none',
      resize: 'none',
      borderRadius: '1rem',
      fontFamily: 'inherit',
      '&:focus-visible': {
        outline: 'none',
      },
    },
  };

  if (!publicProfileData?.biography) return null;
  if (
    !publicProfileData.biography.short &&
    !publicProfileData.biography.long &&
    !publicProfileData.biography.headline
  )
    return null;
  return (
    <React.Fragment>
      <TitleInfo title={title} />
      <Box mb={4}>
        <TabContext value={tab}>
          <Box>
            {filteredEntries.length > 1 && ( // Conditionally render TabList
              <TabList
                style={styles.tabs}
                indicatorColor=""
                onChange={(e, t) => setTab(t)}
                aria-label="Biography tabs"
              >
                {Object.entries(publicProfileData.biography || {})
                  .filter(([_, value]) => value)
                  .map(([key, _]) => (
                    <Tab
                      style={styles.tab}
                      label={key}
                      value={key}
                      sx={{
                        backgroundColor:
                          tab === key ? 'background.paper' : 'transparent',
                        color: tab === key ? 'primary.main' : 'primary.main',
                      }}
                    />
                  ))}
              </TabList>
            )}
          </Box>
          {Object.entries(publicProfileData.biography || {}).map(
            ([key, data]) => (
              <TabPanel
                sx={{
                  bgcolor: 'background.paper',
                  borderRadius: '1rem',
                  [theme.breakpoints.down('sm')]: {
                    borderRadius: '0rem',
                  },
                  p: 3,
                  mb: 1,
                }}
                value={key}
                style={styles.panel}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '26px',
                    fontWeight: '400',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {data}
                </Typography>
              </TabPanel>
            )
          )}
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default Bios;
