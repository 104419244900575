import React, { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  artistProfiles: [],
  activeTab: 0,
};

const profileContext = createContext({
  state: initialState,
  dispatch: () => {},
});

export const ProfileContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <profileContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </profileContext.Provider>
  );
};

export const useProfileContext = () => {
  const { state, dispatch } = useContext(profileContext);
  return { state, dispatch };
};
