import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Box,
  IconButton,
  DialogContent,
} from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  proPanel: {
    backgroundColor: '#E3EEFA',
    padding: '1rem',
    borderRadius: '1rem',
  },
  artPanel: {
    backgroundColor: '#F7F8F9',
    padding: '1rem',
    borderRadius: '1rem',
  },
  panelTitle: {
    marginBottom: '1rem',
  },
  typeIcon: {
    marginRight: '6px',
    height: '16px',
  },
  refreshButton: {
    width: '25px',
    height: '25px',
    marginLeft: '3px',
  },
  refreshIcon: {
    width: '20px',
    height: '20px',
  },
  textWithIconStyle: {
    paddingRight: '30px',
  },
}));

const AboutPublicPages = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">Public Pages</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box>
              <Typography paragraph={true}>
                Your public page displays all the passport data you choose to
                share publicly. If you enable a public page, you also become
                searchable in our portal of other music makers.
              </Typography>
              <Typography paragraph={true}>
                We also offer you granular control over which information you
                want displayed on your page, and which will stay private. Just
                toggle the privacy indicator next to any piece of information in
                your passport. By default, your data is set to private.
              </Typography>
              <Typography>
                You can change your privacy preferences at any time, preview
                your public page, and customise how it looks.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CgClose />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default AboutPublicPages;
