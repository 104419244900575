import React from 'react';
import { Typography, Modal, Box, Stack, IconButton } from '@mui/material';
import { CgClose } from 'react-icons/cg';

const BasicModal = ({ isOpen, onClose, title, section }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby={title}
      aria-describedby={section}
      BackdropProps={{ invisible: true }}
    >
      <Box
        sx={{
          width: {
            xs: '90vw',
            lg: '60vw',
          },
          height: '75vh',
          borderRadius: '22px',
          backgroundColor: '#fff',
          boxShadow: '0px 0px 24px rgba(0,0,0,0.2)',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '30px',
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginBottom: '30px',
          }}
        >
          <Typography
            color="#313233"
            fontWeight="bold"
            component="h1"
            fontSize="17px"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
            }}
          >
            <CgClose />
          </IconButton>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            bottom: '30px',
            width: 'calc(100% - 60px)',
            height: '30px',
          }}
        ></Box>
        <Stack
          sx={{
            overflowY: 'scroll',
            height: '85%',
            width: '100%',
            padding: '10px',
          }}
          spacing="30px"
        >
          {section === 'personal' && (
            <>
              <Typography fontSize="17px">
                What's the difference between your <b>Personal</b> and your
                <b>Artistic Profile(s)</b>?
              </Typography>
              <Typography fontSize="17px">
                A <b>Personal Profile</b> is related to your legal identity,
                it's your legal first name and surname and information related
                to your career as a music professional under that name.
              </Typography>
              <Typography fontSize="17px">
                <b>Artistic Profile(s)</b> are extra collaborative or solo acts
                outside your legal identity. For example think of Paul McCartney
                as the legal identity (Personal Profile) and The Beatles as his
                band (Artistic Profile), or David Jones as the legal identity
                (Personal Profile) and David Bowie as his solo performing act
                (Artistic Profile) or Artist Name.
              </Typography>
              <Typography fontSize="17px">
                <b>Artistic Profile(s)</b> can be anything from solo projects,
                bands (duo, trio, etc.), DJ acts, orchestras, collectives and so
                on. The music industry counts a wide variety of cases and we
                feel it's important to give you freedom in how you want to
                present your music to the industry. If your Artistic Profile has
                more than one member, e.g. bands or orchestras, you will be able
                to add other members to that Profile as collaborators in the
                future.
              </Typography>
              <Typography fontSize="17px">
                💁 Useful tips on how to create your Profiles: <br />
                - You only need your Personal Profile if your legal identity
                (first name and surname) is not different from your
                artistic/professional identity (Artistic Profile). Note that
                exact information about your Personal Profile is useful to get
                you verified.
                <br />- You can add an Artist Name / Stage Name / Alias in your
                Personal Profile if you have one. You can also specify if you
                want to display them both or just your Artist Name / Stage Name
                / Alias. Some people like to be recognised by other fellow
                artists, professionals and their fan base just with their Artist
                Name which is totally fine by us 🙂. Just remember to specify
                your personal details in your Personal Profile correctly to get
                your account verified.
                <br />- You need an Artistic Profile if you consider your legal
                identity (Personal Profile) completely separate from your
                artistic/professional identity (Artistic Profile).
              </Typography>
            </>
          )}
          {section === 'welcome' && (
            <>
              <Typography fontSize="17px">
                <b>The Profiles</b> are a digital version of music makers'
                identities. The Creative Passport helps you create them in a way
                that best represents you.
              </Typography>

              <Typography fontSize="17px">
                Here is what you can do within The Creative Passport: <br />
                1. Create a Personal Profile aka using your legal first name and
                surname. You could also specify your Artist Name if you have
                one.
                <br />
                2. Create one, two or as many Artistic Profile(s) as you want.
                These can be bands, orchestras, collectives or any other type of
                artistic, music-related acts.
                <br />
                3. Decide to have your Profiles public or private. If you opt
                for the first option you can connect and network with other
                music makers.
              </Typography>
              <Typography fontSize="17px">
                🔜 You'll soon be able to use The Creative Passport as a hub to
                store your information. All services (3rd party services) linked
                to it will automatically get updated. We're still working on it!
              </Typography>
            </>
          )}
          {section === 'public' && (
            <>
              <Typography fontSize="17px">
                At The Creative Passport we know how valuable your data is and
                we believe you should be able to manage it as you wish! This is
                why we offer you granular control over which information can be
                displayed publicly and which will stay private.
              </Typography>
              <Typography fontSize="17px">
                Whenever you see a <b>Privacy-switch </b> next to an input field
                or within an element it means that you can control the privacy
                settings for that information:
              </Typography>
              <Typography
                fontSize="17px"
                paddingLeft="10px"
                paddingRight="40px"
              >
                - If the information is set to "public" it will be displayed as
                public for that Profile. <br /> - If the information is set to
                "private" it will only be visible to you on your Creative
                Passport but won't be public for that Profile.
              </Typography>
              <Typography fontSize="17px">
                Please note that this information about privacy settings is only
                relevant if you enabled public Profiles. If you don't make your
                Personal Profile and/or Artistic Profile(s) public, no one could
                ever see your information except from you.
              </Typography>
            </>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default BasicModal;
