import countries from 'i18n-iso-countries';
import { avs } from '../../../../../text_fields';
import CPAutocomplete from '../../../../../components/CPAutocomplete';
import AtomsRoles from '../../../../../icons/atomsRoles';
import AtomsSkills from '../../../../../icons/atomsSkills';
import LocationIcon from '../../../../../icons/atomsLocation';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const MainForm = ({ artistProfile, useStyles, patchTempProfiles }) => {
  const classes = useStyles();

  const handleMusicRoles = (e, newValues) => {
    patchTempProfiles({
      id: artistProfile.id,
      musicRoles: { value: newValues },
    });
  };

  const handleSkillsChange = (e, newValues) => {
    patchTempProfiles({
      id: artistProfile.id,
      otherSkills: { value: newValues },
    });
  };

  const handleCountriesChange = (e, newValues) => {
    // FIXME: patch personal if Pro
    patchTempProfiles({
      id: artistProfile.id,
      countryAlpha2: { value: newValues },
    });
  };

  return (
    <div className={classes.main}>
      <div>
        <p className={classes.myLabel}>
          <AtomsRoles style={{ marginRight: '12px' }} />
          Roles
        </p>
        <p style={{ color: '#313233', fontSize: '14px', fontWeight: '400' }}>
          Specify your music-related roles.
        </p>
        <CPAutocomplete
          options={avs.musicRoles.values}
          value={artistProfile.musicRoles?.value || []}
          onChange={handleMusicRoles}
          placeholder="E.g. Producer, Songwriter, Mixer"
          multiple={true}
          freeSolo
        />
      </div>

      {/* Skills */}
      <div>
        <p className={classes.myLabel}>
          <AtomsSkills style={{ marginRight: '12px' }} />
          Skills
        </p>
        <p style={{ color: '#313233', fontSize: '14px', fontWeight: '400' }}>
          You can add music and non-music related skills.
        </p>
        <CPAutocomplete
          options={[]}
          value={artistProfile.otherSkills?.value || []}
          onChange={handleSkillsChange}
          placeholder="E.g. Singing, Photography, Acting, Web Design"
          multiple={true}
          freeSolo
        />
      </div>

      {/* Country */}
      <div>
        <p className={classes.myLabel}>
          <LocationIcon style={{ marginRight: '12px' }} />
          Location
        </p>
        <CPAutocomplete
          options={Object.keys(countries.getNames('EN'))}
          value={artistProfile.countryAlpha2?.value}
          getOptionLabel={(x) => countries.getName(x, 'EN') ?? ''}
          onChange={handleCountriesChange}
          placeholder="Add your country."
          multiple={false}
        />
      </div>
    </div>
  );
};

export default MainForm;
