import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      {' '}
      <title>owned</title>
      <g
        id="PWA---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-1845.000000, -508.000000)"
          fill="#00FFCC"
        >
          <g id="Combined-Shape" transform="translate(1844.000000, 507.000000)">
            <path d="M14.026,1 L19.661,2.51 L19.661,2.509 L21.106,2.896 L23.002,9.977 L17.82,15.159 L17.817,15.158 L17.817,15.159 L14.721,14.084 L13.845,14.961 L11.612,15.361 L11.098,17.707 L8.851,18.122 L8.473,20.333 L6.262,20.711 L5.806,23 L5.803,23 L1.011,22.988 L1,19.113 L9.916,10.196 L8.84,6.182 L14.022,1 L14.026,1 Z M14.621,3.23 L11.072,6.78 L11.848,9.679 L12.146,10.795 L3.002,19.939 L3.005,20.993 L4.008,20.995 L4.429,18.879 L6.64,18.501 L7.019,16.289 L9.265,15.875 L9.779,13.529 L12.013,13.128 L13.572,11.57 L14.189,11.784 L14.192,11.783 L15.376,12.194 L16.67,12.644 L20.593,8.721 L19.47,4.53 L14.621,3.23 Z M15.9199,4.1925 L19.8069,8.0795 L18.5109,9.3745 L14.6239,5.4885 L15.9199,4.1925 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
