import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { BsPlus } from 'react-icons/bs';
import { Button, Box, Grid, Container } from '@mui/material';
import { toast } from 'react-hot-toast';
import styles from './styles';
import { validateAgainstSchema } from '../../utils/schemaValidation';
import IntegrationCard from '../../components/IntegrationCard';
import vevaLogo from '../../images/veva_collect_icon.png';
import useMutateServerProfile from '../../hooks/useMutateServerProfile';
import VevaCheckInDialog from '../services/veva/VevaCheckInDialog';
import useServerProfile from '../../hooks/useServerProfile';
import { Typography, Paper } from '@mui/material';
import IntegrationSuggestion from '../modals/IntegrationSuggestion.js';
import useDisclosure from '../../hooks/useDisclosure';

const useStyles = makeStyles(styles);

const Integrations = () => {
  const { profile } = useServerProfile();

  const [vevaCheckIn, setVevaCheckIn] = useState(false);
  const [setGoodSplitsOpen] = useState(false);
  const classes = useStyles();
  const { mutate } = useMutateServerProfile();

  const [isOpen] = useState(true);

  const fakeData = [
    {
      name: 'Veva Collect Check-in',
      description:
        'Send hi-res audio, session files, and more to your collaborators. Using Veva Collect, everyone who works on a project can automatically contribute their credit with one simple tap.',
      integrated: false,
      toolTipTitle: 'Veva Logo',
      image: vevaLogo,
    },
  ];

  const [integrations, setIntegrations] = useState(fakeData);
  const [filteredIntegrations, setFilteredIntegrations] = useState(fakeData);

  const {
    isOpen: isIntegrationSuggestionOpen,
    onOpen: onIntegrationSuggestionOpen,
    onClose: onIntegrationSuggestionClose,
  } = useDisclosure(false);

  const handleIntegrationOpen = (serviceName) => {
    if (serviceName.toLowerCase().indexOf('veva') >= 0) {
      setVevaCheckIn(true);
    } else if (serviceName.toLowerCase().indexOf('splits') >= 0) {
      setGoodSplitsOpen(true);
    }
  };

  const handleFieldSharing = async (serviceName) => {
    let updatedIndex = profile.PassportData?.enabledIntegrations?.findIndex(
      (s) => s.name === serviceName
    );
    let passportData;

    if (updatedIndex >= 0) {
      profile.PassportData.enabledIntegrations[updatedIndex].integrated = true;
      passportData = {
        ...profile.PassportData,
        enabledIntegrations: [...profile.PassportData.enabledIntegrations],
      };
    } else {
      let missingService = fakeData.findIndex((s) => s.name === serviceName);

      passportData = {
        ...profile.PassportData,
        enabledIntegrations: [
          ...(profile.PassportData?.enabledIntegrations || []),
          {
            name: serviceName,
            integrated: true,
            description: fakeData[missingService].description,
          },
        ],
      };
    }

    const { valid, error } = validateAgainstSchema(passportData, 'passport');
    if (valid) {
      try {
        await mutate(passportData, { throwOnError: true });
        let filteredData = filteredIntegrations;
        let filteredIndex = filteredData.findIndex(
          (s) => s.name === serviceName
        );
        filteredData[filteredIndex].integrated = true;

        let data = integrations;
        let index = data.findIndex((s) => s.name === serviceName);
        data[index].integrated = true;

        setIntegrations(() => [...data]);
        setFilteredIntegrations(() => [...filteredData]);
        toast.success('Profile has been updated');
      } catch (err) {
        toast.error(`Error Saving Profile ${err.message}`);
      }
    } else {
      toast.error(`Error Saving Profile ${error}`);
    }
  };

  useEffect(() => {
    if (profile.PassportData && profile.PassportData.enabledIntegrations) {
      let data = integrations;
      let filteredData = filteredIntegrations;

      profile.PassportData.enabledIntegrations.map((value, idx) => {
        if (value.integrated === true) {
          let filteredIndex = filteredData.findIndex(
            (s) => s.name === value.name
          );
          if (filteredIndex >= 0) {
            filteredData[filteredIndex].integrated = true;
          }
          let index = data.findIndex((s) => s.name === value.name);
          if (index >= 0) {
            data[index].integrated = true;
          }
        }
        return idx;
      });
    }
  }, [profile, filteredIntegrations, integrations]);

  let currentFilteredIntegrations = filteredIntegrations.map((integration) => {
    const integrationKey = `key-${integration.name}`;

    return (
      <IntegrationCard
        key={integrationKey}
        title={integration.name}
        description={integration.description}
        integrated={integration.integrated}
        toolTipTitle={integration.toolTipTitle}
        image={integration.image}
        handleIntegrationOpen={handleIntegrationOpen}
        handleFieldSharing={handleFieldSharing}
      />
    );
  });

  return (
    <Container>
      {isOpen && (
        <Paper elevation={0} className={classes.welcome}>
          <IntegrationSuggestion
            isOpen={isIntegrationSuggestionOpen}
            onClose={onIntegrationSuggestionClose}
          />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item sm={12} md={8}>
              <Typography variant="body1" fontWeight="bold">
                Enhance your creative passport functionality by integrating with
                3rd party services.
              </Typography>
              <Typography variant="body1">
                We're working to add more integrations, so if you dont find one
                you were looking for, let us know!
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
              <Box>
                <Button
                  onClick={onIntegrationSuggestionOpen}
                  size="small"
                  className={classes.progressBtn}
                >
                  <BsPlus className={classes.progressBtnIcon} />
                  <span>Suggest an integration</span>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Grid container spacing={3} className={classes.cards}>
        {currentFilteredIntegrations}
      </Grid>
      <VevaCheckInDialog
        open={vevaCheckIn}
        onClose={() => setVevaCheckIn(false)}
      />
    </Container>
  );
};

export default Integrations;
