import { Input, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Delete from '../../../icons/atomsIconsDelete';
import CPVisibleToggle from '../../CPVisibleToggle';

export const EditBio = ({
  type,
  handleChange,
  onDelete,
  setIsShow,
  dataField,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: '#f4f5f6',
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={0.7} alignItems="center">
          <Typography
            sx={{
              color: '#626466',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {type}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1.5} alignItems="center">
          <CPVisibleToggle
            darker={true}
            onClick={() => setIsShow()}
            dataField={dataField}
          />
          <Box
            sx={{
              backgroundColor: 'white',
              boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
              height: '35px',
              width: '35px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}
            onClick={() => onDelete(type)}
          >
            <Delete
              sx={{
                fontSize: '40px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Stack>
      </Stack>

      <Input
        value={dataField.value}
        onChange={handleChange}
        multiline
        placeholder={`Add a ${type} bio`}
        sx={{
          width: '100%',
          mt: 3,
        }}
        inputProps={{
          maxLength: type === 'headline' ? 100 : type === 'short' ? 500 : '',
        }}
      />

      <Typography sx={{ float: 'right', fontWeight: 'bold', color: '#313233' }}>
        {type === 'short' && dataField.value?.length > 0
          ? `${dataField.value.length}/500`
          : ''}
        {type === 'headline' && dataField.value?.length > 0
          ? `${dataField.value.length}/100`
          : ''}
      </Typography>
    </Box>
  );
};
