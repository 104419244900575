import { buttonStyles } from '../../../layout/styles';

const styles = (theme) => ({
  professionalEditor: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  artisticEditor: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  add: {
    borderRadius: '1rem',
  },
  formFlex: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(8),
  },
  formFlexChild: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  inputField: {
    width: '100%',
  },
  myLabel: {
    margin: '0',
    marginBottom: theme.spacing(1),
    fontWeight: '600',
    color: '#777778',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  emptyPlaceholder: {
    padding: theme.spacing(1.5),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d3d3d2',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  emptyPlaceholderText: {
    margin: 0,
    width: '90%',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#7d7d7d',
  },
  emptyPlaceholderSVG: {
    fontSize: '25px',
    color: '#d3d2d2',
  },
  aliasFlex: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  alias: {
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderRadius: '12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7f8f9',
    },
  },
  aliasActive: {
    backgroundColor: '#f7f8f9',
  },
  aliasCircle: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    border: '2px solid #8c8c8c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  aliasCircleInner: {
    backgroundColor: '#8c8c8c',
    height: theme.spacing(2),
    width: theme.spacing(2),
    borderRadius: '50%',
  },
  aliasFlexText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.3),
  },
  aftIcon: { fontSize: '35px', color: '#8c8c8c' },
  aftP: { fontSize: '12px', color: '#777777' },
  link: {
    transition: '0.3s',
    display: 'block',
    textDecoration: 'underline',
    color: '#5079A1',
    textDecorationColor: '#5078A150',
    '&:hover': {
      textDecorationColor: '#5079A1',
    },
  },
  ...buttonStyles,
});

export default styles;
