import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import * as containers from '../containers';
import OptinsWrapper from './OptinsWrapper';
import SupportPage from '../containers/support/SupportPage';
import ServicesPage from '../containers/services/ServicesPage';
import PrivateRoute from './PrivateRoute';
import AccountToBeDeleted from './AccountToBeDeleted';
import AccountRestored from './AccountRestored';
import GetInvolved from '../containers/GetInvolved';
import { ProfileContextProvider } from '../containers/passportData/context';

const privateRoutes = [
  { path: '/', component: containers.profile },
  { path: '/passportData', component: containers.profile },
  { path: '/yoti/callback', component: containers.yotiCallback },
  { path: '/verifications/ipn', component: containers.pplCallback },
  { path: '/change_password', component: containers.changePasswordPage },
  { path: '/settings', component: containers.settings },
  { path: '/integrations', component: containers.integrations },
  { path: '/get_involved', component: GetInvolved },
  { path: '/services', component: ServicesPage },
  { path: '/support', component: SupportPage },
  { path: '/deleted', component: AccountToBeDeleted, allowDeleted: true },
  { path: '/account_restored', component: AccountRestored },
];

const privatePaths = privateRoutes.map((p) => p.path);

const SignedInRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PrivateRoute exact path={privatePaths}>
          <ProfileContextProvider>
            <OptinsWrapper>
              {privateRoutes.map((props, i) => {
                return <Route key={i} exact {...props} />;
              })}
            </OptinsWrapper>
          </ProfileContextProvider>
        </PrivateRoute>
        <Route component={containers.pageNotFound} />
      </Switch>
    </Suspense>
  );
};

export default SignedInRoutes;
