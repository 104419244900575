import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="100%"
          x2=".091%"
          y1="97.664%"
          y2="2.423%"
        >
          <stop offset=".091%" stopColor="#FF2667"></stop>
          <stop offset="50.981%" stopColor="#73AEE6"></stop>
          <stop offset="100%" stopColor="#0FC"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="url(#linearGradient-1)" transform="translate(-932 -570)">
          <g transform="translate(260 95)">
            <g transform="translate(672 475)">
              <path d="M11.373 2l11.111 10-11.11 10L10 20.474 18.303 13H2v-2h16.305L10 3.526 11.373 2z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
