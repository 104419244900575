import { buttonStyles } from '../../../layout/styles';

const styles = (theme) => ({
  main: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  section: {
    height: theme.spacing(7),
    width: '100%',
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  sectionText: {
    fontWeight: '900',
  },
  link: {
    transition: '0.3s',
    display: 'block',
    textDecoration: 'underline',
    color: '#5079A1',
    textDecorationColor: '#5078A150',
    '&:hover': {
      textDecorationColor: '#5079A1',
    },
  },
  ...buttonStyles,
});

export default styles;
