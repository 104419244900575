import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAuthManager } from './auth/AuthProvider';
import useServerProfile from '../hooks/useServerProfile';
import useCancelAccountDeletion from '../hooks/useCancelAccountDeletion';
import { useProfileContext } from '../containers/passportData/context';

const styles = () => ({
  dialogTitle: {
    '& .MuiTypography-h6': {
      textTransform: 'none',
    },
  },
  dialogText: {
    textTransform: 'none',
  },
});

const AccountToBeDeleted = ({ classes }) => {
  const authManager = useAuthManager();
  const history = useHistory();
  const [cancelRequested, setCancelRequested] = useState(false);

  let { profile } = useServerProfile();
  const { dispatch } = useProfileContext();

  const { mutateAsync: cancelAccountDeletion } = useCancelAccountDeletion();

  if (!profile.Delete) {
    return <Redirect to={cancelRequested ? '/account_restored' : '/'} />;
  }

  const handleCancelDeletion = () => {
    setCancelRequested(true);
    cancelAccountDeletion().catch((err) => {
      console.error(err);
    });
  };

  const handleClose = () => {
    authManager.logout().then((success) => {
      if (success) {
        dispatch({
          type: 'POPULATE',
          payload: { artistProfiles: [] },
        });
        history.push('/signin');
      }
    });
  };

  let deletionDate = new Date();
  if (profile) {
    var d = new Date(profile.DeleteAt);
    deletionDate = d.toDateString();
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Dialog
          onClick={(e) => e.stopPropagation()}
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            Account scheduled for deletion
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogText}
            >
              This account has been scheduled for deletion by {deletionDate}. If
              you want to restore your account, click "Restore Account". After{' '}
              {deletionDate} your data will be completely erased.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.cancelButton}
            >
              Exit
            </Button>
            <Button
              onClick={handleCancelDeletion}
              color="primary"
              className={classes.confimButton}
            >
              Restore Account
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

AccountToBeDeleted.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountToBeDeleted);
