import { Grid, Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PassportIcon from '../../icons/idIcon';
import React, { useState } from 'react';
import CPAddButton from '../CPAddButton';
import IconTextButton from '../IconTextButton';
import { useMutatePatchProfile } from '../../hooks/useMutateServerProfile';
import toast from 'react-hot-toast';
import Plus from '../../icons/atomsIconsAdd';
import Modal from '../ModalNoForm';
import shortid from 'shortid';
import { EditID } from './EditID';
import { Id } from './ID';
import TitleInfo from '../Title';
import { toggleIsPublished } from '../../services.js';
import { useProfileContext } from '../../containers/passportData/context';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  main: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '18px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  edit: {
    padding: '0.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    gap: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const IDs = ({ artistProfile }) => {
  const [isni, setIsni] = useState({ id: 'isni', ...artistProfile.isni });
  const [ipn, setIpn] = useState({ id: 'ipn', ...artistProfile.ipn });
  const [ipi, setIpi] = useState({ id: 'ipi', ...artistProfile.ipi });
  const [customIds, setCustomIds] = useState(
    artistProfile.customIds?.value || []
  );
  const [edit, setEdit] = useState(false);
  const [editISNI, setEditISNI] = useState(false);
  const [editIPN, setEditIPN] = useState(false);
  const [editIPI, setEditIPI] = useState(false);
  const { mutateAsync, isLoading } = useMutatePatchProfile();
  const classes = useStyles();
  const { dispatch } = useProfileContext();

  const sortableItems = [
    isni,
    ipn,
    ipi,
    ...customIds.map((cid) => ({ id: cid.uniqueKey, ...cid })),
  ].filter(Boolean);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (['isni', 'ipn', 'ipi'].includes(active.id)) {
      toast.error('You can only re-arrange your custom IDs');
      return;
    }

    if (['isni', 'ipn', 'ipi'].includes(over.id)) {
      toast.error('You can only re-arrange your custom IDs');
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = sortableItems.findIndex((item) => item.id === active.id);
      const newIndex = sortableItems.findIndex((item) => item.id === over.id);

      const newOrder = arrayMove(sortableItems, oldIndex, newIndex);

      setIsni(newOrder.find((item) => item.id === 'isni') || {});
      setIpn(newOrder.find((item) => item.id === 'ipn') || {});
      setIpi(newOrder.find((item) => item.id === 'ipi') || {});
      setCustomIds(
        newOrder.filter((item) => !['isni', 'ipn', 'ipi'].includes(item.id))
      );
    }
  };

  const rows = [
    artistProfile.isni,
    artistProfile.ipn,
    artistProfile.ipi,
    ...(artistProfile.customIds?.value || []),
  ];

  const isFewValues = rows.filter((value) => !!value).length <= 2;

  const handleClose = () => {
    setEdit(false);
    setEditISNI(false);
    setEditIPI(false);
    setEditIPN(false);
  };

  const addCustomField = () => {
    const payload = {
      uniqueKey: shortid.generate(),
      fieldName: '',
      value: '',
      publishers: [],
    };
    setCustomIds([...customIds, payload]);
  };

  const handleSubmit = async () => {
    const payload = {
      id: artistProfile.id,
      isni: { ...isni },
      ipn: { ...ipn },
      ipi: { ...ipi },
      customIds: { value: customIds.map(({ id, ...rest }) => ({ ...rest })) },
    };

    delete payload.isni.id;
    delete payload.ipn.id;
    delete payload.ipi.id;

    try {
      await mutateAsync(payload);
      dispatch({
        type: 'PATCH_PROFILE',
        payload,
      });
      handleClose();
      toast.success('Profile info updated successfully');
    } catch (err) {
      const message = err.response?.data?.Error || err.message;
      toast.error(message);
    }
  };

  const handlePrivacy = (key) => {
    if (key === 'isni') {
      setIsni((prevState) => ({
        ...prevState,
        publishers: toggleIsPublished(prevState),
      }));
    } else if (key === 'ipn') {
      setIpn((prevState) => ({
        ...prevState,
        publishers: toggleIsPublished(prevState),
      }));
    } else if (key === 'ipi') {
      setIpi((prevState) => ({
        ...prevState,
        publishers: toggleIsPublished(prevState),
      }));
    } else {
      setCustomIds((prevState) =>
        prevState.map((cid) =>
          cid && cid.uniqueKey === key
            ? { ...cid, publishers: toggleIsPublished(cid) }
            : cid
        )
      );
    }
  };

  const handleChange = (e, { key, isType }) => {
    if (key === 'isni') {
      setIsni((prevState) => ({
        ...prevState,
        value: e.target.value,
      }));
    } else if (key === 'ipn') {
      setIpn((prevState) => ({
        ...prevState,
        value: e.target.value,
      }));
    } else if (key === 'ipi') {
      setIpi((prevState) => ({
        ...prevState,
        value: e.target.value,
      }));
    } else {
      setCustomIds((prevState) =>
        prevState.map((cid) => {
          if (cid.uniqueKey === key) {
            if (isType) return { ...cid, fieldName: e.target.value };
            return { ...cid, value: e.target.value };
          } else {
            return cid;
          }
        })
      );
    }
  };

  const handleDelete = (key) => {
    if (key === 'isni') {
      setIsni({});
    } else if (key === 'ipn') {
      setIpn({});
    } else if (key === 'ipi') {
      setIpi({});
    } else {
      setCustomIds((prevState) =>
        prevState.filter((cid) => cid.uniqueKey !== key)
      );
    }
  };

  const isNotEmpty =
    isni?.value ||
    ipi?.value ||
    ipn?.value ||
    customIds.some((cid) => cid.value);
  return (
    <>
      <Modal
        open={edit}
        onClose={handleClose}
        onSave={handleSubmit}
        isLoading={isLoading}
        aria-labelledby="edit/create ID"
        aria-describedby="editing and creating IDs"
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={sortableItems}
            strategy={verticalListSortingStrategy}
          >
            <Stack direction="column" className={classes.edit}>
              {editISNI || isni?.value ? (
                <EditID
                  key="isni"
                  id="isni"
                  type="isni"
                  message="ISNI is a global standard number for identifying the millions of contributors to creative works. It is not used exclusively by the music industry, like IPNs and IPIs. Not all music makers have an ISNI… or maybe you have one and do not even know. To check whether you have an ISNI you can search their public database at isni.org"
                  value={isni?.value}
                  handleChange={(e) => handleChange(e, { key: 'isni' })}
                  onDelete={(type) => {
                    handleDelete(type);
                    setEditISNI(false);
                  }}
                  setIsShow={() => handlePrivacy('isni')}
                  dataField={isni}
                />
              ) : (
                <CPAddButton size="small" onClick={() => setEditISNI(true)}>
                  ADD AN ISNI
                </CPAddButton>
              )}
              {editIPN || ipn?.value ? (
                <EditID
                  key="ipn"
                  id="ipn"
                  type="ipn"
                  message="The IPN is the International Performer Number. It is an identifier that the music industry uses to identify and pay you if you are a performer and/or producer. If you are a PPL member, the IPN will be your PPL membership number, for instance. You can find your IPN in your royalties statement or online portal of the Performing Rights Society you are a member of as a performer/producer. Our first IPN verification partner is PPL. If you are a PPL member, click “verify my Industry Identifiers” to receive your verified IPN."
                  value={ipn?.value}
                  handleChange={(e) => handleChange(e, { key: 'ipn' })}
                  onDelete={(type) => {
                    handleDelete(type);
                    setEditIPN(false);
                  }}
                  setIsShow={() => handlePrivacy('ipn')}
                  dataField={ipn}
                />
              ) : (
                <CPAddButton size="small" onClick={() => setEditIPN(true)}>
                  ADD AN IPN
                </CPAddButton>
              )}
              {editIPI || ipi?.value ? (
                <EditID
                  key="ipi"
                  id="ipi"
                  type="ipi"
                  message="The IPI is the Interested Party Information. It is an identifier that the music industry uses to identify and pay you if you are a composer/lyricist/songwriter. If you are a PRS member, the IPI will be your PRS membership number, or former CAE, for instance. You can find your IPI in your royalties statement or online portal of the Performing Rights Society you are a member of as a songwriter/composer."
                  value={ipi?.value}
                  handleChange={(e) => handleChange(e, { key: 'ipi' })}
                  onDelete={(type) => {
                    handleDelete(type);
                    setEditIPI(false);
                  }}
                  setIsShow={() => handlePrivacy('ipi')}
                  dataField={ipi}
                />
              ) : (
                <CPAddButton size="small" onClick={() => setEditIPI(true)}>
                  ADD AN IPI
                </CPAddButton>
              )}
              {customIds.map((cid) =>
                cid ? (
                  <EditID
                    key={cid.uniqueKey}
                    id={cid.uniqueKey}
                    type={cid.fieldName}
                    value={cid.value}
                    isCustom={true}
                    handleChange={(e, { isType = false } = {}) =>
                      handleChange(e, { key: cid.uniqueKey, isType })
                    }
                    onDelete={() => handleDelete(cid.uniqueKey)}
                    setIsShow={() => handlePrivacy(cid.uniqueKey)}
                    dataField={cid}
                  />
                ) : null
              )}
              <CPAddButton size="small" onClick={() => addCustomField()}>
                ADD Custom ID
              </CPAddButton>
            </Stack>
          </SortableContext>
        </DndContext>
      </Modal>
      <Box className={classes.root}>
        <TitleInfo
          title="IDs"
          isNotEmpty={isNotEmpty}
          message="Edit IDs"
          onClick={() => setEdit(true)}
        />
        {isNotEmpty ? (
          <Grid container spacing={2}>
            {artistProfile.isni?.value && (
              <Grid item xs={12} sm={isFewValues ? 12 : 6}>
                <Id
                  key={'isni'}
                  item={{ ...artistProfile.isni, fieldName: 'isni' }}
                />
              </Grid>
            )}
            {artistProfile.ipn?.value && (
              <Grid item xs={12} sm={isFewValues ? 12 : 6}>
                <Id
                  key={'ipn'}
                  item={{ ...artistProfile.ipn, fieldName: 'ipn' }}
                />
              </Grid>
            )}
            {artistProfile.ipi?.value && (
              <Grid item xs={12} sm={isFewValues ? 12 : 6}>
                <Id
                  key={'ipi'}
                  item={{ ...artistProfile.ipi, fieldName: 'ipi' }}
                />
              </Grid>
            )}
            {artistProfile.customIds?.value &&
              artistProfile.customIds.value.map((cid) => (
                <Grid item xs={12} sm={isFewValues ? 12 : 6}>
                  <Id key={cid.id} item={cid} />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Box className={classes.main}>
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <PassportIcon sx={{ width: '32px' }} />
              <Typography variant="body1">
                If you have any music IDs such as ISNI or IPN you can list them
                here
              </Typography>
              <IconTextButton
                onClick={() => setEdit(true)}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your ids
              </IconTextButton>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default IDs;
