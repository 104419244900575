import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import { useProfileContext } from '../../containers/passportData/context';
import { useMutatePatchProfile } from '../../hooks/useMutateServerProfile';
import { toggleIsPublished } from '../../services.js';
import toast from 'react-hot-toast';
import Modal from '../ModalNoForm';
import IconTextButton from '../IconTextButton';
import Plus from '../../icons/atomsIconsAdd';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CPVisibleIcon from '../CPVisibleIcon';
import { EditBio } from './EditBio';
import CPAddButton from '../CPAddButton';
import TitleInfo from '../Title';
import AtomHand from '../../icons/atomHand';

const useStyles = makeStyles(styles);
const subBios = ['short', 'long', 'headline'];

const TabPanel = (props) => {
  const classes = useStyles();
  const { data, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ display: 'flex' }} sx={{ p: 2 }}>
          <Typography style={{ flex: 1, whiteSpace: 'pre-wrap' }}>
            {data.value}
          </Typography>
          <Box style={{ marginRight: '-10px', marginTop: '-10px' }}>
            <CPVisibleIcon dataField={data} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Bio = ({ data, id }) => {
  const { dispatch } = useProfileContext();
  const [edit, setEdit] = useState(false);
  const [editFields, setEditFields] = useState({
    long: false,
    short: false,
    headline: false,
  });
  const [bio, setBio] = useState(data || {});
  const { isLoading, mutateAsync } = useMutatePatchProfile();

  const classes = useStyles();

  const [bioTabIndex, setBioTabIndex] = React.useState(0);

  const handleSaveBio = async () => {
    try {
      const payload = { id, biography: bio };
      await mutateAsync(payload);
      dispatch({
        type: 'PATCH_PROFILE',
        payload,
      });
      setEdit(false);
      setEditFields({
        long: false,
        short: false,
        headline: false,
      });
      toast.success('Biography updated successfully');
    } catch (err) {
      const message = err.response?.data?.Error || err.message;
      toast.error(message);
    }
  };

  const handleClose = () => {
    setBio(data);
    setEdit(false);
    setEditFields({
      long: false,
      short: false,
      headline: false,
    });
  };

  // check if there is any bio
  const isBioEmpty = !bio || !Object.values(bio).some((data) => data.value);

  return (
    <Box className={classes.root}>
      <Modal
        onSave={handleSaveBio}
        onClose={handleClose}
        open={edit}
        isLoading={isLoading}
      >
        <div className={classes.modal}>
          {subBios.map((key) => (
            <>
              {editFields[key] || (bio && bio[key] && bio[key].value) ? (
                <EditBio
                  type={key}
                  multiline={true}
                  handleChange={(e) =>
                    setBio({
                      ...bio,
                      [key]: { ...(bio[key] || {}), value: e.target.value },
                    })
                  }
                  onDelete={() => {
                    setBio(
                      bio && bio[key]
                        ? { ...bio, [key]: { ...bio[key], value: '' } }
                        : bio
                    );
                    setEditFields({ ...editFields, [key]: false });
                    setBioTabIndex(0);
                  }}
                  setIsShow={() =>
                    setBio({
                      ...bio,
                      [key]: {
                        ...bio[key],
                        publishers: toggleIsPublished(bio[key]),
                      },
                    })
                  }
                  dataField={
                    bio && bio[key] ? bio[key] : { value: '', publishers: [] }
                  }
                />
              ) : (
                <CPAddButton
                  size="small"
                  onClick={() => setEditFields({ ...editFields, [key]: true })}
                >
                  Add a {key} bio
                </CPAddButton>
              )}
            </>
          ))}
        </div>
      </Modal>
      {bio && Object.keys(bio).length && !isBioEmpty ? (
        <>
          <TitleInfo
            title="Bios"
            isNotEmpty={true}
            message="Edit bio"
            onClick={() => setEdit(true)}
          />

          <Box>
            <Tabs
              className={classes.tabs}
              value={bioTabIndex}
              onChange={(e, newIndex) => setBioTabIndex(newIndex)}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {bio &&
                Object.entries(bio)
                  .filter(([_, data]) => data.value)
                  .map(([key, _], index) => (
                    <Tab
                      key={key}
                      label={key}
                      {...a11yProps(key)}
                      className={classes.tab}
                      sx={{
                        display:
                          Object.entries(bio).filter(([_, data]) => data.value)
                            .length === 1
                            ? 'none'
                            : 'inline',
                      }}
                    />
                  ))}
            </Tabs>
          </Box>
          {Object.entries(bio)
            .filter(([_, data]) => data.value)
            .map(([key, data], i) => (
              <TabPanel key={key} value={bioTabIndex} index={i} data={data} />
            ))}
        </>
      ) : (
        <>
          <TitleInfo title="Bios" message="Edit Bios" />
          <Box className={classes.empty}>
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <AtomHand sx={{ width: '32px' }} />
              <Typography variant="body1">
                Add a couple of lines about yourself
              </Typography>
              <IconTextButton
                onClick={() => setEdit(true)}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your bio
              </IconTextButton>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Bio;
