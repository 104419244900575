import React from 'react';
import { SvgIcon } from '@mui/material';
import shortid from 'shortid';

function Icon({ hasGradient, sx, ...props }) {
  const { color } = props;
  const gradientId = shortid.generate();

  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? `url(#gradient-${gradientId})` : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <title>Tools and Gear</title>
      <g
        id="PWA---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-1173.000000, -724.000000)"
          fill={color || '#626466'}
        >
          <g id="Combined-Shape" transform="translate(1172.000000, 723.000000)">
            <path d="M21,1 L23,3 L23,21 L21,23 L3,23 L1,21 L1,3 L3,1 L21,1 Z M21,3 L4,3 L4,20 L21,20 L21,3 Z M12.0002,11.9996 L12.0002,18.9996 L5.0002,18.9996 L5.0002,11.9996 L12.0002,11.9996 Z M17.0002,3.9996 L17.0002,10.9996 L20.0002,10.9996 L20.0002,15.9996 L19.0002,16.9996 L17.0002,16.9996 L17.0002,18.9996 L15.0002,18.9996 L15.0002,16.9996 L13.0002,16.9996 L13.0002,11.9996 L14.0002,10.9996 L15.0002,10.9996 L15.0002,3.9996 L17.0002,3.9996 Z M10.0002,13.9996 L8.0002,13.9996 L8.0002,15.9996 L10.0002,15.9996 L10.0002,13.9996 Z M18.0002,12.9996 L16.0002,12.9996 L16.0002,13.9996 L18.0002,13.9996 L18.0002,12.9996 Z M12.0002,3.9996 L12.0002,10.9996 L5.0002,10.9996 L5.0002,3.9996 L12.0002,3.9996 Z M10.0002,5.9996 L8.0002,5.9996 L8.0002,7.9996 L10.0002,7.9996 L10.0002,5.9996 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
