import { useQuery } from 'react-query';
import { useAuth, useAuthManager } from '../components/auth/AuthProvider';
import { getPublicPageViews } from '../scripts';
function usePublicPageViews(personaId) {
  const authManager = useAuthManager();
  const { authState } = useAuth();
  const { isLoading, isIdle, isSuccess, isError, status, error, data } =
    useQuery(
      ['publicPageViews', personaId],
      () =>
        authManager
          .getJwtToken()
          .then((jwtToken) => getPublicPageViews(jwtToken, personaId))
          .catch()
          .then((res) => res.data),
      {
        enabled: authState === 'LOGGED_IN',
      }
    );
  return {
    isLoading: isLoading || isIdle,
    isSuccess,
    isError,
    status,
    error,
    publicPageViews: data || { thisWeek: 0, thisMonth: 0, thisYear: 0 },
  };
}
export default usePublicPageViews;
