import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  dialogTitle: {
    '& .MuiTypography-h6': {
      textTransform: 'capitalize',
    },
  },
  dialogText: {
    textTransform: 'none',
  },
});

function Confirmable({
  title,
  message,
  confirmButton = 'Confirm',
  cancelButton = 'Cancel',
  onConfirm,
  onCancel,
  render,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleConfirm = (e) => {
    setOpen(false);
    onConfirm && onConfirm(e);
  };
  const handleCancel = (e) => {
    setOpen(false);
    onCancel && onCancel(e);
  };
  const confirm = () => {
    setOpen(true);
  };
  return (
    <>
      <Dialog
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogText}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {cancelButton}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {confirmButton}
          </Button>
        </DialogActions>
      </Dialog>
      {render(confirm)}
    </>
  );
}
export default Confirmable;
