const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: 'inherit',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
  },
  main: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1536px',
    },
    width: '100%',
    margin: '0rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      margin: '0rem 1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      margin: '0rem 0rem',
    },
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
    },
  },
  none: {
    backgroundColor: theme.palette.background.dark,
    textTransform: 'none',
    padding: '0.5rem 1rem 0.5rem 1rem',
  },
  next: {
    textTransform: 'none',
  },
  link: {
    transition: '0.3s',
    display: 'block',
    textDecoration: 'underline',
    color: '#5079A1',
    textDecorationColor: '#5078A150',
    '&:hover': {
      textDecorationColor: '#5079A1',
    },
    marginTop: theme.spacing(1),
  },
  edit: {
    fontSize: '12px',
    color: '#313233',
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
  personasSelector: {
    padding: '1rem 0rem 0rem 0rem',
  },
  personas: {
    height: '47px',
    fontWeight: '500',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '12px',
    color: '#313233',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px',
    margin: '0px 10px 10px 0px',
    '&:hover': {
      backgroundColor: '#E3EEFA',
    },
  },
  personaName: {
    verticalAlign: 'middle',
  },
  typeIcon: {
    verticalAlign: 'middle',
    height: '16px',
    paddingRight: '10px',
  },
});

export default styles;

export const buttonStyles = {
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '20px',
    margin: '1rem',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255, 1)',
    padding: '0.5rem 1rem 0.5rem 1rem',
    borderRadius: '28px',
    boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    transition: '0.3s',
    border: '1px solid rgba(0,0,0,0)',
    '&:disabled': {
      border: '1px solid rgba(0,0,0,0.08)',
      opacity: 0.6,
      cursor: 'not-allowed',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255, 1)',
      },
    },
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
  buttonSolid: {
    backgroundColor: '#a2a2a2',
    color: 'white',
    fontWeight: 'bold',
  },
  buttonsNext: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  buttonsNextP: {
    backgroundColor: '#F7F8F9',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    height: '3rem',
    borderRadius: '1.5rem',
    color: '#626466',
    justifyContent: 'center',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
};
