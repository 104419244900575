import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import HelpIcon from '@mui/icons-material/Help';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: '12pt',
    fontWeight: 'bold',
  },
});

const SupportPage = ({ classes }) => {
  return (
    <>
      <Typography
        component="h1"
        variant="h6"
        className={classes.title}
        gutterBottom
      >
        Support
      </Typography>
      <List>
        <ListItem
          button
          component="a"
          href="https://www.creativepassport.net/faq/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText
            primary="FAQ"
            secondary="We provide answers to some of the most frequently asked questions in our FAQ"
            primaryTypographyProps={{ component: 'h2' }}
          />
        </ListItem>
        <ListItem
          button
          component="a"
          href="mailto:support@creativepassport.net"
        >
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            primary="Email support"
            secondary="If your question isn't covered in the FAQ then contact us via email at support@creativepassport.net"
            primaryTypographyProps={{ component: 'h2' }}
          />
        </ListItem>
      </List>
    </>
  );
};

SupportPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SupportPage);
