const styles = (theme) => ({
  personas: {
    fontWeight: '500',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '12px',
    color: '#313233',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
    },
  },
});

export default styles;
