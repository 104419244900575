import React, { useState } from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Box,
  IconButton,
  DialogContent,
} from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { makeStyles } from '@mui/styles';
import ProfileIcon from '../../icons/atomProfile';
import RefreshIcon from '@mui/icons-material/Refresh';
import ProfileTypesExampleSets from './ProfileTypesExampleSets.js';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  proPanel: {
    backgroundColor: '#E3EEFA',
    padding: '1rem',
    borderRadius: '1rem',
  },
  artPanel: {
    backgroundColor: '#F7F8F9',
    padding: '1rem',
    borderRadius: '1rem',
  },
  panelTitle: {
    marginBottom: '1rem',
  },
  proExamplePanel: {
    backgroundColor: '#E3EEFA',
    padding: '1rem',
    borderRadius: '1rem',
  },
  artExamplePanel: {
    backgroundColor: '#F7F8F9',
    padding: '1rem',
    borderRadius: '1rem',
    minHeight: '54px',
  },
  typeIcon: {
    marginRight: '6px',
    height: '16px',
  },
  refreshButton: {
    width: '25px',
    height: '25px',
    marginLeft: '3px',
  },
  refreshIcon: {
    width: '20px',
    height: '20px',
  },
  textWithIconStyle: {
    paddingRight: '30px',
  },
}));

const ProfileTypes = ({ isOpen, onClose }) => {
  const classes = useStyles();

  // State to keep track of the current index of the example set
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);

  // Function to handle RefreshIcon click and go to the next example set
  const handleRefreshClick = () => {
    // Calculate the next index based on the current index and the number of example sets
    const nextIndex =
      (currentExampleIndex + 1) % ProfileTypesExampleSets.length;
    // Update the state to show the next set of examples
    setCurrentExampleIndex(nextIndex);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">Profile Types</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.proPanel}>
              <Typography variant="h4" className={classes.panelTitle}>
                Professional Profile
              </Typography>
              <Typography>
                This is related to your legal identity. It's your full legal
                name and information related to your career as a music
                professional under that name.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.artPanel}>
              <Typography variant="h4" className={classes.panelTitle}>
                Artistic Profiles
              </Typography>
              <Typography>
                These are for any bands, collectives, orchestras, and other
                projects you're a part of. You can create as many as you like,
                and add unique data for each one.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">
              Example
              <IconButton
                className={classes.refreshButton}
                onClick={handleRefreshClick}
              >
                <RefreshIcon className={classes.refreshIcon} />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.proExamplePanel}>
              <Typography>
                <ProfileIcon className={classes.typeIcon} />
                {ProfileTypesExampleSets[currentExampleIndex].name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.artExamplePanel}>
              {ProfileTypesExampleSets[currentExampleIndex].bands.map(
                (band, index) => (
                  <Typography key={index}>
                    {band.icon}
                    {band.text}
                  </Typography>
                )
              )}
            </Box>
          </Grid>
        </Grid>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CgClose />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileTypes;
