import countries from 'i18n-iso-countries';
import CPAutocomplete from '../../../../../components/CPAutocomplete';
import AtomsFeatures from '../../../../../icons/atomsFeatures';
import AtomsRoles from '../../../../../icons/atomsRoles';
import LocationIcon from '../../../../../icons/atomsLocation';
import { avs } from '../../../../../text_fields';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const SubForm = ({ artistProfile, useStyles, patchTempProfiles }) => {
  const classes = useStyles();

  const handleFeaturesChange = (e, newValues) => {
    patchTempProfiles({ id: artistProfile.id, features: { value: newValues } });
  };

  const handleCountriesChange = (e, newValue) => {
    patchTempProfiles({
      id: artistProfile.id,
      countryAlpha2: { value: newValue },
    });
  };

  const handleMusicRoles = (e, newValues) => {
    patchTempProfiles({
      id: artistProfile.id,
      musicRoles: { value: newValues },
    });
  };
  return (
    <div className={classes.main}>
      <div>
        <p className={classes.myLabel}>
          <AtomsFeatures style={{ marginRight: '12px' }} />
          Keywords
        </p>
        <p style={{ color: '#313233', fontSize: '14px', fontWeight: '400' }}>
          Specify any top features and defining characteristics of{' '}
          <b>{artistProfile?.artistName}</b>. Type nouns, adjectives, tags, or
          keywords.
        </p>
        <CPAutocomplete
          options={[]}
          value={artistProfile.features?.value || []}
          onChange={handleFeaturesChange}
          placeholder="Girlband, Queer, Independent, Punk"
          multiple={true}
          freeSolo
        />
      </div>

      {/* Role */}
      <div>
        <p className={classes.myLabel}>
          <AtomsRoles style={{ marginRight: '12px' }} />
          Roles
        </p>
        <p style={{ color: '#313233', fontSize: '14px', fontWeight: '400' }}>
          Specify your roles in <b>{artistProfile?.artistName}</b>.
        </p>
        <CPAutocomplete
          options={avs.musicRoles.values}
          value={artistProfile.musicRoles?.value || []}
          onChange={handleMusicRoles}
          placeholder="e.g. Singer, Dancer, Producer, Guitarist"
          multiple={true}
          freeSolo
        />
      </div>

      {/* Country */}
      <div>
        <p className={classes.myLabel}>
          <LocationIcon style={{ marginRight: '12px' }} />
          Location
        </p>
        <CPAutocomplete
          options={Object.keys(countries.getNames('EN'))}
          value={artistProfile.countryAlpha2?.value}
          getOptionLabel={(x) => countries.getName(x, 'EN') ?? ''}
          onChange={handleCountriesChange}
          placeholder={`Add a country for ${artistProfile?.artistName}.`}
          multiple={false}
        />
      </div>
    </div>
  );
};

export default SubForm;
