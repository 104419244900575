import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePublicProfileContext } from '../../containers/public-profile';
import { makeStyles } from '@mui/styles';
import TitleInfo from '../Title';

const useStyles = makeStyles((theme) => ({
  idsBox: {
    margin: theme.spacing(2, 0),
    width: '100%',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0rem',
    },
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  idsTitleBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.blue.dark,
    width: 'fit-content',
    padding: '0.3rem 0.7rem',
    borderRadius: '0.5rem',
  },
  idsTitle: {
    margin: 'auto',
    fontWeight: '700',
    color: theme.palette.blue.lighter,
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  idsValue: {
    marginTop: theme.spacing(1),
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: '700',
  },
}));

const Ids = () => {
  const classes = useStyles();
  const publicProfileData = usePublicProfileContext();
  if (!publicProfileData) return null;
  if (
    !publicProfileData.ipn &&
    !publicProfileData.isni &&
    !publicProfileData.ipi &&
    !publicProfileData.customIds?.length
  )
    return null;
  return (
    <React.Fragment>
      <Box mb={4}>
        <TitleInfo title="IDs" />
        {publicProfileData.ipn && (
          <IdsComponent
            title="IPN"
            classes={classes}
            value={publicProfileData.ipn}
          />
        )}
        {publicProfileData.isni && (
          <IdsComponent
            title="ISNI"
            classes={classes}
            value={publicProfileData.isni}
          />
        )}
        {publicProfileData.ipi && (
          <IdsComponent
            title="IPI"
            classes={classes}
            value={publicProfileData.ipi}
          />
        )}
        {publicProfileData.customIds &&
          publicProfileData.customIds.map((customId, i) => (
            <IdsComponent
              title={customId.fieldName}
              value={customId.value}
              key={i}
              classes={classes}
            />
          ))}
      </Box>
    </React.Fragment>
  );
};

const IdsComponent = ({ title, value, classes }) => {
  return (
    <Box className={classes.idsBox}>
      <Box className={classes.idsTitleBox}>
        <Typography className={classes.idsTitle}>{title}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Typography className={classes.idsValue}>{value}</Typography>
      </Box>
    </Box>
  );
};

export default Ids;
