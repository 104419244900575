import React from 'react';
import { SvgIcon } from '@mui/material';
import shortid from 'shortid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = shortid.generate();
  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? `url(#gradient-${gradientId})` : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={hasGradient ? null : '#626466'}>
          <path
            d="M11.9884,7.9996 C12.6284,7.9996 13.2704,8.1656 13.8444,8.4976 C14.9934,9.1606 15.7014,10.3866 15.7014,11.7126 L15.7014,11.7126 L15.7014,14.6536 L12.7284,17.3336 L16.5674,17.3336 L18.0004,21.4286 L15.4444,23.9996 L8.5554,23.9996 L6.0004,21.4286 L7.4324,17.3336 L11.2494,17.3336 L8.2754,14.6536 L8.2754,11.7126 C8.2754,11.5466 8.2874,11.3826 8.3084,11.2216 C8.4374,10.2506 8.9464,9.3686 9.7224,8.7716 C9.8514,8.6726 9.9884,8.5806 10.1314,8.4976 C10.1944,8.4616 10.2604,8.4346 10.3254,8.4026 C10.8484,8.1396 11.4174,7.9996 11.9884,7.9996 Z M13.242,-0.0007 L15,1.7573 L15,4.7643 L14.482,4.7643 L24,23.9993 L21.765,23.9993 L16.701,13.7603 L16.701,11.7123 C16.701,10.0353 15.798,8.4703 14.345,7.6313 C14.071,7.4743 13.781,7.3543 13.483,7.2543 L13.483,7.2543 L12.252,4.7643 L11.748,4.7643 L10.52,7.2443 C10.213,7.3453 9.913,7.4683 9.632,7.6313 C8.179,8.4703 7.275,10.0353 7.275,11.7123 L7.275,11.7123 L7.275,13.8003 L2.227,23.9993 L0,23.9993 L9.516,4.7643 L9,4.7643 L9,1.7573 L10.758,-0.0007 L13.242,-0.0007 Z M15.1484,19.3336 L8.8514,19.3336 L8.4294,20.5386 L9.8814,21.9996 L14.6124,21.9996 L15.6984,20.9066 L15.1484,19.3336 Z M11.9884,9.9996 C11.6884,9.9996 11.3914,10.0796 11.1314,10.2296 C10.6034,10.5346 10.2754,11.1036 10.2754,11.7126 L10.2754,11.7126 L10.2754,13.3306 L12.2284,15.0916 L13.7014,13.7646 L13.7014,11.7126 C13.7014,11.1036 13.3734,10.5346 12.8444,10.2296 C12.5854,10.0796 12.2894,9.9996 11.9884,9.9996 Z M12.414,1.9993 L11.586,1.9993 L11,2.5853 L11,2.7643 L13,2.7643 L13,2.5853 L12.414,1.9993 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
