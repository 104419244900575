import { forwardRef } from 'react';
import { Input, Stack, Typography, Checkbox, Box, Link } from '@mui/material';
import AtomsIconsTick from '../../../icons/atomsIconsTick';

export const MyCheckbox = forwardRef((props, ref) => {
  const style = { height: '20px', width: '20px', borderRadius: '2px' };

  return (
    <Checkbox
      size="small"
      sx={{
        '&.MuiCheckbox-root': {
          padding: '2px',
        },
      }}
      icon={
        <Box
          sx={{
            ...style,
            backgroundColor: '#dbdbdb',
          }}
        ></Box>
      }
      checkedIcon={
        <Box
          sx={{
            ...style,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            backgroundImage:
              'linear-gradient(-54deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
          }}
        >
          <AtomsIconsTick sx={{ fontSize: '20px' }} />
        </Box>
      }
      {...props}
      ref={ref}
    />
  );
});

const Register = ({ register, agree, setAgree }) => {
  return (
    <Box>
      <Stack
        sx={{
          width: '100%',
          marginTop: '45px',
        }}
        spacing={{
          xs: '48px',
          lg: '48px',
        }}
      >
        <Input
          {...register('email')}
          type="email"
          placeholder="insert your email address"
        />
        <Input
          {...register('password')}
          type="password"
          placeholder="type a safe password"
        />
      </Stack>
      <Stack
        spacing={{
          xs: '16px',
          lg: '16px',
        }}
        alignItems="flex-start"
        sx={{
          marginTop: {
            xs: '45px',
            lg: '35px',
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing="7px"
          sx={{
            borderRadius: '8px',
            padding: '8px',
            backgroundColor: 'rgba(247,248,249, 1)',
            width: '100%',
          }}
        >
          <MyCheckbox
            onChange={(e) => setAgree(e.target.checked)}
            value={agree}
          />
          <Typography
            fontSize={{
              xs: '14px',
              lg: '14px',
            }}
          >
            I agree to The Creative Passport's&nbsp;
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.creativepassport.net/terms/"
            >
              Privacy Policy & Terms of Use
            </Link>{' '}
          </Typography>
        </Stack>
        {/*
        <Stack
          direction="row"
          alignItems="center"
          spacing="7px"
          sx={{
            borderRadius: '8px',
            padding: '8px',
            backgroundColor: 'rgba(247,248,249, 1)',
            width: '100%',
          }}
        >
          <MyCheckbox {...register('newsletter')} />
          <Typography
            fontSize={{
              xs: '14px',
              lg: '14px',
            }}
          >
            Join our mailing list (once in a while, for relevant information
            only, we promise)
          </Typography>
        </Stack>
            */}
      </Stack>
    </Box>
  );
};

export default Register;
