import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withRedirectOnNotAuth from '../../hocs/withRedirectOnNotAuth';
import useServerProfile from '../../hooks/useServerProfile';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';
import TabComponent from '../../components/TabComponent';
import Connections from '../../components/Connections';

import BandIcon from '../../icons/atomDrum';
import SoloIcon from '../../icons/atomSoloProject';
import OrchestraIcon from '../../icons/atomOrchestra';
import OtherIcon from '../../icons/atomPuzzle';
import CollectiveIcon from '../../icons/atomGroup';
import ProfileIcon from '../../icons/atomProfile';

import { useProfileContext } from './context';
import toast from 'react-hot-toast';
import { deleteProfile } from '../../scripts';
import { useAuthManager } from '../../components/auth/AuthProvider';
import IconTextButton from '../../components/IconTextButton';
import { MdBolt } from 'react-icons/md';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ProfileCompletion from '../../components/ProfileCompletion';
import PublicPageVisualization from '../../components/PublicPageVisualization';
import WhatElseCanYouDo from '../../components/WhatElseCanYouDo';
import GuidanceAndSupport from '../../components/GuidanceAndSupport';
import AtomsNotification from '../../icons/atomsNotification';
import AtomsNotificationSelected from '../../icons/atomsNotificationSelected';
import AtomsThunderSelected from '../../icons/atomsThunderSelected';
import AtomsThunder from '../../icons/atomsThunder';

const useStyles = makeStyles(styles);

const TabContent1 = () => {
  // Content for tab 1
  return (
    <Box mb={4} sx={{ width: '100vw', px: 2 }}>
      <ProfileCompletion />
      <PublicPageVisualization />
      <WhatElseCanYouDo />
      <GuidanceAndSupport />
    </Box>
  );
};

const TabContent2 = () => {
  // Content for tab 2
  return (
    <Box mb={4} sx={{ width: '100vw', px: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.paper',
          p: 3,
          borderRadius: '16px',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src="/images/icon/gear.svg"
          sx={{ width: 70, height: 70 }}
        />
        <Typography sx={{ fontWeight: 800, color: '#000', fontSize: 18 }}>
          Work in progress
        </Typography>
        <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
          This functionality is still under construction
        </Typography>
      </Box>
    </Box>
  );
};

const Profile = () => {
  const { state, dispatch } = useProfileContext();
  const classes = useStyles();
  const { profile } = useServerProfile();
  const authManager = useAuthManager();
  const [activeTab, setActiveTab] = useState(0);
  const [personaId, setPersonaId] = useState(state.artistProfiles[0].id);
  const [activeSecTab, setActiveSecTab] = useState(0);
  const [newID, setNewID] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [section, setSection] = useState(1);

  const handleActiveTab = (e, newValue) => {
    const adjustedValue = Math.max(0, newValue - 1);

    dispatch({
      type: 'SET_TAB',
      payload: newValue,
    });
    setPersonaId(state.artistProfiles[adjustedValue].id);
    setActiveTab(newValue);
  };
  const handleActiveSecTab = (e, newValue) => {
    setActiveSecTab(newValue);
  };

  const handleCreateNewTab = () => {
    const newID = uuidv4();
    setNewID(newID);
    // TODO this should generate a schema-compliant data structure
    const preExistingTabs = state.artistProfiles.length;
    dispatch({
      type: 'ADD_TAB',
      payload: {
        id: newID,
        isProfessionalProfile: false,
        type: 'band',
      },
    });
    handleActiveTab(null, preExistingTabs);
  };

  const handleTabDelete = async (personaId, isNew = false) => {
    setIsDeleting(true);
    setActiveTab(0);
    try {
      dispatch({
        type: 'REMOVE_TAB',
        payload: {
          id: personaId,
        },
      });
    } catch (err) {
      toast.error('Could not delete profile (local)');
    }
    if (!isNew) {
      // we only delete if we already posted to server, but new profiles haven't been
      try {
        await deleteProfile(personaId, await authManager.getJwtToken());
      } catch (error) {
        toast.error('Could not delete profile (remote)');
      }
    }
    setIsDeleting(false);
  };

  const getAtomIcon = (artisticType, isActive, className) => {
    switch (artisticType) {
      case 'professional':
        return (
          <ProfileIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'solo':
        return (
          <SoloIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'band':
        return (
          <BandIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'orchestra':
        return (
          <OrchestraIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'collective':
        return (
          <CollectiveIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      default:
        return (
          <OtherIcon hasGradient={isActive} className={classes.typeIcon} />
        );
    }
  };

  return (
    <>
      {section === 2 ? (
        <React.Fragment>
          <Box className={classes.mobileTab}>
            <Box
              sx={{
                px: 1,
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => setSection(section === 1 ? 2 : 1)}
                className={classes.tabUnselected}
                disableRipple
                disableFocusRipple
              >
                <AiOutlineArrowLeft />
              </IconButton>
            </Box>
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
              value={activeSecTab}
              onChange={handleActiveSecTab}
              className={classes.leftSecTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="artist profile tabs"
            >
              <Tab
                className={classes.leftSecTab}
                label={
                  <IconButton
                    className={classes.icon}
                    disableRipple
                    disableFocusRipple
                  >
                    {activeSecTab === 0 ? (
                      <AtomsThunderSelected
                        className={classes.actionsIcon}
                        fill={!Boolean(activeSecTab)}
                      />
                    ) : (
                      <AtomsThunder className={classes.actionsIcon} />
                    )}
                  </IconButton>
                }
              />
              <Tab
                className={classes.leftSecTab}
                label={
                  <IconButton
                    className={classes.icon}
                    disableRipple
                    disableFocusRipple
                  >
                    {activeSecTab === 0 ? (
                      <AtomsNotification
                        className={classes.notificationsIcon}
                      />
                    ) : (
                      <AtomsNotificationSelected
                        className={classes.notificationsIcon}
                        fill={Boolean(activeSecTab)}
                      />
                    )}
                  </IconButton>
                }
              />
            </Tabs>
          </Box>
          {/* ... Existing code */}
          {activeSecTab === 0 && <TabContent1 />}
          {activeSecTab === 1 && <TabContent2 />}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box className={classes.mobileTab}>
            <Box>
              {state.artistProfiles.length && (
                <Select
                  sx={{
                    px: 1,
                    ml: 1,
                  }}
                  disableUnderline
                  className={classes.dropdown}
                  value={`${activeTab}`}
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {getAtomIcon(
                        state.artistProfiles[selected].isProfessionalProfile
                          ? 'professional'
                          : state.artistProfiles[selected].type,
                        true,
                        classes.selectedMenuIcon
                      )}
                      {state.artistProfiles[selected].artistName}
                    </div>
                  )}
                  label="try"
                  variant="outlined"
                >
                  {state.artistProfiles.map((artistProfile, index) => (
                    <MenuItem
                      onClick={() => setActiveTab(index)}
                      key={index}
                      value={`${index}`}
                    >
                      <ListItemIcon>
                        {getAtomIcon(
                          artistProfile.isProfessionalProfile
                            ? 'professional'
                            : artistProfile.type,
                          activeTab === index,
                          classes.menuIcon
                        )}
                      </ListItemIcon>
                      <ListItemText primary={artistProfile.artistName} />
                    </MenuItem>
                  ))}
                </Select>
              )}
              {state.artistProfiles.length === 0 ? (
                <IconTextButton
                  size="large"
                  onClick={handleCreateNewTab}
                  icon={<AddIcon size="small" />}
                >
                  Artistic Profile
                </IconTextButton>
              ) : (
                <IconButton
                  onClick={handleCreateNewTab}
                  className={classes.tabAddSingle}
                >
                  <AddIcon size="small" className={classes.tabAddIcon} />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                px: 1,
                ml: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => setSection(section === 1 ? 2 : 1)}
                className={classes.tabUnselected}
                disableRipple
                disableFocusRipple
              >
                <MdBolt />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.main}>
            <Box className={classes.root}>
              <Box className={classes.container}>
                <Box className={classes.topRow}>
                  {/* Persona Tabs (Desktop)*/}
                  <Tabs
                    TabIndicatorProps={{
                      style: { backgroundColor: 'transparent' },
                    }}
                    value={activeTab}
                    onChange={handleActiveTab}
                    className={classes.tabs}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="artist profile tabs"
                  >
                    {state.artistProfiles.map((artistProfile, index) => (
                      <Tooltip
                        key={artistProfile.id}
                        title={artistProfile.artistName}
                        disableHoverListener
                      >
                        <Tab
                          icon={
                            <IconButton
                              disableRipple
                              style={{ height: '100%' }}
                            >
                              {getAtomIcon(
                                artistProfile.isProfessionalProfile
                                  ? 'professional'
                                  : artistProfile.type,
                                activeTab === index
                              )}
                            </IconButton>
                          }
                          className={classes.tab}
                          aria-label={artistProfile.artistName}
                          label={artistProfile.artistName}
                        />
                      </Tooltip>
                    ))}
                  </Tabs>
                  {/* Additional profile buttons*/}
                  {state.artistProfiles.length === 1 ? (
                    <IconTextButton
                      size="large"
                      onClick={handleCreateNewTab}
                      icon={<AddIcon size="small" />}
                    >
                      Artistic Profile
                    </IconTextButton>
                  ) : (
                    <IconButton
                      onClick={handleCreateNewTab}
                      className={classes.tabAddSingle}
                    >
                      <AddIcon size="small" className={classes.tabAddIcon} />
                    </IconButton>
                  )}
                </Box>
                <Box
                  sx={
                    {
                      // bgcolor: 'red',
                    }
                  }
                >
                  {state.artistProfiles.map(
                    (ap, id) =>
                      id === activeTab && (
                        <div key={ap.id}>
                          <TabComponent
                            {...ap}
                            artistProfile={ap}
                            personalData={state.personalData}
                            profile={{
                              ...profile,
                              PassportData: {
                                ...profile.PassportData,
                                ...state,
                              },
                            }}
                            onDelete={handleTabDelete}
                            isDeleting={isDeleting}
                            isNew={newID === ap.id}
                            onNoLongerNew={() => setNewID('')}
                          />
                        </div>
                      )
                  )}
                </Box>
              </Box>
            </Box>
            <Connections personaId={personaId} />
          </Box>
        </React.Fragment>
      )}
    </>
  );
};

export default withRedirectOnNotAuth(Profile);
