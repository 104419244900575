import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AtomsEyePrivateDefault from '../icons/defaultEyePrivate';
import AtomsEyePublicDefault from '../icons/defaultEyePublic';
import { isPublished } from '../services.js';

const useStyles = makeStyles((theme) => ({
  eye: {
    color: '#ACAFB2',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
  },
  eyeActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
  },
}));

const CPVisibleIcon = ({ dataField, fontSizePublic, fontSizePrivate }) => {
  const classes = useStyles();

  return (
    <div className={isPublished(dataField) ? classes.eyeActive : classes.eye}>
      {!isPublished(dataField) ? (
        <AtomsEyePrivateDefault
          sx={{ fontSize: fontSizePrivate ? fontSizePrivate : '16px' }}
        />
      ) : (
        <AtomsEyePublicDefault
          sx={{ fontSize: fontSizePublic ? fontSizePublic : '16px' }}
        />
      )}
    </div>
  );
};

export default CPVisibleIcon;
