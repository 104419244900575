import { useEffect, useState } from 'react';
import { RadioGroup } from '@mui/material';
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { useAuthManager } from '../../../components/auth/AuthProvider';
import useServerProfile from '../../../hooks/useServerProfile';
import { vevaCheckIn, vevaGetIds } from '../../../scripts';
import { checkIsni } from '../../../utils/validation';
import CPRadio from '../../../components/CPRadio';
import Button from '../../../components/Button';
import Checkmark from '../../../icons/tick';

function allowEmpty(schema) {
  return schema.transform(function (value, originalValue) {
    if (this.isType(value) && typeof value !== 'string') return value;
    if (!originalValue || !originalValue.trim()) {
      return undefined;
    }
    // we return the invalid original value
    return originalValue;
  });
}

function emptyString() {
  return allowEmpty(yup.string());
}

function emptyNumber() {
  return allowEmpty(yup.number());
}

function testBirthDate(value) {
  if (!value) return true;
  const date = new Date(value);
  return date < new Date() && date.getFullYear() > 1900;
}

const schema = yup.object().shape({
  title: yup.string().label('Title'),
  firstName: yup.string().label('First Name').required(),
  middleName: yup.string().label('Middle Name'),
  lastName: yup.string().label('Last Name').required(),
  email: emptyString().label('Email').email().required(),
  suffix: emptyString().label('Suffix'),
  isni: checkIsni(emptyString().label('ISNI')),
  birthDate: emptyString()
    .label('Birth date')
    // eslint-disable-next-line no-template-curly-in-string
    .test('is-date', '${path} is not a valid date', testBirthDate),
  role: yup.string().oneOf(['performer', 'nonperformer']),
  instrumentId: emptyNumber()
    .label('Instrument')
    .integer('Must be an integer')
    .positive('Must be positive')
    .when('role', (role, schema) =>
      role === 'performer' ? schema.required() : schema
    ),
  roleId: emptyNumber()
    .label('Role')
    .integer('Must be an integer')
    .positive('Must be positive')
    .when('role', (role, schema) =>
      role === 'nonperformer' ? schema.required() : schema
    ),
});

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      color: 'red',
    },
    saveButton: {
      backgroundColor: '#FFFFFF',
      paddingLeft: '4px',
      fontSize: 12,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gap: 6,
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    },
    cancelButton: {
      backgroundColor: theme.palette.background.dark,
      fontSize: '13px',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    },
    role: {
      padding: '1px 10px 1px 1px',
      cursor: 'pointer',
      width: '100%',
      height: '40px',
    },
    activeRole: {
      backgroundColor: '#F7F8F9',
      borderRadius: '8px',
    },
  })
);

function getInitialState(profile) {
  let state = {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    suffix: '',
    isni: '',
    birthDate: null,
    role: 'performer',
    instrumentId: null,
    roleId: null,
    isniOptions: [],
  };
  let isoDateOfBirth = undefined;
  let hasName = false;
  const personalData = profile?.PassportData?.personalData;
  if (personalData) {
    for (const attr of ['firstName', 'lastName']) {
      if (personalData[attr] && personalData[attr] !== '') {
        state[attr] = personalData[attr];
        hasName = true;
      }
    }
    if (personalData.dateOfBirth && personalData.dateOfBirth !== '') {
      isoDateOfBirth = personalData.dateOfBirth;
    }
  }

  const yotiData = profile?.VerifiedData?.yoti;
  if (yotiData) {
    // Don't override user configured name with Yoti
    if (!hasName) {
      const fullName = yotiData.fullName?.value;
      if (fullName) {
        let names = fullName.split(' ').filter((p) => p !== '');
        if (names.length > 0) {
          state.firstName = names[0];
        }
        if (names.length > 1) {
          state.lastName = names[names.length - 1];
        }
        if (names.length > 2) {
          state.middleName = names.slice(1, names.length - 1);
        }
      }
    }
    isoDateOfBirth = isoDateOfBirth || yotiData.dateOfBirth?.value;
  }
  if (isoDateOfBirth) {
    const [year, month, day] = isoDateOfBirth.split('-');
    if (year && month && day) {
      state.birthDate = {
        year,
        month,
        day,
      };
    }
  }

  if (profile.PassportData) {
    state.email = profile.PassportData.email || '';
    if (profile.PassportData.artistProfiles) {
      for (const persona of profile.PassportData.artistProfiles) {
        if (persona.isni?.value) {
          state.isniOptions.push(persona.isni?.value);
        }
      }
    }
    if (state.isniOptions.length > 0) {
      state.isni = state.isniOptions[0];
    }
  }
  return state;
}

function SessionDetails({ sessionName, accessToken, onNext, onCancel }) {
  const { profile } = useServerProfile();
  const [state, setState] = useState(() => getInitialState(profile));
  const [error, setError] = useState('');
  const [ids, setIds] = useState();
  const [submitting, setSubmitting] = useState(false);
  const classes = useStyles();
  const authManager = useAuthManager();
  const handleChange = (event) => {
    setState((s) => ({ ...s, [event.target.name]: event.target.value }));
  };

  useEffect(() => {
    authManager
      .getJwtToken()
      .then((jwtToken) => vevaGetIds(jwtToken))
      .then(({ data }) => {
        setIds(data);
      });
  }, [authManager]);

  const handleNext = (e) => {
    e.preventDefault();
    try {
      const data = schema.validateSync(state);
      setError('');
      setSubmitting(true);
      let req = {
        accessToken,
        title: data.title,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        email: data.email,
        suffix: data.suffix,
        isni: data.isni,
        birthDate: data.birthDate,
        role: data.role,
      };
      switch (state.role) {
        case 'performer':
          req.instrumentId = data.instrumentId;
          break;
        case 'nonperformer':
          req.roleId = data.roleId;
          break;
        default:
      }

      authManager
        .getJwtToken()
        .then((jwtToken) => vevaCheckIn(req, jwtToken))
        .then(({ data: { success } }) => {
          if (!success) {
            setError('Invalid session code');
            setSubmitting(false);
            return;
          }
          setSubmitting(false);
          // window.fathom.trackGoal('VDDATKEX', 0); // VEVA Collect check-in goal
          onNext();
        })
        .catch((e) => {
          setError('Error checking into session');
          setSubmitting(false);
        });
      // Woo
    } catch (e) {
      if (e instanceof ValidationError) {
        setError(e.errors.join(' '));
      } else {
        setError('Invalid data');
      }
    }
  };
  const sharedProps = {
    fullWidth: true,
  };
  const sharedPropsTextField = {
    ...sharedProps,
    variant: 'standard',
    onChange: handleChange,
  };

  return (
    <form onSubmit={handleNext}>
      <DialogContent>
        <DialogContentText>Check into session {sessionName}</DialogContentText>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              {...sharedPropsTextField}
              value={state.title}
              name="title"
              label="Title"
              autoFocus
            />
          </Grid>
          <Grid item sm={6} xs />
          <Grid item sm={6} xs={12}>
            <TextField
              {...sharedPropsTextField}
              value={state.firstName}
              name="firstName"
              label="First Name"
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              {...sharedPropsTextField}
              value={state.middleName}
              name="middleName"
              label="Middle Name"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              {...sharedPropsTextField}
              value={state.lastName}
              name="lastName"
              label="Last Name"
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              {...sharedPropsTextField}
              value={state.suffix}
              name="suffix"
              label="Suffix"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...sharedPropsTextField}
              value={state.email}
              name="email"
              type="email"
              label="Email"
              required
            />
          </Grid>
          <Grid item xs={12}>
            {state.isniOptions.length > 1 ? (
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={state.isni}
                options={state.isniOptions}
                disableClearable
                autoSelect
                onChange={(e, v) =>
                  v
                    ? setState((s) => ({ ...s, isni: v }))
                    : setState((s) => ({ ...s, isni: '' }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...sharedPropsTextField}
                    name="isni"
                    label="ISNI"
                  />
                )}
              />
            ) : (
              <TextField
                {...sharedPropsTextField}
                value={state.isni}
                name="isni"
                label="ISNI"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">Date of Birth</Typography>
            <TextField
              {...sharedPropsTextField}
              name="birthDate"
              value={state.birthDate}
              type="date"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              aria-label="role"
              name="role"
              value={state.role}
              onChange={handleChange}
            >
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    className={`${classes.role} ${
                      state.role === 'performer' ? classes.activeRole : ''
                    }`}
                    value="performer"
                    control={<CPRadio />}
                    label="Performer"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    className={`${classes.role} ${
                      state.role === 'nonperformer' ? classes.activeRole : ''
                    }`}
                    value="nonperformer"
                    control={<CPRadio />}
                    label="Non Performer"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
          {state.role === 'performer' ? (
            <Grid item xs={12}>
              <Autocomplete
                onChange={(e, v) =>
                  v
                    ? setState((s) => ({ ...s, instrumentId: v.id }))
                    : setState((s) => ({ ...s, instrumentId: null }))
                }
                loading={!ids}
                options={ids ? ids.instruments : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...sharedProps}
                    variant="standard"
                    label="Instrument"
                    required
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Autocomplete
                onChange={(e, v) =>
                  v
                    ? setState((s) => ({ ...s, roleId: v.id }))
                    : setState((s) => ({ ...s, roleId: null }))
                }
                loading={!ids}
                options={ids ? ids.roles : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...sharedProps}
                    variant="standard"
                    label="Role"
                    required
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <div className={classes.error}>{error}</div>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          className={classes.cancelButton}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="submit"
          className={classes.saveButton}
          disabled={submitting}
        >
          <Checkmark sx={{ fontSize: 12 }} />
          Check In
        </Button>
      </DialogActions>
    </form>
  );
}
export default SessionDetails;
