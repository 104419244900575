import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import styles from './styles';
import CPAddButton from '../../CPAddButton';
import CPVisibleToggle from '../../CPVisibleToggle';
import Plus from '../../../icons/atomsIconsAdd';
import SuggestionIcon from '../../../icons/suggestion';
import { avs } from '../../../text_fields';
import { languages } from 'countries-list';
import CPAutocomplete from '../../CPAutocomplete';

const useStyles = makeStyles(styles);

const title2field = (title) => {
  return title === 'supported causes'
    ? 'charitiesSupported'
    : title === 'favorite artists'
    ? 'favouriteMusicMakers'
    : title === 'interests / inspirations'
    ? 'interestsInspiration'
    : title === 'spoken languages'
    ? 'spokenLanguages'
    : title === 'tools and gear'
    ? 'tools'
    : title === 'endorsements'
    ? 'endorsement'
    : title === 'skills'
    ? 'otherSkills'
    : title === 'roles'
    ? 'musicRoles'
    : title === 'keywords'
    ? 'features'
    : title === 'quotes'
    ? 'favouriteQuote'
    : title;
};

const InfoEdit = ({
  Icon,
  id,
  title,
  defaultTitles,
  defaultSuggestedTitles,
  defaultUsedTitles,
  defaultOwnedTitles,
  description,
  setIsShow,
  dataField,
  hasUsed,
  setState,
  state,
}) => {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState(defaultSuggestedTitles);
  const [editMode, setEditMode] = useState(false);
  // titles
  const [titles, setTitles] = useState(defaultTitles || []);
  const [usedTitles, setUsedTitles] = useState(defaultUsedTitles);
  const [ownedTitles, setOwnedTitles] = useState(defaultOwnedTitles);

  const spokenLanguages = [];
  for (const i in languages) {
    spokenLanguages.push(languages[i].name);
  }

  const handleInput = (e, newValues) => {
    const newTitles = newValues.filter((v) => v.trim() !== '');
    setTitles(newTitles);
    setState({
      ...state,
      [title2field(title)]: {
        ...state[title2field(title)],
        value: newTitles,
      },
    });
  };

  const handleUsedInput = (e, newValues) => {
    const newTitles = newValues.filter((v) => v.trim() !== '');
    setUsedTitles(newTitles);

    const key =
      title2field(title) + (title === 'instruments' ? 'Played' : 'Used');
    setState({
      ...state,
      [key]: {
        ...state[key],
        value: newTitles,
      },
    });
  };

  const handleOwnedInput = (e, newValues) => {
    const newTitles = newValues.filter((v) => v.trim() !== '');
    setOwnedTitles(newTitles);
    const key = title2field(title) + 'Owned';
    setState({
      ...state,
      [key]: {
        ...state[key],
        value: newTitles,
      },
    });
  };

  const handleInputBySuggestion = (val) => {
    setTitles([...titles, val]);
    setSuggestions((state) => state.filter((sug) => sug !== val));
    setState({
      ...state,
      [title2field(title)]: {
        ...state[title2field(title)],
        value: [...titles, val],
      },
    });
  };

  return (
    <>
      {defaultTitles?.length ||
      defaultUsedTitles?.length ||
      defaultOwnedTitles?.length ||
      editMode ? (
        <div className={classes.root} data-testid={`${id}-edit-${title}`}>
          <div className={classes.heading}>
            <div className={classes.headingText}>
              <Icon className={classes.headingTextIcon} />
              <p className={classes.headingTextP}>{title}</p>
            </div>
            <CPVisibleToggle
              darker={true}
              onClick={() => setIsShow()}
              dataField={dataField}
            />
          </div>
          <p className={classes.description}>{description}</p>
          {suggestions.length > 0 && (
            <div className={classes.suggestions}>
              <Typography
                className={classes.suggestionsTitle}
                data-testid={`${id}-suggest-${title}`}
              >
                <SuggestionIcon />
                suggested
              </Typography>
              <div className={classes.suggestionsFlex}>
                {suggestions
                  .filter((el) => !titles.map((t) => t.title).includes(el))
                  .map((el) => (
                    <div
                      className={classes.suggestion}
                      key={el}
                      onClick={() => handleInputBySuggestion(el)}
                    >
                      <p className={classes.suggestionP}>{el}</p>
                      <Plus sx={{ fontSize: 14, color: '#5079A1' }} />
                    </div>
                  ))}
              </div>
            </div>
          )}
          {!hasUsed && (
            <div data-testid={`${id}-dropdown-${title}`}>
              <CPAutocomplete
                data-testid={`select-${title}-menu`}
                multiple
                freeSolo
                options={
                  title === 'roles'
                    ? avs.musicRoles.values
                    : title === 'keywords'
                    ? avs.keywords.values
                    : title === 'genres'
                    ? avs.genres.values
                    : title === 'spoken languages'
                    ? spokenLanguages
                    : []
                }
                value={titles}
                name={title}
                onChange={handleInput}
                placeholder="Type and hit enter"
              />
            </div>
          )}
          {hasUsed && (
            <>
              <p
                className={classes.subHeading}
                data-testid={`${id}-used-${title}`}
              >
                {title === 'instruments' ? 'can play' : 'used'}
              </p>
              <CPAutocomplete
                data-testid={`select-${title}-used-titles`}
                multiple
                fullWidth
                freeSolo
                options={
                  title === 'instruments' ? avs.instrumentsPlayed.values : []
                }
                value={usedTitles}
                onChange={handleUsedInput}
                placeholder="Type and hit enter"
              />
            </>
          )}
          {hasUsed && (
            <>
              <p
                className={classes.subHeading}
                data-testid={`${id}-owned-${title}`}
              >
                Owned
              </p>
              <CPAutocomplete
                data-testid={`select-${title}-menu`}
                multiple
                fullWidth
                freeSolo
                options={[]}
                value={ownedTitles}
                onChange={handleOwnedInput}
                placeholder="Type and hit enter"
              />
            </>
          )}
        </div>
      ) : (
        <CPAddButton
          size="small"
          onClick={() => setEditMode(true)}
          data-testid={`${id}-add-${title}-button`}
        >
          Add {title}
        </CPAddButton>
      )}
    </>
  );
};

export default InfoEdit;
