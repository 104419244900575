import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useReducer } from 'react';
import clsx from 'clsx';

export const DialogDispatchContext = React.createContext();

function dialogReducer(state, action) {
  switch (action.type) {
    case 'show': {
      // Received a new dialog
      if (!state.active) {
        // No active dialog, just show it
        return { ...state, active: action.payload };
      } else {
        // There is an active dialog, so we add it to the queue
        return { ...state, queue: [...state.queue, action.payload] };
      }
    }
    case 'close': {
      // The dialog is closing
      if (state.active.onCancel) {
        state.active.onCancel();
      }
      return { ...state, active: { ...state.active, open: false } };
    }
    case 'confirm': {
      // The dialog is confirmed
      if (state.active.onConfirm) {
        state.active.onConfirm();
      }
      return { ...state, active: { ...state.active, open: false } };
    }
    case 'exit': {
      // The dialog has finished closing
      if (state.queue.length > 0) {
        // Show the next one
        return {
          ...state,
          active: { ...state.queue[0] },
          queue: state.queue.splice(1),
        };
      } else {
        // No more dialogs, just clear active
        return { ...state, active: undefined };
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function useDialog() {
  const dispatch = React.useContext(DialogDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  function showDialogAction(
    title,
    message,
    { onConfirm, onCancel, cancelButton, confirmButton }
  ) {
    dispatch({
      type: 'show',
      payload: {
        key: new Date().getTime(),
        message,
        title,
        onConfirm,
        onCancel,
        cancelButton,
        confirmButton,
        open: true,
      },
    });
  }

  return showDialogAction;
}

const useStyles = makeStyles({
  dialogTitle: {
    '& .MuiTypography-h6': {
      textTransform: 'none',
    },
  },
  dialogText: {
    textTransform: 'none',
  },
});

function DialogProvider({ children, className }) {
  const classes = useStyles();

  const [state, dispatch] = useReducer(dialogReducer, {
    active: undefined,
    queue: [],
  });
  const handleClose = useCallback(() => dispatch({ type: 'close' }), []);
  const handleExit = useCallback(() => dispatch({ type: 'exit' }), []);
  const handleConfirm = useCallback(() => dispatch({ type: 'confirm' }), []);

  return (
    <DialogDispatchContext.Provider value={dispatch}>
      {state.active && (
        <>
          <Dialog
            onClick={(e) => e.stopPropagation()}
            key={state.active.key}
            open={state.active.open}
            onClose={handleClose}
            TransitionProps={{
              onExited: handleExit,
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              className={classes.dialogTitle}
            >
              {state.active.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogText}
              >
                {state.active.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                className={clsx(classes.cancelButton, className)}
              >
                {state.active.cancelButton || 'Cancel'}
              </Button>
              <Button
                onClick={handleConfirm}
                color="primary"
                className={clsx(classes.confimButton, className)}
                autoFocus
              >
                {state.active.confirmButton || 'Confirm'}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {children}
    </DialogDispatchContext.Provider>
  );
}

export default DialogProvider;
