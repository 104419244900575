import { useMutation } from 'react-query';
import { useAuthManager } from '../components/auth/AuthProvider';
import { postOptin } from '../scripts';

function useOptinPassport() {
  const authManager = useAuthManager();
  const { mutateAsync: requestProfileOptin, isLoading: isLoadingOptin } =
    useMutation(() => {
      return authManager
        .getJwtToken()
        .then((jwtToken) => postOptin(jwtToken))
        .then((res) => res.data);
    });

  return { requestProfileOptin, isLoadingOptin };
}

export default useOptinPassport;
