import axios from 'axios';
import { Auth } from 'aws-amplify';

export const apiBase = process.env.REACT_APP_API_BASE;

const url = apiBase + '/passport/1.0';
const publicpage = apiBase + '/public/1.0';
const analyticsUrl = apiBase + '/analytics/1.0';
const yotiUrl = url + '/verifications/yoti';
const pplUrl = url + '/verifications/ppl';
const vevaUrl = url + '/veva';

export function getProfileData(jwtToken) {
  if (!jwtToken) {
    return Promise.reject('No JWT token');
  }

  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };

  return axios.get(url + '/profile', { headers: headers });
}

export function getIsOptlessProfile(jwtToken) {
  if (!jwtToken) {
    return Promise.reject('No JWT token');
  }

  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };

  return axios.get(url + '/isoptlessprofile', { headers: headers });
}

export function postOptin(jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };

  return axios.post(url + '/optin', undefined, { headers: headers });
}

export async function updateProfileData(data, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };

  const user = await Auth.currentAuthenticatedUser();
  return await axios.put(
    url + '/profile/' + user.username,
    { PassportData: data },
    { headers: headers }
  );
}

export async function uploadImage(type, personaId, file, jwtToken) {
  // type: 'profile' | 'background'
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: await jwtToken,
  };
  // Get the s3 presigned url & form fields from the api
  const res = await axios.post(
    `${url}/${type}-image-upload/${personaId}`,
    undefined,
    { headers }
  );
  const data = res.data;
  const formData = new FormData();
  for (const [k, v] of Object.entries(data.fields)) {
    formData.append(k, v);
  }
  formData.append('file', file);

  // Post to s3 upload bucket, triggers resizing etc on the backend
  return await axios.post(data.url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function deleteProfileImage(personaId, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: await jwtToken,
  };
  return await axios.delete(`${url}/profile-image/${personaId}`, { headers });
}

export async function getImage(type, jwtToken, personaId) {
  // type: 'profile' | 'background'
  // Gets a presigned url from s3, if the image exists
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };
  try {
    const res = await axios.get(`${url}/${type}-image/${personaId}`, {
      headers,
    });
    return res.data;
  } catch (err) {
    if (err.response.status !== 404) throw err;
    return {
      image: '',
      thumbnailImageUrl: '',
      isLoading: false,
    };
  }
}

export async function getYotiShareUrl(jwtToken, attributes) {
  const headers = {
    Authorization: jwtToken,
  };
  return await axios.get(yotiUrl, { params: { attributes }, headers });
}

export async function getYOTIData(yoti_token, jwtToken) {
  const headers = {
    Authorization: jwtToken,
  };
  return axios.post(yotiUrl, { token: yoti_token }, { headers });
}

export async function getIPNNumber(pplToken, jwtToken) {
  const headers = {
    Authorization: jwtToken,
  };
  return axios.post(pplUrl, { token: pplToken }, { headers });
}

export async function removeFromMap(jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.delete(url + '/publicmap', { headers: headers });
}

export async function sendToMap(data, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.post(url + '/publicmap', data, { headers: headers });
}

export async function getFromMap(jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.get(url + '/publicmap', { headers: headers });
}

export function getPublicPageViews(jwtToken, personaId) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };

  const result = axios.get(url + '/publicpageviews/' + personaId, {
    headers: headers,
  });
  return result;
}

export function getAnalytics(jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };

  return axios.get(analyticsUrl + '/profiles/get', { headers: headers });
}

export async function deleteAccount(jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };

  return await axios.post(url + '/delete/request', undefined, {
    headers: headers,
  });
}

export async function cancelDeleteAccount(jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };

  return await axios.post(url + '/delete/cancel', undefined, {
    headers: headers,
  });
}

export async function postWelcomeQuestionnaire(data, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.post(url + '/welcomequestionnaire', data, {
    headers: headers,
  });
}

export async function vevaValidateSession(data, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.post(vevaUrl + '/session', data, {
    headers: headers,
  });
}

export async function vevaGetIds(jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.get(vevaUrl + '/ids', {
    headers: headers,
  });
}

export async function vevaCheckIn(data, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.post(vevaUrl + '/checkin', data, {
    headers: headers,
  });
}

export async function patchProfileData(data, personaId, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.patch(
    `${url}/profile/${personaId}`,
    { profilePatch: data },
    {
      headers,
    }
  );
}

export async function postProfileData(data, personaId, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.post(`${url}/profile/${personaId}`, data, {
    headers,
  });
}

export async function patchPersonalData(data, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.patch(
    `${url}/personal`,
    { personalPatch: data },
    {
      headers,
    }
  );
}

export async function deleteProfile(personaId, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.delete(`${url}/profile/${personaId}`, {
    headers,
  });
}

export async function postPassport(data, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return await axios.post(
    `${url}/passport`,
    { PassportData: data },
    {
      headers,
    }
  );
}

export async function postPPLNonce(nonce, jwtToken) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: jwtToken,
  };

  return axios.post(
    url + '/verifications/ppl/nonce',
    { nonce: nonce },
    { headers: headers }
  );
}

export function titleCase(str) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export function defaultProfile() {
  let profile = {
    PassportId: null,
    PassportData: {
      email: null,
      test_profile: false,
      version: 0.1,
      artistProfiles: [],
      personalData: {},
      enabledIntegrations: [],
    },
  };

  return profile;
}

export const getPublicProfile = async (passportId, personaId) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
  };

  const res = await axios.get(
    `${publicpage}/publicpage/${passportId}/${personaId}/profile`,
    {
      headers,
    }
  );
  return res.data;
};

export const fetchPlace = async (text, countryCode) => {
  try {
    const res = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${process.env.REACT_APP_MAP_API_KEY}&cachebuster=1625641871908&autocomplete=true&country=${countryCode}`
    );
    if (!res.ok) throw new Error(res.statusText);
    return res.json();
  } catch (err) {
    return { error: 'Unable to retrieve places' };
  }
};
