import { Auth } from 'aws-amplify';

export class AuthManager {
  constructor(queryCache) {
    this.queryCache = queryCache;
    this.listeners = [];
    this.beforeLogoutActions = [];
  }

  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((x) => x !== listener);
    };
  }

  registerLogoutAction(asyncFn) {
    this.beforeLogoutActions.push(asyncFn);
    return () => {
      this.beforeLogoutActions = this.beforeLogoutActions.filter(
        (x) => x !== asyncFn
      );
    };
  }

  notify(type, user) {
    for (const listener of this.listeners) {
      listener(type, user);
    }
  }

  notifyLoggedOut() {
    this.notify('LOGGED_OUT');
  }

  notifyLoggedIn(user) {
    this.notify('LOGGED_IN', user);
  }

  async logout() {
    try {
      for (const asyncFn of this.beforeLogoutActions) {
        await asyncFn();
      }
    } catch {
      // Before logout action promise rejected so don't log out.
      return false;
    }
    await Auth.signOut();
    this.queryCache.clear();
    this.notifyLoggedOut();
    return true;
  }

  async login(username, password) {
    if (!username || username === '' || typeof username !== 'string') {
      throw new Error('Email is required');
    }
    await Auth.signIn({ username: username.toLowerCase(), password });
    const user = await Auth.currentAuthenticatedUser();
    this.notifyLoggedIn(user);
  }

  async getJwtToken() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user.signInUserSession.idToken.jwtToken;
    } catch (error) {
      this.notifyLoggedOut();
      throw error;
    }
  }
}
