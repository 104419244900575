import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: '12pt',
    fontWeight: 'bold',
  },
  text: {
    display: 'block',
  },
});

const AccountRestored = ({ classes }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h6"
          className={classes.title}
          gutterBottom
        >
          Account Restored
        </Typography>
        <List className={classes.root}>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar>
                <EmojiEmotionsIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Your account has been restored"
              secondary={<Link href="/">Back to the Home Page</Link>}
              primaryTypographyProps={{ component: 'h2' }}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

AccountRestored.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountRestored);
