import React from "react";
import Contacts from "./SubComponents/Contacts";
import Ids from "./SubComponents/Ids";
import Projects from './SubComponents/Projects';

const ProfileSideBarContents = () => {
  return (
    <React.Fragment>
      {/* <RelatedProfile />*/}
      {/*  <Connections />*/}
      <Ids />
      <Contacts />
      <Projects />
      {/* <Connections />*/}
    </React.Fragment>
  );
};

export default ProfileSideBarContents;
