import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthProvider';
// import * as routes from '../routes'

const withRedirectOnNotAuth = (Component) => (props) => {
  const { authState } = useAuth();
  let history = useHistory();

  useEffect(() => {
    switch (authState) {
      case 'LOGGED_OUT':
        history.push('/signin');
        break;
      case 'UNCONFIRMED':
        history.push('/confirm');
        break;
      default:
    }
  }, [authState, history]);

  switch (authState) {
    case 'LOGGED_IN':
      break;
    case 'LOGGED_OUT':
    case 'UNCONFIRMED':
      return null;
    case 'INIT':
      return <div>Loading...</div>;
    default:
      return (
        <div>
          <p>Unrecognised authentication state</p>
        </div>
      );
  }
  return <Component {...props} />;
};

export default withRedirectOnNotAuth;
