import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, IconButton, Tabs, Tab, Typography } from '@mui/material';
import AtomsNotification from '../../icons/atomsNotification';
import AtomsNotificationSelected from '../../icons/atomsNotificationSelected';
import AtomsThunderSelected from '../../icons/atomsThunderSelected';
import AtomsThunder from '../../icons/atomsThunder';
import ConnectionsProfileTab from '../ConnectionsProfileTab';

const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.only('xl')]: {
      width: 'calc(33% - 3rem)',
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(33% - 3rem)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(33% - 2rem)',
    },
    justifyContent: 'end',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  root: {
    marginRight: '4rem',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    //Not yet migrated to MUI overrides, only thing this seems to be helping with is remove the gap above Tabs when 1 item only.
    width: '100%',
    minHeight: '100%',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden !important',
  },
  tab: {
    marginBottom: '20px',
    flexGrow: 1,
    height: '48px',
    textTransform: 'capitalize',
  },
  actionsIcon: {
    height: '20px',
    width: '20px',
  },
  notificationsIcon: {
    height: '18px',
    width: '18px',
  },
}));

const Connections = ({ personaId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();
  const handleActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <Box className={classes.main}>
      <Box className={classes.root}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
          value={activeTab}
          onChange={handleActiveTab}
          className={classes.tabs}
          variant="scrollable"
          scrollButtons={false}
          aria-label="artist profile tabs"
        >
          <Tab
            className={classes.tab}
            label={
              <IconButton
                className={classes.icon}
                disableRipple
                disableFocusRipple
              >
                {activeTab === 0 ? (
                  <AtomsThunderSelected
                    className={classes.actionsIcon}
                    fill={!Boolean(activeTab)}
                  />
                ) : (
                  <AtomsThunder className={classes.actionsIcon} />
                )}
              </IconButton>
            }
          />
          <Tab
            className={classes.tab}
            label={
              <IconButton
                className={classes.icon}
                disableRipple
                disableFocusRipple
              >
                {activeTab === 0 ? (
                  <AtomsNotification className={classes.notificationsIcon} />
                ) : (
                  <AtomsNotificationSelected
                    className={classes.notificationsIcon}
                    fill={Boolean(activeTab)}
                  />
                )}
              </IconButton>
            }
          />
        </Tabs>

        {activeTab === 0 ? (
          <ConnectionsProfileTab personaId={personaId} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'background.paper',
              p: 3,
              borderRadius: '16px',
              width: '100%',
            }}
          >
            <Box
              component="img"
              src="/images/icon/gear.svg"
              sx={{ width: 70, height: 70 }}
            />
            <Typography sx={{ fontWeight: 800, color: '#000', fontSize: 18 }}>
              Work in progress
            </Typography>
            <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
              This functionality is still under construction
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Connections;
