import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CPRadio from '../CPRadio';
import ImageUpload from './ImageUpload';
import { HiDotsVertical } from 'react-icons/hi';
import { lighten, darken, alpha } from '@mui/system';

import { useProfileContext } from '../../containers/passportData/context';
import { useMutatePatchProfile } from '../../hooks/useMutateServerProfile';
import { toast } from 'react-hot-toast';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { TwitterPicker } from 'react-color';
import AddIcon from '@mui/icons-material/Add';
import { uploadImage } from '../../scripts';
import { useAuthManager } from '../auth/AuthProvider';
import useFetchImage from '../../hooks/useFetchImage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      easing={{ enter: 'cubic-bezier(0, 1.3, .4, 1)', exit: 'linear' }}
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const defaultPPStyling = {
  theme: 'light',
  backgroundType: 'fill',
  customBgColor: false,
  translucentSections: false,
};

export default function SettingsModal({
  open,
  handleCancel,
  previewLink,
  artistProfile,
}) {
  const { dispatch } = useProfileContext();
  const authManager = useAuthManager();
  const [tempPPStyling, setTempPPStyling] = React.useState({
    ...defaultPPStyling,
    ...artistProfile.publicPageStyling,
  });
  const [imageToBeUploaded, setImageToBeUploaded] = React.useState(null);
  const [imageJustUploaded, setImageJustUploaded] = React.useState(null);
  const { mutateAsync } = useMutatePatchProfile();
  const [openPicker, setOpenPicker] = React.useState(false);
  const [colors, setColors] = React.useState([
    { code: '#F7F8F9', name: 'Frost', theme: 'light', default: true },
    { code: '#EEF0F4', name: 'Light Grey', theme: 'light' },
    { code: '#313233', name: 'Carbon', theme: 'dark', default: true },
    { code: '#626466', name: 'Smoke', theme: 'dark' },
  ]);
  const [selectedColor, setSelectedColor] = React.useState({
    code: '#F7F8F9',
    name: 'Frost',
    theme: 'light',
    default: true,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { thumbnail, isFetching } = useFetchImage(
    'background',
    artistProfile.id
  );
  const buttonRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const openMenu = Boolean(anchorEl);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target)
      ) {
        setOpenPicker(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [buttonRef, pickerRef]);

  // TODO set initial color(s)

  const handleColorChange = (change) => {
    if (change.delete) {
      setSelectedColor(
        colors.find((c) => c.theme === tempPPStyling.theme && c.default)
      );
      setTempPPStyling((prevState) => ({ ...prevState, customBgColor: false }));
      setColors(colors.filter((c) => c.name !== change.name));
      return;
    }
    const existingColor = colors.find((c) => c.name === change.name);
    if (existingColor) {
      setSelectedColor(existingColor);
    } else {
      setSelectedColor(change);
      setColors((prevState) => [...prevState, change]);
    }
    setTempPPStyling((prevState) => ({
      ...prevState,
      customBgColor: !change.default,
      bgColor: change.code,
    }));
  };

  const handleImageChange = (image) => {
    setImageToBeUploaded(image);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const saveChanges = async () => {
    const cleanedStyling = { ...tempPPStyling };
    if (!cleanedStyling.customBgColor) {
      delete cleanedStyling.bgColor;
    }
    const patch = {
      id: artistProfile.id,
      publicPageStyling: { ...cleanedStyling, publishers: ['publicPage'] },
    };
    mutateAsync(patch)
      .then(() =>
        dispatch({
          type: 'PATCH_PROFILE',
          payload: patch,
        })
      )
      .catch(() => toast.error('Could not save profile'));
    if (imageToBeUploaded) {
      uploadImage(
        'background',
        artistProfile.id,
        imageToBeUploaded,
        await authManager.getJwtToken()
      )
        .then(() => toast.success('Background Image Update'))
        .then(() =>
          setImageJustUploaded(URL.createObjectURL(imageToBeUploaded))
        )
        .then(() => setImageToBeUploaded(null))
        .catch((err) => toast.error('Error saving background image'));
    }
  };
  //FIXME: This whole page is a mess
  return (
    <>
      <div style={{ color: '#717375 !important' }}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            // setImagePanel(false);
            handleCancel();
          }}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            sx: {
              borderRadius: '16px',
              bgcolor: '#fff',
              width: '512px',
              margin: '0',
              maxHeight: 'calc(100% - 64px)',
              '@media (max-width: 600px)': {
                width: '100vw',
                borderRadius: '0px',
                height: '100vh',
                maxHeight: 'calc(100%)',
              },
            },
          }}
        >
          <DialogContent>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  paddingBottom: 2,
                  color: '#717375 !important',
                  fontWeight: 'bold',
                }}
              >
                Theme
              </FormLabel>
              <RadioGroup
                onChange={(event) =>
                  setTempPPStyling((prevState) => ({
                    ...prevState,
                    theme: event.target.value,
                    customBgColor: false,
                  }))
                }
                row
                sx={{ justifyContent: 'space-between' }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={tempPPStyling.theme}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  color="#717375"
                  sx={{
                    bgcolor:
                      tempPPStyling.theme === 'light'
                        ? '#f0f2f4'
                        : 'transparent',
                    flexGrow: 1,
                    borderRadius: 2,
                    color: '#717375 !important',
                  }}
                  value="light"
                  control={<CPRadio />}
                  label={<span style={{ color: '#717375' }}>Light</span>}
                />
                <FormControlLabel
                  sx={{
                    bgcolor:
                      tempPPStyling.theme === 'dark'
                        ? '#f0f2f4'
                        : 'transparent',
                    flexGrow: 1,
                    ml: 2,
                    borderRadius: 2,
                    color: '#717375 !important',
                  }}
                  value="dark"
                  control={<CPRadio />}
                  label={<span style={{ color: '#717375' }}>Dark</span>}
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ my: 2, color: '#717375 !important', fontWeight: 'bold' }}
              >
                Color
              </FormLabel>
              <Grid
                container
                spacing={2}
                mt={0}
                ml={0}
                pb={2}
                pr={2}
                sx={{
                  border: '1px solid #f0f2f4',
                  borderRadius: '0.5rem',
                  width: '100%',
                }}
              >
                {colors
                  .filter((c) =>
                    c.theme ? c.theme === tempPPStyling.theme : true
                  )
                  .map((colorOption, i) => (
                    <Grid item xs={6} sm={4} key={i}>
                      <Button
                        sx={{
                          textTransform: 'none',
                          borderRadius: 10,
                          p: 0.75,
                          color: '#717375 !important',
                          bgcolor:
                            selectedColor.name === colorOption.name
                              ? '#f0f2f5'
                              : 'transparent',
                          boxShadow: 'none',
                          ':hover': {
                            bgcolor:
                              selectedColor === colorOption.code
                                ? 'transparent'
                                : '#f0f2f5',
                          },
                        }}
                        onClick={() => handleColorChange(colorOption)}
                      >
                        <Avatar
                          sx={{
                            bgcolor: colorOption.code,
                            color: colorOption.code,
                            border: '1px solid #FFF',
                            width: '24px',
                            height: '24px',
                          }}
                        >
                          #
                        </Avatar>
                        <Typography sx={{ mx: 1 }}>
                          {colorOption.name}
                        </Typography>
                        {!colorOption.theme && (
                          <>
                            <IconButton size="small" onClick={handleClick}>
                              <HiDotsVertical />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={closeMenu}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem
                                onClick={() =>
                                  handleColorChange({
                                    ...colorOption,
                                    delete: true,
                                  })
                                }
                              >
                                Remove
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </Button>
                    </Grid>
                  ))}
                <Grid item xs={6} sm={4}>
                  <Button
                    ref={buttonRef}
                    sx={{
                      borderRadius: 10,
                      px: 2,
                      py: 1,
                      bgcolor: '#fff',
                      color: '#717375 !important',
                    }}
                    onClick={() => setOpenPicker(!openPicker)}
                  >
                    <AddIcon />
                    Custom
                  </Button>
                  <div ref={pickerRef}>
                    {openPicker && (
                      <TwitterPicker
                        width="auto"
                        color={selectedColor.code}
                        onChange={(selection) =>
                          handleColorChange({
                            code: selection.hex,
                            name: selection.hex,
                          })
                        }
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ my: 2, color: '#717375 !important', fontWeight: 'bold' }}
              >
                Sections
              </FormLabel>
              <RadioGroup
                row
                onChange={(event) =>
                  setTempPPStyling((prevState) => ({
                    ...prevState,
                    translucentSections: event.target.value === 'true',
                  }))
                }
                sx={{ justifyContent: 'space-between' }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={tempPPStyling.translucentSections}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  sx={{
                    bgcolor: tempPPStyling.translucentSections
                      ? 'transparent'
                      : '#EEF0F4',
                    flexGrow: 1.25,
                    borderRadius: 2,
                    color: '#717375 !important',
                  }}
                  value={false}
                  control={<CPRadio />}
                  label={<span style={{ color: '#717375' }}>Opaque</span>}
                />
                <FormControlLabel
                  sx={{
                    bgcolor: tempPPStyling.translucentSections
                      ? '#EEF0F4'
                      : 'transparent',
                    flexGrow: 1,
                    ml: 2,
                    borderRadius: 2,
                    color: '#717375 !important',
                  }}
                  value={true}
                  control={<CPRadio />}
                  label={<span style={{ color: '#717375' }}>Translucent</span>}
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ my: 2, color: '#717375 !important', fontWeight: 'bold' }}
              >
                Background
              </FormLabel>
              <RadioGroup
                row
                onChange={(event) =>
                  setTempPPStyling((prevState) => ({
                    ...prevState,
                    backgroundType: event.target.value,
                  }))
                }
                sx={{ justifyContent: 'space-between' }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={tempPPStyling.backgroundType}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  sx={{
                    bgcolor:
                      tempPPStyling.backgroundType === 'image'
                        ? 'transparent'
                        : '#f0f2f4',
                    flexGrow: 1,
                    borderRadius: 2,
                    color: '#717375 !important',
                  }}
                  value="fill"
                  control={<CPRadio />}
                  label={<span style={{ color: '#717375' }}>Use Colour</span>}
                />
                <FormControlLabel
                  sx={{
                    bgcolor:
                      tempPPStyling.backgroundType === 'image'
                        ? '#f0f2f4'
                        : 'transparent',
                    flexGrow: 1,
                    ml: 2,
                    borderRadius: 2,
                    color: '#717375 !important',
                  }}
                  value="image"
                  control={<CPRadio />}
                  label={<span style={{ color: '#717375' }}>Use Image</span>}
                />
              </RadioGroup>
            </FormControl>
            <Box mt={2}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  color: '#717375 !important',
                  fontWeight: 'bold',
                }}
              >
                Preview
              </FormLabel>
            </Box>
            {tempPPStyling.backgroundType === 'fill' && (
              <Box
                mt={2}
                p={2}
                sx={{
                  border: '1px solid #f0f2f4',
                  borderRadius: '0.5rem',
                  position: 'relative',
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={5.65}
                    sx={{
                      borderRadius: '0.5rem',
                      backgroundColor: selectedColor.code,
                      width: '100%',
                      minHeight: '150px',
                      maxHeight: '100%',
                    }}
                  >
                    <Grid
                      container
                      gap={1}
                      xs={6.15}
                      p={4}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Grid
                        item
                        xs={11.8}
                        sx={{
                          backgroundColor: tempPPStyling.translucentSections
                            ? alpha(
                                tempPPStyling.theme === 'light'
                                  ? lighten(selectedColor.code, 0.75)
                                  : darken(selectedColor.code, 0.75),
                                0.7
                              )
                            : tempPPStyling.theme === 'light'
                            ? lighten(selectedColor.code, 0.5)
                            : darken(selectedColor.code, 0.5),
                          borderRadius: '0.25rem',
                          height: '30%',
                        }}
                      >
                        <Typography
                          color={
                            tempPPStyling.theme === 'light'
                              ? '#000000bb'
                              : '#ffffffda'
                          }
                          variant="h2"
                          m={1}
                          sx={{
                            fontSize: '10px',
                          }}
                        >
                          The Creative Passport
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          borderRadius: '0.25rem',
                          height: '5%',
                        }}
                      >
                        <Typography
                          color={
                            tempPPStyling.theme === 'light'
                              ? '#000000bb'
                              : '#ffffffda'
                          }
                          variant="h3"
                          sx={{
                            fontSize: '8px',
                          }}
                        >
                          Profile Info
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={11.8}
                        sx={{
                          backgroundColor: tempPPStyling.translucentSections
                            ? alpha(
                                tempPPStyling.theme === 'light'
                                  ? lighten(selectedColor.code, 0.75)
                                  : darken(selectedColor.code, 0.75),
                                0.7
                              )
                            : tempPPStyling.theme === 'light'
                            ? lighten(selectedColor.code, 0.5)
                            : darken(selectedColor.code, 0.5),
                          borderRadius: '0.25rem',
                          height: '55%',
                        }}
                      ></Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6.35} pl={3}>
                    <Typography>
                      Customise your color, theme, and section styling to
                      preview the appearance of your public profile.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            {tempPPStyling.backgroundType === 'image' && (
              <Box
                mt={2}
                p={2}
                sx={{
                  border: '1px solid #f0f2f4',
                  borderRadius: '0.5rem',
                  position: 'relative',
                }}
              >
                <Grid
                  container
                  gap={1}
                  xs={6.15}
                  p={4}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Grid
                    item
                    xs={11.8}
                    sx={{
                      backgroundColor: tempPPStyling.translucentSections
                        ? alpha(
                            tempPPStyling.theme === 'light'
                              ? lighten(selectedColor.code, 0.75)
                              : darken(selectedColor.code, 0.75),
                            0.7
                          )
                        : tempPPStyling.theme === 'light'
                        ? lighten(selectedColor.code, 0.5)
                        : darken(selectedColor.code, 0.5),
                      borderRadius: '0.25rem',
                      height: '30%',
                    }}
                  >
                    <Typography
                      color={
                        tempPPStyling.theme === 'light'
                          ? '#000000bb'
                          : '#ffffffda'
                      }
                      variant="h2"
                      m={1}
                      sx={{
                        fontSize: '10px',
                      }}
                    >
                      The Creative Passport
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderRadius: '0.25rem',
                      width: '100%',
                      height: '5%',
                    }}
                  >
                    <Typography
                      color={
                        tempPPStyling.theme === 'light'
                          ? '#000000bb'
                          : '#ffffffda'
                      }
                      variant="h3"
                      sx={{
                        fontSize: '8px',
                      }}
                    >
                      Profile Info
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={11.8}
                    sx={{
                      backgroundColor: tempPPStyling.translucentSections
                        ? alpha(
                            tempPPStyling.theme === 'light'
                              ? lighten(selectedColor.code, 0.75)
                              : darken(selectedColor.code, 0.75),
                            0.7
                          )
                        : tempPPStyling.theme === 'light'
                        ? lighten(selectedColor.code, 0.5)
                        : darken(selectedColor.code, 0.5),
                      borderRadius: '0.25rem',
                      height: '55%',
                    }}
                  ></Grid>
                </Grid>
                <ImageUpload
                  src={imageJustUploaded || thumbnail}
                  alt="Public page background image"
                  handleImageChange={handleImageChange}
                  isBusy={isFetching}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              fontSize: '16px',
              textTransform: 'capitalize',
              backgroundColor: '#EEF0F4',
              color: '#717375 !important',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {/*<Button
              sx={{
                borderRadius: 10,
                px: 3,
                py: 1,
                color: '#717375 !important',
              }}
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${
                    window.location.origin
                  }/${previewLink}?preview=${theme}&color=${
                    color.split('#')[1]
                  }&type=${bg}&image=${bgImage}`,
                  '_blank'
                );
              }}
            >
              Preview
            </Button>*/}

            <Button
              sx={{
                borderRadius: 10,
                px: 3,
                py: 1,
                color: '#717375 !important',
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                borderRadius: 10,
                px: 3,
                py: 1,
                bgcolor: '#fff',
                color: '#717375 !important',
              }}
              onClick={() => {
                saveChanges();
                handleCancel();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
/*<Box
  onClick={() => setImagePanel(false)}
  sx={{
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }}
>
  <div onClick={(event) => event.stopPropagation()}>
    <Widget
      onChange={(data) => {
        console.log(data);
        setBgImage(data.cdnUrl);
        setImagePanel(false);
      }}
      publicKey={"e0a4ef7ee66f1d91ccad"}
      clearable
      previewStep
      imagesOnly
      />
  </div>
</Box>*/
