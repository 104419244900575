import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { Checkbox } from '@mui/material';

const useStyles = makeStyles(() => ({
  checkbox: {
    pointerEvents: ({ disabled }) => (disabled ? 'none' : undefined),
  },
  wrapDiv: {
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : undefined),
  },
}));

const CPCheckbox = ({ disabled, ...props }) => {
  const classes = useStyles({ disabled });
  const checkboxComponent = (
    <Checkbox className={classes.checkbox} disabled={disabled} {...props} />
  );
  return props.message ? (
    <Tooltip
      title={props.message ? props.message : ''}
      classes={{ tooltip: classes.customWidth }}
      arrow
    >
      <div className={classes.wrapDiv}>{checkboxComponent}</div>
    </Tooltip>
  ) : (
    checkboxComponent
  );
};

export default CPCheckbox;
