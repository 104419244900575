import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import goodSplitsLogo from '../../images/goodsplits.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
  cardContent: {
    flex: 1,
  },
  media: {
    backgroundColor: '#fbfbf6',
    backgroundSize: 'contain',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundOrigin: 'content-box',
    height: 150,
  },
}));

export default function GoodSplitsCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={goodSplitsLogo}
        title="Good Splits"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="h2">
          Good Splits
        </Typography>
        <Typography color="textSecondary" component="p">
          Good Splits is a free accounting tool that allows musicians and
          managers to easily calculate royalties from any music distributor and
          pay their collaborators faster.
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          href="https://goodsplits.app/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sign in with Creative Passport
        </Button>
      </CardActions>
    </Card>
  );
}
