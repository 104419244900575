export const services = {
  PUBLIC_PAGE: 'publicPage',
  VEVA: 'veva',
  GOOD_SPLITS: 'goodSplits',
};

export function isServiceEnabled(artistProfile, service) {
  if (
    artistProfile.enabledServices &&
    artistProfile.enabledServices.some((s) => s.name === service && !s.disabled)
  ) {
    return true;
  } else return false;
}

export function isPublished(field) {
  return field?.publishers
    ? field.publishers === 'all' ||
        field.publishers.includes(services.PUBLIC_PAGE) ||
        field.publishers.includes('Public')
    : false;
}

export function toggleIsPublished(field) {
  if (!field.publishers || field.publishers.length === 0) {
    field.publishers = [services.PUBLIC_PAGE];
  } else if (field.publishers === 'all') {
    field.publishers = [];
  } else if (
    field.publishers.includes(services.PUBLIC_PAGE) ||
    field.publishers.includes('Public')
  ) {
    field.publishers = field.publishers.filter(
      (service) => ![services.PUBLIC_PAGE, 'Public'].includes(service)
    );
  } else {
    field.publishers.push(services.PUBLIC_PAGE);
  }
  return field.publishers;
}

export function enableService(artistProfile, service, options = {}) {
  if (!artistProfile.enabledServices) {
    artistProfile.enabledServices = [];
  }
  const index = artistProfile.enabledServices.findIndex(
    (s) => s.name === service
  );
  if (index >= 0) {
    artistProfile.enabledServices[index] = {
      ...artistProfile.enabledServices[index],
      disabled: false,
      ...options,
    };
  } else {
    artistProfile.enabledServices.push({
      name: service,
      disabled: false,
      ...options,
    });
  }
}

export function disableService(artistProfile, service) {
  if (!artistProfile.enabledServices) {
    artistProfile.enabledServices = [];
  } else {
    const index = artistProfile.enabledServices.findIndex(
      (s) => s.name === service
    );
    if (index >= 0) {
      artistProfile.enabledServices[index] = {
        ...artistProfile.enabledServices[index],
        disabled: true,
      };
    }
  }
}
