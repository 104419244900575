import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Tooltip, Box } from '@mui/material';
import { toast } from 'react-hot-toast';
import { BiImage, BiLoaderAlt } from 'react-icons/bi';
import styles from './styles';
import CPDeleteButton from '../CPDeleteButton';
import ModalNoForm from '../ModalNoForm';
//import CheckMark from '../../icons/checkMark';

const useStyles = makeStyles(styles);

const ProfileImage = ({
  src,
  alt,
  size,
  disableUpload,
  toolTipTitle,
  handleImageChange,
  isBusy,
  showTooltip,
  isVerified = false,
  handleImageDelete,
}) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showImageDelete, setShowImageDelete] = useState(false);

  const handleOnChange = async (e) => {
    const file = e.target.files[0];
    if (file.size > 2000000) {
      toast.error('Maximum of 2MB');
      return;
    }
    setLoading(true);
    setImageSrc(URL.createObjectURL(file));
    if (handleImageChange) {
      handleImageChange(file);
    }
    setLoading(false);
  };

  const mainImage = (
    <>
      <Box
        sx={{
          height: size ? size : '155px',
          width: size ? size : '165px',
          '.input-helper': {
            display: isBusy || loading ? 'flex' : 'none',
          },
          '&:hover': {
            '.input-helper': {
              display: 'flex',
            },
          },
        }}
        display={'flex'}
        flexDirection={'column'}
        className={classes.root}
      >
        {!disableUpload ? (
          <>
            <label htmlFor="profile-image" style={{ display: 'none' }}>
              Profile Photo
            </label>
            <input
              className={classes.inputField}
              id="profile-image"
              data-testid="picture"
              type="file"
              accept="image/*"
              onChange={handleOnChange}
              style={{
                cursor: 'pointer',
              }}
            />
          </>
        ) : null}
        {imageSrc || src ? (
          <Avatar
            src={imageSrc || src}
            alt={alt}
            className={classes.image}
            tabIndex="0"
            role="button"
          />
        ) : (
          <div className={classes.emptyImage}>
            {loading || isBusy ? (
              <BiLoaderAlt className={classes.inputLoader} />
            ) : (
              <BiImage />
            )}
          </div>
        )}
        {/*TODO only show on hover and position relative to parent (top-right?)*/}
        {handleImageDelete && (imageSrc || src) && !loading && !isBusy ? (
          <Box sx={{ display: 'flex', 'z-index': 20 }}>
            <CPDeleteButton onClick={() => setShowImageDelete(true)} />
          </Box>
        ) : null}
        {/*  {isVerified && (
          <Box className={classes.checkmark}>*/}
        {/* Change the Icon */}
        {/*<CheckMark sx={{ maxWidth: '13.66px', maxHeight: '13.33px' }} />
          </Box>
        )}*/}
        <ModalNoForm
          open={showImageDelete}
          onClose={() => setShowImageDelete(false)}
          onSave={() => {
            handleImageDelete();
            setShowImageDelete(false);
            setImageSrc(null);
          }}
          altSaveText={'Confirm Delete'}
        >
          Are you sure you want to delete your profile photo?
        </ModalNoForm>
      </Box>
    </>
  );

  if (!showTooltip) return mainImage;
  else
    return (
      <Tooltip
        title={
          toolTipTitle
            ? toolTipTitle
            : 'Profile Image must be less than 2MB (recommended dimensions: at least 420px x 420px)'
        }
        classes={{ tooltip: classes.tooltip }}
      >
        {' '}
        {mainImage}{' '}
      </Tooltip>
    );
};

export default ProfileImage;
