import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import App from './App';
import '@fontsource/heebo';

async function startApp() {
  Sentry.init({
    dsn: 'https://97cbef083dce332aa1be1192e6050370@o4505635093020672.ingest.us.sentry.io/4507537026842624',
    integrations: [
      Sentry.browserTracingIntegration(),
      // Session Replay: turn on to see where users clicked. Only 50 replays per month so use wisely
      // Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //replaysSessionSampleRate: 0.1,
    //replaysOnErrorSampleRate: 1.0,
    environment: process.env.SENTRY_ENVIRONMENT || 'UNKNOWN',
  });

  const target = document.getElementById('root');
  ReactDOM.render(<App />, target);
  serviceWorker.unregister();

  /* eslint-disable no-console */
  const log = console.error.bind(console);
  console.error = (...args) => {
    /* eslint-enable no-console */
    if (
      args.indexOf('The pseudo class') &&
      args.indexOf(
        'is potentially unsafe when doing server-side rendering. Try changing it to'
      )
    ) {
      return;
    }
    log(...args);
  };
}

startApp();
