const styles = (theme) => ({
  form: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },
  save: {
    boxShadow: '0px -15px 20px rgba(255, 255, 255, 1)',
    zIndex: '1',
    marginTop: 'auto',
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down('sm')]: {
      marginRight: '0.5rem',
    },
  },
  saveButton: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '4px',
    fontSize: 12,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.background.dark,
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
});

export default styles;
