import { Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { services } from '../../services';
import { text_fields } from '../../text_fields';
import ServicePermissionsDialog from './ServicePermissionsDialog';

function PublicPagePermissionsDialog({
  showExplanationText,
  profile,
  onEnable,
  ...rest
}) {
  const [searchEnabled, setSearchEnabled] = useState(true);
  const supportedFields = [
    ...Object.keys(text_fields.passport_info.data_fields).map((f) => ({
      key: f,
      label: text_fields.passport_info.data_fields[f].label,
    })),
    ...Object.keys(text_fields.ids.data_fields)
      .filter((f) => !text_fields.ids.data_fields[f].nested)
      .map((f) => ({
        key: f,
        label: text_fields.ids.data_fields[f].label,
      })),
  ];

  const handleEnable = (published) => {
    onEnable(published, { searchEnabled });
  };

  useEffect(() => {
    if (profile.enabledServices) {
      const service = profile.enabledServices.find(
        (s) => s.name === services.PUBLIC_PAGE
      );
      if (service) {
        setSearchEnabled(Boolean(service.searchEnabled));
      }
    }
  }, [profile.enabledServices]);

  const serviceSettings = (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={searchEnabled}
            onChange={(event) => setSearchEnabled(event.target.checked)}
            name="Search Enabled"
            color="secondary"
          />
        }
        label="Include this page in search"
      />
    </>
  );

  const explanationText = showExplanationText ? (
    <>
      Your Public Page is an open webpage publicly available where you can
      display any of the data in your passport. This page will also be linked to
      your name if you have joined the Music Makers Map. <br />
      Select below which fields you would like shared on your Public Page.
      Alternatively, you can enable/disable the fields using the sharing
      controls provided in your artist profile.
      <br />
      {serviceSettings}
    </>
  ) : (
    <>
      Please select which fields you would like shared on your public page.
      <br />
      {serviceSettings}
    </>
  );
  return (
    <ServicePermissionsDialog
      {...rest}
      onEnable={handleEnable}
      profile={profile}
      explanationText={explanationText}
      publisher={services.PUBLIC_PAGE}
      supportedFields={supportedFields}
      enableTitleText="Enable Public Page"
      enableButtonText="Enable Public Page"
      editTitleText="Edit Public Page Permissions"
    />
  );
}

export default PublicPagePermissionsDialog;
