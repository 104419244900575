import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import CPButton from '../../components/CPButton';

const styles = (theme) => ({
  errorText: {
    textAlign: 'center',
  },
});

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      oldPassword: '',
      newPassword: '',
    };
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  onSubmit = (event) => {
    event.preventDefault();

    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(
          user,
          this.state.oldPassword,
          this.state.newPassword
        )
          .then(() => {
            this.setState({ error: 'Password changed' });
            return <Redirect to="/signin" />;
          })
          .catch((error) => {
            let error_message = error.message;
            this.setState({ error: error_message });
          });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  changeOldPassword = (event) => {
    this.setState({ oldPassword: event.target.value });
  };

  changeNewPassword = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Box>
          <form>
            <TextField
              style={{ marginTop: '0px' }}
              variant="standard"
              inputProps={{ 'data-testid': 'oldPassword' }}
              id="old-password"
              type="password"
              required
              fullWidth
              value={this.state.oldPassword}
              label="Old Password"
              name="old_password"
              onChange={this.changeOldPassword}
              margin="normal"
            />
            <TextField
              variant="standard"
              inputProps={{ 'data-testid': 'newPassword' }}
              id="new-password"
              type="password"
              required
              fullWidth
              value={this.state.newPassword}
              label="New Password"
              name="new_password"
              onChange={this.changeNewPassword}
              margin="normal"
            />
            <CPButton
              type="submit"
              variant="contained"
              style={{
                boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
                width: '100%',
                verticalAlign: 'middle',
                marginTop: '1em',
              }}
              onClick={this.onSubmit}
            >
              Set new password
            </CPButton>
          </form>
          <Typography className={classes.errorText}>
            {this.state.error}
          </Typography>
        </Box>
      </div>
    );
  }
}

ChangePasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  clearCache: PropTypes.func,
  username: PropTypes.string,
  error: PropTypes.string,
  email: PropTypes.string,
  user: PropTypes.object,
  attributes: PropTypes.object,
};

ChangePasswordPage.contextTypes = {
  store: PropTypes.object,
};

export default withRouter(withStyles(styles)(ChangePasswordPage));
