import React from 'react';
import { DialogContent, Dialog, DialogActions, Slide } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Checkmark from '../../icons/tick';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import Button from '../Button';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      easing={{ enter: 'cubic-bezier(0, 1.3, .4, 1)', exit: 'linear' }}
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const ModalNoForm = ({
  children,
  open,
  onClose,
  onSave,
  isLoading,
  altSaveText,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={isSmallScreen ? true : false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogContent className={classes.withPadding}>{children}</DialogContent>
      <DialogActions className={classes.save}>
        <Button
          size="large"
          className={classes.cancelButton}
          data-testid={`cancel-button`}
          onClick={onClose}
        >
          Cancel
        </Button>
        {isLoading ? (
          <Button size="large" className={classes.saveButton}>
            Saving...
          </Button>
        ) : (
          <Button
            size="large"
            onClick={onSave}
            data-testid={`save-button`}
            className={classes.saveButton}
          >
            <Checkmark sx={{ fontSize: 12 }} />
            {altSaveText || 'Save Edits'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalNoForm;
