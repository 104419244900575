import React, { useState } from 'react';

import {
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { v4 as uuidv4 } from 'uuid';
import useServerProfile from '../../hooks/useServerProfile';
import Button from '../Button';
import { useAuthManager } from '../auth/AuthProvider';
import { postPPLNonce } from '../../scripts';
import { toast } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  paper: {
    flexGrow: 1,
    height: '100%',
    textAlign: 'center',
    width: '360px',
  },
  title: {
    fontSize: '12pt',
    fontWeight: '500',
    fontStyle: 'normal',
    marginBottom: theme.spacing(2),
  },
  helptext: {
    color: '#9e9e9e',
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  dataBox: {
    backgroundColor: theme.palette.background.dark,
    borderRadius: '0.5rem',
    padding: '1rem',
  },
}));

const VerifyIPN = () => {
  const classes = useStyles();
  const { profile } = useServerProfile();
  const authManager = useAuthManager();
  const [ipnPopupOpen, setIPNPopupOpen] = useState(false);
  const pplAuthLink = process.env.REACT_APP_PPL_UK_PORTAL_AUTH;

  const beginPPLVerification = async () => {
    let nonce = uuidv4();
    nonce = nonce.replace(/-/g, Math.floor(Math.random() * 10));
    try {
      await postPPLNonce(nonce, await authManager.getJwtToken());
      const pplLink = `${pplAuthLink}?nonce=${nonce}&auth=creativepassport`;
      window.open(pplLink, '_blank');
    } catch (e) {
      toast.error(e.message);
      return;
    }
  };

  let ipn;
  let getVerifiedIPN = false;
  let verified = profile?.VerifiedData;

  if (verified?.ppl?.ipn?.value?.[0]) {
    ipn = verified.ppl.ipn.value[0];
    getVerifiedIPN = true;
  }

  let IPNConnectionSection = (
    <Dialog
      onClose={() => setIPNPopupOpen(false)}
      aria-labelledby="ipn-dialog-title"
      open={ipnPopupOpen}
    >
      <DialogTitle id="ipn-dialog-title">
        <Typography variant="body1" align="center">
          Sign in with your PPL performer account
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.creativepassport.net/faq/"
          >
            Learn more about industry identifiers
          </a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIPNPopupOpen(false)} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={beginPPLVerification} autoFocus>
          Sign in to PPL
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <Paper className={classes.paper} elevation={0}>
      <Box p={3}>
        {IPNConnectionSection}
        {getVerifiedIPN ? (
          <Box>
            <Box className={classes.dataBox} mb={2}>
              <Typography variant="h4" align="left" gutterBottom>
                IPN Number
              </Typography>
              <Typography component="h6" align="left" gutterBottom>
                {ipn}
              </Typography>
            </Box>
            <Button
              className={classes.ipnButton}
              onClick={beginPPLVerification}
            >
              UPDATE IPN - PPL
            </Button>
          </Box>
        ) : (
          <Box>
            <Box className={classes.dataBox} mb={2}>
              <Typography variant="h4" align="left" gutterBottom>
                IPN Number
              </Typography>
              <Typography component="h6" align="left" gutterBottom>
                Not verified
              </Typography>
            </Box>
            <Button
              className={classes.ipnButton}
              onClick={beginPPLVerification}
            >
              VERIFY IPN - PPL
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default VerifyIPN;
