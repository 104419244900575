import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ToggleButton } from '@mui/material';
import AtomsEyePrivateActive from '../icons/activeEyePrivate';
import AtomsEyePrivateDefault from '../icons/defaultEyePrivate';
import AtomsEyePublicDefault from '../icons/defaultEyePublic';
import AtomsEyePublicActive from '../icons/activeEyePublic';
import { isPublished } from '../services.js';

const useStyles = makeStyles((theme) => ({
  locationEyes: {
    width: '5rem',
    height: '2.5 rem',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  eyeDefault: {
    width: '40px',
    height: '32px',
    borderRadius: '8px',
    backgroundColor: '',
    padding: '8.5px',
  },
  eyeActive: {
    width: '40px',
    height: '32px',
    borderRadius: '8px',
    backgroundColor: '#F7F8F9',
    padding: '6.5px',
  },
  eyeActiveDarker: {
    backgroundColor: '#FFFFFF',
  },
  eyeButton: {
    border: 'none',
    backgroundColor: '',
    borderRadius: '8px',
    width: '40px',
    height: '32px',
    hover: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const EyeIcon = ({ isPublic, isActive, darker, classes }) => {
  const IconComponent = isPublic
    ? isActive
      ? AtomsEyePublicActive
      : AtomsEyePublicDefault
    : isActive
    ? AtomsEyePrivateActive
    : AtomsEyePrivateDefault;

  const styleClass = isActive ? classes.eyeActive : classes.eyeDefault;
  const darkerClass = isActive
    ? classes.eyeActiveDarker
    : classes.eyeDefaultDarker;

  return (
    <IconComponent className={`${styleClass} ${darker ? darkerClass : ''}`} />
  );
};

const CPVisibleToggle = ({ onClick, dataField, darker }) => {
  const classes = useStyles();
  const isPub = isPublished(dataField);

  return (
    <div className={classes.locationEyes}>
      <ToggleButton
        className={classes.eyeButton}
        value="check"
        onChange={onClick}
      >
        <EyeIcon
          isPublic={false}
          isActive={!isPub}
          darker={darker}
          classes={classes}
        />
      </ToggleButton>
      <ToggleButton
        className={classes.eyeButton}
        value="check"
        onChange={onClick}
      >
        <EyeIcon
          isPublic={true}
          isActive={isPub}
          darker={darker}
          classes={classes}
        />
      </ToggleButton>
    </div>
  );
};

export default CPVisibleToggle;
