import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { QueryClient } from 'react-query';
import { AuthManager } from './AuthManager';

export const AuthManagerContext = React.createContext();
export const AuthDispatchContext = React.createContext();
export const AuthValueContext = React.createContext();

export const useAuthManager = () => React.useContext(AuthManagerContext);

function authReducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, authState: 'LOGGED_IN', user: action.payload };
    case 'LOGOUT':
      return { ...state, authState: 'LOGGED_OUT', user: undefined };
    case 'SET_UNCONFIRMED_USER':
      return { authState: 'UNCONFIRMED', unconfirmedUsername: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function useAuth() {
  const state = React.useContext(AuthValueContext);
  if (state === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return state;
}

export function useSetAuthUser() {
  const dispatch = React.useContext(AuthDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useSetAuthUser must be used within a AuthProvider');
  }
  return useCallback(
    (user) => dispatch({ type: 'SET_USER', payload: user }),
    [dispatch]
  );
}

export function useSetUnconfirmedUser() {
  const dispatch = React.useContext(AuthDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useSetUnconfirmedUser must be used within a AuthProvider');
  }
  return useCallback(
    (username) => dispatch({ type: 'SET_UNCONFIRMED_USER', payload: username }),
    [dispatch]
  );
}

export function AuthProvider({
  children,
  authManager: authManagerProp,
  initialState = {
    authState: 'INIT',
    user: undefined,
  },
}) {
  const [auth, dispatch] = useReducer(authReducer, initialState);
  const authManager = useMemo(
    () => authManagerProp || new AuthManager(new QueryClient()),
    [authManagerProp]
  );
  const isInit = auth.authState === 'INIT';

  useEffect(() => {
    if (isInit) {
      let finished = false;
      (async function () {
        try {
          const user = await Auth.currentAuthenticatedUser();
          if (!finished) {
            dispatch({
              type: 'SET_USER',
              payload: {
                username: user.username,
                passportId: user.attributes.sub,
                email: user.attributes.email,
              },
            });
          }
        } catch (error) {
          if (!finished) {
            dispatch({ type: 'LOGOUT' });
          }
        }
      })();
      return function cleanup() {
        finished = true;
      };
    }
  }, [dispatch, isInit]);

  useEffect(() => {
    const unsubscribe = authManager.subscribe((type, user) => {
      switch (type) {
        case 'LOGGED_OUT':
          dispatch({ type: 'LOGOUT' });
          break;
        case 'LOGGED_IN':
          dispatch({
            type: 'SET_USER',
            payload: {
              username: user.username,
              passportId: user.attributes.sub,
              email: user.attributes.email,
            },
          });
          break;
        default:
      }
    });
    return () => {
      unsubscribe();
    };
  }, [authManager, dispatch]);

  return (
    <AuthManagerContext.Provider value={authManager}>
      <AuthDispatchContext.Provider value={dispatch}>
        <AuthValueContext.Provider value={auth}>
          {children}
        </AuthValueContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthManagerContext.Provider>
  );
}
