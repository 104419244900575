import { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import TitleInfo from '../../../../components/Title';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import MainForm from './MainForm';
import SubForm from './SubForm';
import Button from '../../button';
import { useProfileContext } from '../../../passportData/context';
import IconButton from '@mui/material/IconButton';

import BandIcon from '../../../../icons/atomDrum';
import SoloIcon from '../../../../icons/atomSoloProject';
import OrchestraIcon from '../../../../icons/atomOrchestra';
import OtherIcon from '../../../../icons/atomPuzzle';
import CollectiveIcon from '../../../../icons/atomGroup';
import ProfileIcon from '../../../../icons/atomProfile';

const useStyles = makeStyles(styles);

const BasicInfo = ({ back, next, artistProfiles }) => {
  const classes = useStyles();
  const { dispatch, state: passportState } = useProfileContext();
  const [activeTab, setActiveTab] = useState(
    artistProfiles.findIndex((ap) => ap.isProfessionalProfile)
  );
  const [tempProfiles, setTempProfiles] = useState(artistProfiles);

  const changePage = (options) => {
    if (options.save ?? true) {
      dispatch({
        type: 'POPULATE',
        payload: {
          ...passportState,
          artistProfiles: tempProfiles,
        },
      });
    }
    options.reverse ? back() : next();
  };

  const patchTempProfiles = (patch) => {
    setTempProfiles((prevProfiles) =>
      prevProfiles.map((ap) => (ap.id === patch.id ? { ...ap, ...patch } : ap))
    );
  };

  const getAtomIcon = (artisticType, isActive) => {
    switch (artisticType) {
      case 'professional':
        return (
          <ProfileIcon className={classes.typeIcon} hasGradient={isActive} />
        );
      case 'solo':
        return <SoloIcon hasGradient={isActive} className={classes.typeIcon} />;
      case 'band':
        return <BandIcon hasGradient={isActive} className={classes.typeIcon} />;
      case 'orchestra':
        return (
          <OrchestraIcon hasGradient={isActive} className={classes.typeIcon} />
        );
      case 'collective':
        return (
          <CollectiveIcon hasGradient={isActive} className={classes.typeIcon} />
        );
      default:
        return (
          <OtherIcon hasGradient={isActive} className={classes.typeIcon} />
        );
    }
  };

  const handleActiveTab = (e, newTabId) => {
    setActiveTab(newTabId);
  };

  const activeArtistProfile = tempProfiles[activeTab];

  return (
    <>
      <TitleInfo title="Basic Information" />
      <Box marginBottom={4}>
        {tempProfiles.length === 1 ? (
          <MainForm
            artistProfile={activeArtistProfile}
            useStyles={useStyles}
            patchTempProfiles={patchTempProfiles}
          />
        ) : (
          <Box w="100%" maxWidth="100%">
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
              value={activeTab}
              onChange={handleActiveTab}
              className={classes.tabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="artist profile tabs"
            >
              {tempProfiles.map((artistProfile, index) => (
                <Tab
                  icon={
                    <IconButton disableRipple>
                      {getAtomIcon(
                        artistProfile.isProfessionalProfile
                          ? 'professional'
                          : artistProfile.type,
                        activeTab === index
                      )}
                    </IconButton>
                  }
                  className={classes.tab}
                  aria-label={artistProfile.artistName}
                  label={artistProfile.artistName}
                />
              ))}
            </Tabs>

            <Box>
              <div
                style={{
                  display: activeArtistProfile?.isProfessionalProfile
                    ? 'block'
                    : 'none',
                }}
              >
                <MainForm
                  artistProfile={activeArtistProfile}
                  useStyles={useStyles}
                  patchTempProfiles={patchTempProfiles}
                />
              </div>
              <div
                style={{
                  display: activeArtistProfile?.isProfessionalProfile
                    ? 'none'
                    : 'block',
                }}
              >
                <SubForm
                  artistProfile={activeArtistProfile}
                  useStyles={useStyles}
                  patchTempProfiles={patchTempProfiles}
                />
              </div>
            </Box>
          </Box>
        )}
      </Box>
      <div className={classes.buttons} style={{ marginBottom: '60px' }}>
        <Button
          isBack
          width={{ xs: '45px', lg: '200px' }}
          type="button"
          onClick={() => changePage({ reverse: true })}
        >
          previous
        </Button>
        <div></div>
        <div className={classes.buttonsNext}>
          <p
            onClick={() => changePage({ save: false })}
            className={classes.buttonsNextP}
          >
            Skip this step
          </p>
          <Button
            onClick={() => changePage({})}
            width={{ xs: '120px', lg: '200px' }}
          >
            next
          </Button>
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
