import React from 'react';
import { Grid, Dialog, DialogContent, Button } from '@mui/material';
import CPLogo from '../../icons/cpLogoWithTextWhite.js';
import IconButton from '@mui/material/IconButton';
import { AiOutlineArrowRight, AiOutlineIdcard } from 'react-icons/ai';

const AnnouncementDialog = ({ onAgree, onDisagree }) => {
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogContent className="announcement-modal">
        <div className="head-content" style={{ width: '100%' }}>
          <CPLogo
            style={{
              width: '20%',
              height: '40%',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          />
        </div>
        <div className="body-content">
          <h1>
            Welcome back to The Creative Passport, <br></br>in our brand new
            redesign!
          </h1>
          <br></br>
          <p>
            We are trying to make the transition to our new design as smooth as
            possible, but if any issues occur during the migration please notify
            our <a href="mailto:tech@creativepassport.net">tech team</a>.
          </p>
          <br></br>
          <p>
            So what’s new? We have identified that it would be better to have
            one
            <b> professional profile</b>, and separate any additional projects,
            like bands, collectives, and orchestras, into different{' '}
            <b>artistic profiles</b>.
          </p>
          <br></br>
          <p>
            You can easily import your existing data, and assign your profile
            type(s), or start fresh and create new profiles in the redesign.
          </p>
          <br></br>

          <Grid container className="welcome-buttons" spacing={2}>
            <Grid item xs={12} sm={4.5}>
              <Button
                className="icon-button-padded"
                onClick={onDisagree}
                style={{ width: '100%' }}
              >
                Start fresh
                <IconButton disableRipple size="small">
                  <AiOutlineIdcard />
                </IconButton>
              </Button>
            </Grid>
            <Grid item xs={12} sm={7.5}>
              <Button
                className="icon-button-padded"
                onClick={onAgree}
                style={{ width: '100%' }}
              >
                Assign my existing profiles
                <IconButton disableRipple size="small">
                  <AiOutlineArrowRight />
                </IconButton>
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AnnouncementDialog;
