import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon({ hasGradient, sx, ...rest }) {
  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? 'url(#MyGradient)' : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient id="MyGradient" gradientTransform="rotate(10)">
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={hasGradient ? null : '#626466'}>
          <path
            d="M8.982,0 C9.944,0 10.906,0.249 11.767,0.746 C13.49,1.741 14.551,3.58 14.551,5.569 L14.551,9.98 L10.092,14 L14,14 L14,16 L3.732,16 L2.982,18.142 L6.816,22 L13.334,22 L14,21.331 L14,24 L3.833,24 L0,20.142 L2.149,14 L7.873,14 L3.414,9.98 L3.414,5.569 C3.414,5.072 3.48,4.584 3.606,4.116 C3.669,3.882 3.748,3.653 3.84,3.431 C4.302,2.319 5.121,1.368 6.198,0.746 C6.411,0.623 6.63,0.515 6.854,0.423 C7.536,0.141 8.258,0 8.982,0 Z M16.9995,13.9995 L16.9995,17.9995 L20.9995,17.9995 L20.9995,19.9995 L16.9995,19.9995 L16.9995,23.9995 L14.9995,23.9995 L14.9995,19.9995 L10.9995,19.9995 L10.9995,17.9995 L14.9995,17.9995 L14.9995,13.9995 L16.9995,13.9995 Z M8.982,2 C8.357,2 7.74,2.166 7.198,2.478 C6.915,2.641 6.661,2.842 6.437,3.071 C6.422,3.237 6.396,3.4 6.396,3.569 L6.396,7.98 L10.089,11.309 L12.551,9.09 L12.551,5.569 C12.551,4.297 11.868,3.114 10.767,2.478 C10.225,2.166 9.608,2 8.982,2 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
