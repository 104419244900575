import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Radio from '@mui/material/Radio';
import Avatar from '@mui/material/Avatar';
import CPDialog from '../../components/CPDialog';
import useUniqueId from '../../hooks/useUniqueId';
import default_theme from '../../images/default_theme.png';
import ice_theme from '../../images/ice_theme.png';
import b_w_theme from '../../images/b_w_theme.png';
import dark_theme from '../../images/dark_theme.png';
import { services } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: '400px', margin: 'auto auto' },
  title: {
    margin: theme.spacing(0.4),
    textAlign: 'center',
  },
  textArea: {
    marginLeft: theme.spacing(0.4),
    textAlign: 'center',
  },
}));

const BlueRadio = withStyles({
  root: {
    '&$checked': {
      color: '#188a96',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const defaultTheme = { name: 'default' };

function CustomThemeDialog({ open, onClose, onConfirm, profile }) {
  // FIXME: this needs updating - but is the /services page still used?
  const [theme, setTheme] = React.useState(defaultTheme);
  const id = useUniqueId('service-theme-dialog-');
  const classes = useStyles();

  useEffect(() => {
    if (open && profile.enabledServices) {
      const publicPageService = profile.enabledServices.find(
        (s) => s.name === services.PUBLIC_PAGE
      );

      if (publicPageService) {
        setTheme({ ...defaultTheme, ...publicPageService.theme });
      }
    }
  }, [open, profile]);

  const handleClose = () => {
    onClose();
  };

  const handleThemes = (event) => {
    setTheme((theme) => ({ ...theme, name: event.target.value }));
  };

  const handleSetDefault = () => {
    setTheme({ name: 'default' });
    onConfirm({ name: 'default' });
  };

  const handleConfirm = () => {
    onConfirm(theme);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      className={classes.root}
      fullWidth
    >
      <CPDialog.Title className={classes.title} id={id}>
        Customize the look of your public page
      </CPDialog.Title>
      <DialogContent className={classes.textArea}>
        <DialogContentText>
          Your Public Page is an open webpage where you can display any data
          from your passport. It is also linked to your name if you have joined
          the Music Makers Map.
        </DialogContentText>
        <List dense className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt={`Default Theme`}
                src={default_theme}
                className={classes.small}
              />
            </ListItemAvatar>
            <ListItemText id="default" primary="Default" />
            <ListItemSecondaryAction>
              <BlueRadio
                checked={theme.name === 'default'}
                onChange={handleThemes}
                value="default"
                name="default"
                inputProps={{ 'aria-label': 'Default Theme' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Black and White Theme"
                src={b_w_theme}
                className={classes.small}
              />
            </ListItemAvatar>
            <ListItemText id="blackAndWhite" primary="Monochrome" />
            <ListItemSecondaryAction>
              <BlueRadio
                checked={theme.name === 'blackAndWhite'}
                onChange={handleThemes}
                value="blackAndWhite"
                name="blackAndWhite"
                inputProps={{ 'aria-label': 'Black and White Theme' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Dark Theme"
                src={dark_theme}
                className={classes.small}
              />
            </ListItemAvatar>
            <ListItemText id="dark" primary="dark" />
            <ListItemSecondaryAction>
              <BlueRadio
                checked={theme.name === 'dark'}
                onChange={handleThemes}
                value="dark"
                name="dark"
                inputProps={{ 'aria-label': 'Dark Theme' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Ice Theme"
                src={ice_theme}
                className={classes.small}
              />
            </ListItemAvatar>
            <ListItemText id="ice" primary="Ice" />
            <ListItemSecondaryAction>
              <BlueRadio
                checked={theme.name === 'ice'}
                onChange={handleThemes}
                value="ice"
                name="ice"
                inputProps={{ 'aria-label': 'Ice Theme' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSetDefault} color="primary">
          Reset
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomThemeDialog;
