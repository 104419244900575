import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const fieldNames = [
  ['artistName', 'Artist Name'],
  ['aliases', 'Aliases'],
  ['biography', 'Biography'],
  ['country', 'Country'],
  ['city', 'City'],
  ['genres', 'Genres'],
  ['musicRoles', 'Music Roles'],
  ['nonMusicRoles', 'Non Music Roles'],
  ['otherSkills', 'Other Skills'],
  ['instrumentsPlayed', 'Instruments Played'],
  ['hardwareUsed', 'Hardware Used'],
  ['softwareUsed', 'Software Used'],
  ['endorsement', 'Endorsement'],
  ['interestsInspiration', 'Interests Inspiration'],
  ['currentProjects', 'Current Projects'],
  ['favouriteMusicMakers', 'Favourite Music Makers'],
  ['charitiesSupported', 'Charities Supported'],
  ['favouriteQuote', 'Favourite Quote'],
];

export const useStyles = makeStyles((theme) => ({
  highlight: {
    '& em': {
      backgroundColor: theme.palette.green.light,
      borderRadius: '0.25rem',
      outline: '2px solid',
      outlineColor: theme.palette.green.light,
      fontStyle: 'normal',
    },
    backgroundColor: theme.palette.background.dark,
    padding: '0.75rem',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
  },
  category: {},
}));

export function Highlight({ highlights }) {
  const classes = useStyles();
  let r = [];
  for (const [key, title] of fieldNames) {
    const v = highlights[key];
    if (v) {
      r.push(
        <div key={key} className={classes.highlight}>
          <Typography variant="h4" className={classes.category}>
            {title}
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: v.join(', ') }} />
        </div>
      );
    }

    // Limit the number of highlighted fields to 3
    if (r.length === 3) {
      return r;
    }
  }
  return r;
}
