import React from 'react';
import { deepmerge } from '@mui/utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { usePublicProfileContext } from '../containers/public-profile';
import { GlobalStyles } from '@mui/material';
import { lighten, darken, alpha } from '@mui/system';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1280,
    xl: 1536,
  },
};

export default function PublicPageStylingOverride(props) {
  const publicProfileData = usePublicProfileContext();
  const publicPageStyling = publicProfileData?.publicPageStyling;

  // If customBgColor is true, set bgColor
  const bgColor = publicPageStyling?.customBgColor
    ? publicPageStyling.bgColor
    : undefined;

  // Set bgColor for sections
  const paperColorLight = publicPageStyling?.translucentSections // If Light Theme selected
    ? bgColor // If Translucent selected
      ? alpha(lighten(bgColor, 0.75), 0.7) // Lighten then make translucent if bgColor is true, else use default
      : '#FFFFFFAF' // Default translucent
    : bgColor // If Fill selected
    ? lighten(bgColor, 0.5) // Lighten if bgColor is true, else use default
    : '#FFF'; // Default Fill
  const paperColorDark = publicPageStyling?.translucentSections // If Dark Theme selected
    ? bgColor // If Translucent selected
      ? alpha(darken(bgColor, 0.75), 0.7) // Darken then make translucent if bgColor is true, else use default
      : '#000000B0' // Default translucent
    : bgColor // If Fill selected
    ? darken(bgColor, 0.5) // Darken if bgColor is true, else use default
    : '#181919'; // Default Fill

  const lightOverlay = {
    breakpoints: {
      ...customBreakpointValues,
    },
    palette: {
      //FIXME, ideally this just pulls fallback colours from main.js
      primary: {
        //Grey
        hint: bgColor ? darken(bgColor, 0.75) : `hsl(210, 2%, 40%)`,
        main: bgColor ? darken(bgColor, 0.8) : `hsl(210, 2%, 20%)`,
        dark: bgColor ? darken(bgColor, 0.95) : `hsl(210, 2%, 10%)`,
      },
      green: {
        //Green
        lighter: bgColor ? lighten(bgColor, 0.65) : 'hsl(166, 100%, 95%)', //Chip background
        main: bgColor ? lighten(bgColor, 0.25) : 'hsl(166, 100%, 75%)', //Chip border
        contrastText: bgColor ? darken(bgColor, 0.9) : 'hsl(166, 100%, 10%)',
      },
      blue: {
        dark: bgColor ? darken(bgColor, 0.47) : 'hsl(210, 34%, 47%)', //Label background
        lighter: bgColor ? lighten(bgColor, 0.94) : `hsl(210, 70%, 94%)`, //Label text
      },
      background: {
        paper: paperColorLight,
        dark: bgColor ? darken(bgColor, 0.75) : `hsl(210, 14%, 94%)`,
      },
    },
    typography: {
      h3: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        textTransform: 'Capitalize',
        fontWeight: '500',
        fontSize: 20,
        color: bgColor ? darken(bgColor, 0.8) : `hsl(210, 2%, 20%)`, //palette.primary.main
      },
      h5: {},
      h6: {},
      body1: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontSize: 14,
        color: bgColor ? darken(bgColor, 0.8) : `hsl(210, 2%, 20%)`, //palette.primary.main
      },
      subtitle1: {
        fontSize: 12,
        lineHeight: 1.5,
        color: bgColor ? darken(bgColor, 0.8) : `hsl(210, 2%, 20%)`, //palette.primary.main
      },
    },

    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            '&:hover': {
              backgroundColor: bgColor
                ? lighten(bgColor, 0.4)
                : paperColorLight,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            maxWidth: '50%',
            fontWeight: '400',
            display: 'inline',
            margin: 'auto',
            verticalAlign: 'middle',
            minHeight: 0,
            marginRight: '0',
            marginLeft: '0',
            '&.Mui-selected': {
              backgroundColor: paperColorLight,
              borderRadius: '0.75rem',
              fontFamily: 'Roboto, Sans-serif',
              color: bgColor ? darken(bgColor, 0.8) : `hsl(210, 2%, 20%)`,
              textStroke: '0.3px',
              WebkitTextStroke: '0.3px',
              strokeLinejoin: 'round',
              '&:hover': {
                backgroundColor: paperColorLight,
              },
            },
            '&:hover': {
              borderRadius: '0.75rem',
              backgroundColor: paperColorLight,
            },
          },
        },
      },
    },
  };
  const darkOverlay = {
    breakpoints: {
      ...customBreakpointValues,
    },
    palette: {
      primary: {
        hint: bgColor ? lighten(bgColor, 0.75) : 'hsl(210, 2%, 60%)',
        main: bgColor ? lighten(bgColor, 0.8) : 'hsl(210, 2%, 80%)',
        dark: bgColor ? lighten(bgColor, 0.95) : 'hsl(210, 2%, 90%)',
      },
      green: {
        //Green
        lighter: bgColor ? darken(bgColor, 0.65) : 'hsl(166, 100%, 95%)', //Chip background
        main: bgColor ? darken(bgColor, 0.25) : 'hsl(166, 100%, 75%)', //Chip border
        contrastText: bgColor ? lighten(bgColor, 0.9) : 'hsl(166, 100%, 10%)',
      },
      blue: {
        lighter: bgColor ? darken(bgColor, 0.94) : `hsl(210, 70%, 94%)`, //Label text
        dark: bgColor ? lighten(bgColor, 0.47) : 'hsl(210, 34%, 47%)', //Label background
      },
      background: {
        default: '#313233',
        paper: paperColorDark,
        dark: bgColor ? lighten(bgColor, 0.75) : 'hsl(210, 14%, 5%)',
      },
    },
    typography: {
      h3: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        textTransform: 'Capitalize',
        fontWeight: '500',
        fontSize: 20,
        color: bgColor ? lighten(bgColor, 0.8) : 'hsl(210, 2%, 80%)', //palette.primary.main
      },
      h5: {
        color: '#fff',
      },
      h6: {
        color: '#fff',
      },
      body1: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontSize: 14,
        color: bgColor ? lighten(bgColor, 0.8) : 'hsl(210, 2%, 80%)', //palette.primary.main
      },
      subtitle1: {
        fontSize: 12,
        lineHeight: 1.5,
        color: bgColor ? lighten(bgColor, 0.8) : 'hsl(210, 2%, 80%)', //palette.primary.main
      },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            '&:hover': {
              backgroundColor: bgColor ? darken(bgColor, 0.6) : paperColorDark,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            maxWidth: '50%',
            fontWeight: '400',
            display: 'inline',
            margin: 'auto',
            verticalAlign: 'middle',
            minHeight: 0,
            marginRight: '0',
            marginLeft: '0',
            '&.Mui-selected': {
              backgroundColor: paperColorDark,
              borderRadius: '0.75rem',
              fontFamily: 'Roboto, Sans-serif',
              color: bgColor ? lighten(bgColor, 0.8) : 'hsl(210, 2%, 80%)',
              textStroke: '0.3px',
              WebkitTextStroke: '0.3px',
              strokeLinejoin: 'round',
              '&:hover': {
                backgroundColor: paperColorDark,
              },
            },
            '&:hover': {
              borderRadius: '0.75rem',
              backgroundColor: paperColorDark,
            },
          },
        },
      },
    },
  };

  const bgColorOverlay = {
    palette: {
      background: {
        default: bgColor,
      },
    },
  };

  let overlay =
    publicPageStyling?.theme === 'dark' ? darkOverlay : lightOverlay;
  if (bgColor) {
    overlay = deepmerge(overlay, bgColorOverlay);
  }
  const innerTheme = createTheme(overlay);

  let globalStyles = undefined;
  if (overlay?.palette?.background?.default) {
    globalStyles = {
      ...globalStyles,
      backgroundColor: overlay.palette.background.default,
    };
  }
  if (
    publicPageStyling?.backgroundType === 'image' &&
    publicProfileData.backgroundImage?.url
  ) {
    globalStyles = {
      ...globalStyles,
      backgroundImage: `url(${publicProfileData.backgroundImage?.url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundAttachment: 'fixed',
    };
  }

  return (
    <ThemeProvider theme={(outerTheme) => deepmerge(outerTheme, innerTheme)}>
      {globalStyles && (
        <GlobalStyles
          styles={{
            body: globalStyles,
          }}
        />
      )}
      {props.children}
    </ThemeProvider>
  );
}
