import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Stack, Box } from '@mui/material';
import styles from './styles';
import { useProfileContext } from '../../containers/passportData/context';
import { useMutatePatchProfile } from '../../hooks/useMutateServerProfile';
import shortid from 'shortid';
import toast from 'react-hot-toast';
import AtomsIconsRepresentative from '../../icons/atomsIconsRepresentative';
import Representative from './Representative';
import Modal from '../ModalNoForm';
import CPAddButton from '../CPAddButton';
import IconTextButton from '../IconTextButton';
import Plus from '../../icons/atomsIconsAdd';
import TitleInfo from '../Title';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles(styles);

const Representatives = ({ artistProfile }) => {
  const classes = useStyles();
  const { dispatch } = useProfileContext();
  const [editMode, setIsEditMode] = useState(false);
  const [representatives, setRepresentatives] = useState(
    artistProfile.representatives?.value || []
  );
  const { mutateAsync } = useMutatePatchProfile();

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    const newRepresentatives = Array.from(representatives);
    const [removed] = newRepresentatives.splice(source.index, 1);
    newRepresentatives.splice(destination.index, 0, removed);

    setRepresentatives(newRepresentatives);
  };

  const handleAdd = () => {
    setRepresentatives((prevState) => [
      ...prevState,
      {
        uniqueKey: shortid.generate(),
        fieldName: '',
        value: {
          repName: '',
          contactPhone: '',
          contactNote: '',
          contactEmail: '',
        },
        publishers: [],
      },
    ]);
  };

  const handleDataChange = (item) => {
    setRepresentatives((prevState) =>
      prevState.map((rep) => (rep.uniqueKey === item.uniqueKey ? item : rep))
    );
  };

  const handleDelete = (item) => {
    setRepresentatives((prevState) =>
      prevState.filter((rep) => rep.uniqueKey !== item.uniqueKey)
    );
  };

  const handleSave = async () => {
    const patch = {
      id: artistProfile.id,
      representatives: {
        value: representatives,
      },
    };
    mutateAsync(patch)
      .then(() => dispatch({ type: 'PATCH_PROFILE', payload: patch }))
      .then(() => handleClose())
      .catch((err) => toast.error('Error saving profile'));
  };

  const handleClose = () => {
    setIsEditMode(false);
  };

  const editDialog = (
    <Modal open={editMode} onClose={handleClose} onSave={() => handleSave()}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="representatives">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {representatives.map((rep, index) => (
                <Draggable
                  key={rep.uniqueKey}
                  draggableId={rep.uniqueKey}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Representative
                        editMode={editMode}
                        representative={rep}
                        onDelete={handleDelete}
                        onDataChange={handleDataChange}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <CPAddButton onClick={() => handleAdd()}>Add a new contact</CPAddButton>
    </Modal>
  );

  return (
    <div className={classes.root}>
      {editDialog}
      <TitleInfo
        title="Contacts/Representatives"
        isNotEmpty={
          artistProfile.representatives?.value &&
          artistProfile.representatives.value.length > 0
        }
        message="Edit Contacts/Representatives"
        onClick={() => setIsEditMode(true)}
      />

      {!artistProfile.representatives?.value?.length ? (
        <>
          <Box className={classes.empty}>
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <AtomsIconsRepresentative sx={{ width: '32px' }} />
              <Typography variant="body1">
                Specify who people should contact to reach out to you
              </Typography>
              <IconTextButton
                onClick={() => setIsEditMode(true)}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add Contacts/Representatives
              </IconTextButton>
            </Stack>
          </Box>
        </>
      ) : (
        <>
          <Stack className={classes.reps}>
            {artistProfile.representatives?.value?.map((rep) => (
              <div key={rep.uniqueKey}>
                <Representative
                  editMode={false}
                  representative={rep}
                  id={artistProfile.id}
                />
              </div>
            ))}
          </Stack>
        </>
      )}
    </div>
  );
};

export default Representatives;
