import React from 'react';
import { SvgIcon } from '@mui/material';
import shortid from 'shortid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = shortid.generate();
  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? `url(#gradient-${gradientId})` : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={hasGradient ? null : '#626466'}>
          <path d="M22.346 1L24 6.768l-5.083 1.458L21 10.309v7.455l-1.929 1.93 3.343 3.342h-2.828l-1.929-1.928-.93.929-1 .999H8.272l-1.929-1.928-1.929 1.928H1.586l3.343-3.342L3 17.764v-7.455l2.083-2.083L0 6.768 1.654 1l9.612 2.758-.366 1.278h2.2l-.367-1.278L22.346 1zm-8.672 6.036h-3.348l-.713 2.489-2.332-.669L6 10.137v6.627l4.272 4.273h4.627L19 16.936v-5.799l-2.281-2.281-2.332.669-.713-2.489zM12 10l1.237 2.505 2.764.401L14 14.855l.472 2.754-2.472-1.3-2.471 1.3.471-2.754-2-1.949 2.764-.401L12 10zm8.974-6.526l-5.767 1.654.551 1.922 5.768-1.654-.552-1.922zm-17.949 0l-.551 1.922L8.241 7.05l.552-1.922-5.768-1.654z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
