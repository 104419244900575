import { useMutation, useQueryClient } from 'react-query';
import { useAuthManager } from '../components/auth/AuthProvider';
import {
  patchPersonalData,
  patchProfileData,
  postProfileData,
  updateProfileData,
} from '../scripts';

function useMutateServerProfile() {
  const queryClient = useQueryClient();
  const authManager = useAuthManager();

  return useMutation(
    (data) => {
      return authManager
        .getJwtToken()
        .then((jwtToken) => updateProfileData(data, jwtToken));
    },
    {
      onMutate: async (newServerProfile) => {
        const previousData = queryClient.getQueryData('profileData');

        // Don't optimistically update new passports
        if (previousData.PassportId) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries('profileData');

          const newData = {
            ...previousData,
            PassportData: newServerProfile,
          };
          queryClient.setQueryData('profileData', newData);
          return { previousData, newData };
        }
      },
      onError: (err, newData, context) => {
        queryClient.setQueryData('profileData', context.previousData);
      },
      onSettled: () => {
        queryClient.invalidateQueries('profileData');
      },
    }
  );
}

export const useMutatePatchProfile = () => {
  const authManager = useAuthManager();

  return useMutation(async (data) => {
    // FIXME: this should be passed in along side the diff
    const profileId = data.id;
    const jwtToken = await authManager.getJwtToken().catch(() => null);
    return patchProfileData(data, profileId, jwtToken);
  });
};

export const useMutatePostProfile = () => {
  const authManager = useAuthManager();

  const { mutateAsync: postProfileAsync, isLoading: isPostingProfile } =
    useMutation(async (data) => {
      // FIXME: this should be passed in along side the new value
      const profileId = data.id;
      const jwtToken = await authManager.getJwtToken().catch(() => null);
      return postProfileData(data, profileId, jwtToken);
    });
  return { postProfileAsync, isPostingProfile };
};

export const useMutatePatchPersonal = () => {
  const authManager = useAuthManager();

  const { mutateAsync: patchPersonalAsync, isLoading: isPatchingPersonal } =
    useMutation(async (data) => {
      const jwtToken = await authManager.getJwtToken().catch(() => null);
      return patchPersonalData(data, jwtToken);
    });
  return { patchPersonalAsync, isPatchingPersonal };
};

export default useMutateServerProfile;
