import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Stack } from '@mui/material';
import SkillsIcon from '../../icons/atomsSkills';
import LanguagesIcon from '../../icons/languages';
import RolesIcon from '../../icons/atomsRoles';
import FeaturesIcon from '../../icons/atomsFeatures';
import InstrumentsIcon from '../../icons/instruments';
import ToolsAndGearIcon from '../../icons/toolsAndGear';
import InspirationsIcon from '../../icons/inspirations';
import FavoriteArtistsIcon from '../../icons/favoriteArtist';
import EndorsementIcon from '../../icons/endorsement';
import GenresIcon from '../../icons/genres';
import SupportedCauses from '../../icons/supportedCauses';
import QuotesIcon from '../../icons/quotes';
import Plus from '../../icons/atomsIconsAdd';
import Info from './Info';
import InfoEdit from './InfoEdit';
import styles from './styles';
import Modal from '../ModalNoForm';
import { avs } from '../../text_fields';
import { toggleIsPublished, isPublished } from '../../services.js';
import { useProfileContext } from '../../containers/passportData/context';
import IconTextButton from '../../components/IconTextButton';
import { useMutatePatchProfile } from '../../hooks/useMutateServerProfile';
import toast from 'react-hot-toast';
import TitleInfo from '../Title';
import Toolclick from '../CPHelpMessage/index';

const useStyles = makeStyles(styles);

const PersonalInfo = ({ artistProfile }) => {
  const { dispatch } = useProfileContext();
  const [editMode, setEditMode] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [state, setState] = useState(artistProfile);
  const { mutateAsync, isLoading } = useMutatePatchProfile();
  const classes = useStyles();

  useEffect(
    () =>
      setIsEmpty(
        !state?.toolsUsed?.value?.length &&
          !state?.toolsOwned?.value?.length &&
          !state?.instrumentsPlayed?.value?.length &&
          !state?.instrumentsOwned?.value?.length &&
          !state?.musicRoles?.value?.length &&
          !state?.otherSkills?.value?.length &&
          !state?.genres?.value?.length &&
          !state?.favouriteMusicMakers?.value?.length &&
          !state?.endorsement?.value?.length &&
          !state?.interestsInspiration?.value?.length &&
          !state?.charitiesSupported?.value?.length &&
          !state?.favouriteQuote?.value?.length &&
          !state?.spokenLanguages?.value?.length
      ),
    [state]
  );

  const handleClose = () => {
    setEditMode(false);
  };

  const handleSave = async () => {
    try {
      const patch = state;
      await mutateAsync(patch);
      dispatch({
        type: 'PATCH_PROFILE',
        payload: patch,
      });
      setEditMode(false);
      toast.success('Profile info updated successfully');
    } catch (err) {
      const message = err.response?.data?.Error || err.message;
      toast.error(message);
    }
  };

  const handlePrivacy = (key) => {
    setState((prevState) => {
      if (!prevState[key]) {
        return prevState;
      }

      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          publishers: toggleIsPublished(prevState[key]),
        },
      };
    });
  };

  return (
    <>
      <Modal
        onSave={() => handleSave()}
        onClose={handleClose}
        open={editMode}
        isLoading={isLoading}
      >
        {/* Profile Info Editor */}

        <div className={classes.modal}>
          <Toolclick
            className={classes.toolclick}
            message="To add items press enter after typing each item or select out of the dropdown lists."
            title="How do I fill out the fields?"
          />
          {artistProfile.isProfessionalProfile ? (
            <InfoEdit
              Icon={RolesIcon}
              title="roles"
              defaultTitles={state.musicRoles?.value}
              defaultUsedTitles={[]}
              defaultOwnedTitles={[]}
              description="Specify the role/s in the industry that best represent you both music and non-music related.
              The first three roles will be displayed in your profile summary. Drag roles to rearrange them."
              setIsShow={() => handlePrivacy('musicRoles')}
              dataField={state.musicRoles}
              defaultSuggestedTitles={avs.musicRoles.values.slice(4, 8)}
              id={artistProfile.id}
              setState={setState}
              state={state}
            />
          ) : (
            <InfoEdit
              Icon={FeaturesIcon}
              title="keywords"
              defaultTitles={state.features?.value}
              defaultUsedTitles={[]}
              defaultOwnedTitles={[]}
              description="Specify any top features and defining characteristics of your profile. Type nouns, adjectives, tags, or keywords. The first three keywords will be displayed in your profile summary, so make sure to add the most important ones first."
              setIsShow={() => handlePrivacy('features')}
              dataField={state.features}
              defaultSuggestedTitles={avs.keywords.values.slice(0, 4)}
              id={artistProfile.id}
              setState={setState}
              state={state}
            />
          )}

          <InfoEdit
            Icon={SkillsIcon}
            title="skills"
            defaultTitles={state.otherSkills?.value}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="Add your most relevant music and non-music skills and expertise that define you."
            setIsShow={() => handlePrivacy('otherSkills')}
            defaultSuggestedTitles={[
              'Songwriting',
              'Composing',
              'Photography',
              'Web Design',
            ]}
            dataField={state.otherSkills}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={InstrumentsIcon}
            title="instruments"
            defaultTitles={[]}
            defaultUsedTitles={state.instrumentsPlayed?.value || []}
            defaultOwnedTitles={state.instrumentsOwned?.value || []}
            description="List the musical instruments you can play and the ones that you own."
            isShow={false}
            hasUsed
            setIsShow={() => {
              const synced =
                isPublished(state.instrumentsPlayed) ===
                isPublished(state.instrumentsOwned);
              handlePrivacy('instrumentsPlayed');
              if (synced) handlePrivacy('instrumentsOwned');
            }}
            defaultSuggestedTitles={[]}
            dataField={state.instrumentsPlayed}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={ToolsAndGearIcon}
            title="tools and gear"
            defaultTitles={[]}
            defaultUsedTitles={state.toolsUsed?.value || []}
            defaultOwnedTitles={state.toolsOwned?.value || []}
            description="Add your preferred softwares, instruments, and other gear you can use and/or own. Do you use a specific DAW, or plugins? This is an important field to improve your discoverability in case brands are looking for a music maker that is using their brand for promotional activities/endorsement, or just to share what you use in your public profile so music makers can check each other’s gear or get inspiration."
            isShow={false}
            hasUsed
            setIsShow={() => {
              const synced =
                isPublished(state.toolsUsed) === isPublished(state.toolsOwned);
              handlePrivacy('toolsUsed');
              if (synced) handlePrivacy('toolsOwned');
            }}
            defaultSuggestedTitles={[]}
            dataField={state.toolsUsed}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={GenresIcon}
            title="genres"
            defaultTitles={state.genres?.value || []}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="Add the genres that best represent your music."
            setIsShow={() => handlePrivacy('genres')}
            dataField={state.genres}
            defaultSuggestedTitles={['Jazz', 'Classical', 'Minimal']}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={LanguagesIcon}
            title="spoken languages"
            defaultTitles={state.spokenLanguages?.value || []}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="List all the languages that you speak fluently."
            setIsShow={() => handlePrivacy('spokenLanguages')}
            dataField={state.spokenLanguages}
            defaultSuggestedTitles={[]}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={FavoriteArtistsIcon}
            title="favorite artists"
            defaultTitles={state.favouriteMusicMakers?.value || []}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="List all your favourite artists and Music Makers. This is an important field to share with the music makers’ community and champion your favourite colleagues, and the Music industry too. For instance… imagine if a DSP will be able to access this information and offer playlists or artist’s radio based on what music you like."
            setIsShow={() => handlePrivacy('favouriteMusicMakers')}
            dataField={state.favouriteMusicMakers}
            defaultSuggestedTitles={[]}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={EndorsementIcon}
            title="endorsements"
            defaultTitles={state.endorsement?.value || []}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="List if you are endorsing a specific brand or equipment. This is an important field to improve your discoverability."
            setIsShow={() => handlePrivacy('endorsement')}
            dataField={state.endorsement}
            defaultSuggestedTitles={[]}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={InspirationsIcon}
            title="interests / inspirations"
            defaultTitles={state.interestsInspiration?.value || []}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="List all your interests or inspirations. Are you interested in particle physics or molecular cuisine? A specific book or painter? This is an important field to improve your discoverability and to share with the music makers’ community your preferences."
            setIsShow={() => handlePrivacy('interestsInspiration')}
            dataField={state.interestsInspiration}
            defaultSuggestedTitles={[]}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={SupportedCauses}
            title="supported causes"
            defaultTitles={state.charitiesSupported?.value || []}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="List all the charities and charitable causes you support. This is an important field, as it can help to increase the visibility of the causes or institutions you care the most."
            setIsShow={() => handlePrivacy('charitiesSupported')}
            dataField={state.charitiesSupported}
            defaultSuggestedTitles={[]}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
          <InfoEdit
            Icon={QuotesIcon}
            title="quotes"
            defaultTitles={state.favouriteQuote?.value || []}
            defaultUsedTitles={[]}
            defaultOwnedTitles={[]}
            description="Do you have a favourite quote that represents YOU or your artistic persona? This is an important field to improve your discoverability."
            setIsShow={() => handlePrivacy('favouriteQuote')}
            defaultSuggestedTitles={[]}
            dataField={state.favouriteQuote}
            id={artistProfile.id}
            setState={setState}
            state={state}
          />
        </div>
      </Modal>
      {/* Profile Info Display */}
      <div className={classes.root}>
        <TitleInfo
          title="Profile Info"
          isNotEmpty={!isEmpty}
          message="Edit profile information"
          onClick={() => setEditMode(true)}
        />

        {isEmpty ? (
          <Box
            className={classes.empty}
            data-testid={`empty-${artistProfile.id}-display`}
          >
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <div className={classes.emptyIcons}>
                <SkillsIcon />
                <InstrumentsIcon />
                <ToolsAndGearIcon />
              </div>
              <Typography variant="body1">
                Add Skills, Instruments, Tools, Gear and other info that define
                your uniqueness
              </Typography>
              <IconTextButton
                data-testid="add-data-button"
                onClick={() => {
                  setEditMode(true);
                }}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your profile info
              </IconTextButton>
            </Stack>
          </Box>
        ) : (
          <div className={classes.main}>
            {!artistProfile.isProfessionalProfile &&
            state.features?.value?.length ? (
              <Info
                Icon={FeaturesIcon}
                title="keywords"
                titles={state.features?.value}
                dataField={state.features}
              />
            ) : null}
            {state.musicRoles?.value?.length ? (
              <Info
                Icon={RolesIcon}
                title="roles"
                titles={state.musicRoles?.value}
                dataField={state.musicRoles}
              />
            ) : null}
            {state.otherSkills?.value?.length ? (
              <Info
                Icon={SkillsIcon}
                title="skills"
                titles={state.otherSkills?.value}
                dataField={state.otherSkills}
              />
            ) : null}
            {state.instrumentsPlayed?.value?.length ? (
              <Info
                Icon={InstrumentsIcon}
                title="instruments used"
                titles={state.instrumentsPlayed?.value}
                dataField={state.instrumentsPlayed}
              />
            ) : null}
            {state.instrumentsOwned?.value?.length ? (
              <Info
                Icon={InstrumentsIcon}
                title="instruments owned"
                titles={state.instrumentsOwned?.value}
                dataField={state.instrumentsOwned}
              />
            ) : null}
            {state.toolsUsed?.value?.length ? (
              <Info
                Icon={ToolsAndGearIcon}
                title="tools and gear used"
                titles={state.toolsUsed?.value}
                dataField={state.toolsUsed}
              />
            ) : null}
            {state.toolsOwned?.value?.length ? (
              <Info
                Icon={ToolsAndGearIcon}
                title="tools and gear owned"
                titles={state.toolsOwned?.value}
                dataField={state.toolsOwned}
              />
            ) : null}
            {state?.favouriteMusicMakers?.value?.length ? (
              <Info
                Icon={FavoriteArtistsIcon}
                title="favorite artists"
                titles={state.favouriteMusicMakers?.value}
                dataField={state.favouriteMusicMakers}
              />
            ) : null}
            {state.genres?.value?.length ? (
              <Info
                Icon={GenresIcon}
                title="genres"
                titles={state.genres?.value}
                dataField={state.genres}
              />
            ) : null}
            {state.spokenLanguages?.value?.length ? (
              <Info
                Icon={LanguagesIcon}
                title="spoken languages"
                titles={state.spokenLanguages?.value}
                dataField={state.spokenLanguages}
              />
            ) : null}
            {state.endorsement?.value?.length ? (
              <Info
                Icon={EndorsementIcon}
                title="endorsements"
                titles={state.endorsement?.value}
                dataField={state.endorsement}
              />
            ) : null}
            {state.interestsInspiration?.value?.length ? (
              <Info
                Icon={InspirationsIcon}
                title="interests / inspirations"
                titles={state.interestsInspiration?.value}
                dataField={state.interestsInspiration}
              />
            ) : null}
            {state.charitiesSupported?.value?.length ? (
              <Info
                Icon={SupportedCauses}
                title="supported causes"
                titles={state.charitiesSupported?.value}
                dataField={state.charitiesSupported}
              />
            ) : null}
            {state.favouriteQuote?.value?.length ? (
              <Info
                Icon={QuotesIcon}
                title="quotes"
                titles={state.favouriteQuote?.value}
                dataField={state.favouriteQuote}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalInfo;
