import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '1.5rem',
    height: '2.5rem',
    color: '#626466',
    fontSize: 12,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textTransform: 'uppercase',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    [theme.breakpoints.up('md')]: {
      width: '320px',
    },
  },
  small: {
    height: '2rem',
    color: '#626466',
    fontSize: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textTransform: 'uppercase',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
  },
  icon: {
    display: 'flex',
    alignContent: 'center',
    marginRight: '0.5rem',
  },
  large: {
    height: '3rem',
    color: '#626466',
    fontSize: 14,
    borderRadius: '2rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    backgroundColor: '#FFFFFF',
    textTransform: 'uppercase',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    width: '50%',
  },
}));

const IconTextButton = ({
  size = 'standard',
  icon,
  onClick,
  children,
  className = 'standard',
  sx = {},
}) => {
  const classes = useStyles();
  return (
    <Button
      sx={sx}
      data-testid="add-data-button"
      className={clsx(
        className,
        size === 'small'
          ? classes.small
          : size === 'large'
          ? classes.large
          : classes.root
      )}
      disableElevation
      onClick={onClick}
    >
      <div className={classes.icon}>{icon}</div>
      {children}
    </Button>
  );
};

IconTextButton.displayName = 'IconTextButton';

export default IconTextButton;
