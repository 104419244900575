import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import VevaCheckInDialog from './VevaCheckInDialog';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import vevaLogo from '../../../images/veva_collect_black.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flex: 1,
  },
  media: {
    backgroundColor: '#f6f7f9',
    backgroundSize: 'contain',
    height: 150,
  },
});

export default function VevaCard() {
  const classes = useStyles();
  const [vevaCheckIn, setVevaCheckIn] = useState(false);

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={vevaLogo}
        title="VEVA Collect"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="h2">
          VEVA Collect Check&#8209;In
        </Typography>
        <Typography color="textSecondary" component="p">
          Using VEVA Check-In App, everyone who works on a project can
          automatically contribute their credit with one simple tap.
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          href="https://www.vevacollect.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => setVevaCheckIn(true)}
        >
          Check-In
        </Button>
      </CardActions>

      <VevaCheckInDialog
        open={vevaCheckIn}
        onClose={() => setVevaCheckIn(false)}
      />
    </Card>
  );
}
