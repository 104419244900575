import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AtomsIconsEdit from '../icons/atomsIconsEdit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
}));

const defaultProps = {
  title: '',
};

const CPEditButton = ({ title, onClick, ...other }) => {
  const classes = useStyles();

  return (
    <Tooltip title={title} classes={{ tooltip: classes.tooltip }} arrow>
      <IconButton
        color="inherit"
        aria-label="menu"
        onClick={onClick}
        className={classes.root}
        size="medium"
        {...other}
      >
        <AtomsIconsEdit sx={{ fontSize: '15px' }} />
      </IconButton>
    </Tooltip>
  );
};

CPEditButton.defaultProps = defaultProps;

export default CPEditButton;
