import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthProvider';

const PublicRoute = ({ children, component: Component, ...rest }) => {
  const { authState } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState === 'LOGGED_IN' ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ) : Component ? (
          <Component />
        ) : (
          children
        )
      }
    />
  );
};

export default PublicRoute;
