import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon({ hasGradient, sx, ...rest }) {
  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? 'url(#MyGradient)' : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient id="MyGradient" gradientTransform="rotate(10)">
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={hasGradient ? null : '#626466'}>
          <path
            d="M14.432,16 L15.699,19.621 L13.335,22 L6.816,22 L2.983,18.142 L3.732,16 L14.432,16 Z M10.767,2.478 C11.868,3.114 12.552,4.298 12.552,5.569 L12.552,9.091 L10.09,11.31 L6.396,7.98 L6.396,3.569 C6.396,3.4 6.423,3.236 6.437,3.07 C6.661,2.842 6.915,2.642 7.198,2.478 C7.74,2.165 8.357,2 8.983,2 C9.609,2 10.225,2.165 10.767,2.478 L10.767,2.478 Z M15.851,14 L10.093,14 L14.552,9.98 L14.552,5.569 C14.552,3.58 13.491,1.741 11.767,0.746 C10.906,0.249 9.944,0 8.983,0 C8.259,0 7.536,0.141 6.854,0.423 C6.631,0.515 6.411,0.623 6.198,0.746 C5.552,1.119 4.999,1.611 4.56,2.185 C4.413,2.376 4.28,2.576 4.16,2.785 C3.819,3.374 3.594,4.028 3.487,4.711 C3.443,4.993 3.414,5.279 3.414,5.569 L3.414,9.98 L7.873,14 L4.432,14 L4.432,14 L2.15,14 L0,20.142 L3.834,24 L14.167,24 L18,20.142 L15.851,14 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
