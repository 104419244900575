import { useTheme } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { BiLoaderAlt } from 'react-icons/bi';
import RightArrow from '../../../icons/atomsIconsRarr';
import LeftArrow from '../../../icons/atomsIconsLarr';

const Button = ({ children, loading, width, isBack, ...rest }) => {
  const theme = useTheme();

  const styles = {
    [theme.breakpoints.only('xs')]: {
      marginRight: '1rem',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: loading ? 'center' : 'space-between',
    backgroundColor: 'rgba(255,255,255, 1)',
    padding: '0.5rem 1rem 0.5rem 1rem',
    borderRadius: '28px',
    boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    transition: '0.3s',
    border: '1px solid rgba(0,0,0,0)',
    textTransform: 'capitalize',
    '&:disabled': {
      border: '1px solid rgba(0,0,0,0.08)',
      opacity: 0.6,
      cursor: 'not-allowed',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255, 1)',
      },
    },
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  };

  if (isBack) {
    styles[theme.breakpoints.only('xs')] = {
      padding: '0.5rem',
      marginLeft: '1rem',
    };
  }

  return (
    <Box gap={1} component="button" {...rest} sx={styles}>
      {loading ? (
        <BiLoaderAlt className="loader" />
      ) : (
        <>
          {isBack && <LeftArrow sx={{ fontSize: '22px' }} />}
          <Typography
            color="#313233"
            fontWeight="500"
            textAlign="center"
            fontSize="14px"
            sx={{
              display: {
                xs: isBack ? 'none' : 'inline',
                sm: 'inline',
              },
            }}
          >
            {children}
          </Typography>
          {!isBack && <RightArrow sx={{ fontSize: '22px' }} />}
        </>
      )}
    </Box>
  );
};

export default Button;
