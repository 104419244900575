import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import withRedirectOnNotAuth from '../../hocs/withRedirectOnNotAuth';
import useVerifyYoti from '../../hooks/useVerifyYoti';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function YotiCallback() {
  const history = useHistory();
  const search = useQuery();
  const { mutateAsync: verifyYoti } = useVerifyYoti();
  const yotiToken = search.get('token');
  useEffect(() => {
    verifyYoti(yotiToken)
      .then(() => history.push('/'))
      .catch((err) => {
        console.error(err);
        history.push('/');
      });
  }, [yotiToken, verifyYoti, history]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <Typography component="h1" variant="h5">
          Verifying with Yoti, please wait...
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withRedirectOnNotAuth(YotiCallback);
