import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import {
  FormControl,
  TextField,
  Typography,
  MenuItem,
  Select,
  Box,
} from '@mui/material';
import CPVisibleToggle from '../../CPVisibleToggle';
import CPVisibleIcon from '../../CPVisibleIcon';
import CPDeleteButton from '../../CPDeleteButton';
import LabelBox from '../../LabelBox';
import PhoneIcon from '../../../icons/phoneIcon';
import MailIcon from '../../../icons/atomsMail';
import { toggleIsPublished } from '../../../services.js';

const useStyles = makeStyles(styles);

const repOptions = {
  label: {
    key: 0,
    label: 'Label',
  },
  publisher: {
    key: 1,
    label: 'Publisher',
  },
  collectionSociety: {
    key: 2,
    label: 'Collection Society',
  },
  distributor: { key: 3, label: 'Distributor' },
  prAgency: {
    key: 11,
    label: 'PR Agency',
  },
  agent: {
    key: 4,
    label: 'Agent',
  },
  legal: {
    key: 5,
    label: 'Legal',
  },
  syncAgency: {
    key: 6,
    label: 'Sync Agency',
  },
  management: {
    key: 7,
    label: 'Management',
  },
  generalContact: { key: 8, label: 'General Contact' },
};

const Representative = ({
  editMode,
  representative,
  onDelete,
  onDataChange,
}) => {
  const classes = useStyles();

  const handleRepTypeChange = (e) => {
    onDataChange({
      ...representative,
      fieldName: e.target.value,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    onDataChange({
      ...representative,
      value: { ...representative?.value, [name]: value },
    });
  };

  const handleDelete = () => {
    onDelete(representative);
  };

  const handlePrivacy = () => {
    onDataChange({
      ...representative,
      publishers: toggleIsPublished(representative),
    });
  };

  const contactRepType = repOptions[representative?.fieldName]?.label;

  return editMode ? (
    <>
      <div className={classes.root}>
        <form>
          <FormControl className={classes.topForm} size="small">
            <Box className={classes.topEdit}>
              <Select
                labelId={`rep-type`}
                id={`rep-type`}
                label="Representative Type"
                disableUnderline
                variant="filled"
                value={representative?.fieldName || 'Select'}
                onChange={handleRepTypeChange}
                required
                className={classes.repTypeSelect}
                defaultValue={representative?.fieldName || 'Select'}
              >
                <MenuItem value="Select">{'Please select'}</MenuItem>
                {Object.keys(repOptions).map((key) => (
                  <MenuItem value={key}>{repOptions[key].label}</MenuItem>
                ))}
              </Select>
              <Box sx={{ marginTop: '0.3rem' }}>
                <CPVisibleToggle
                  onClick={handlePrivacy}
                  dataField={representative}
                  darker={true}
                  sx={{ marginTop: '8px', height: '2rem' }}
                />
              </Box>
              <CPDeleteButton onClick={handleDelete} />
            </Box>
          </FormControl>
          <FormControl className={classes.form}>
            <TextField
              label="Name"
              variant="standard"
              fullWidth
              className={classes.editField}
              defaultValue={representative?.value?.repName}
              required
              name="repName"
              onChange={handleChange}
            />
            <TextField
              label="Email"
              variant="standard"
              fullWidth
              className={classes.editField}
              defaultValue={representative?.value?.contactEmail}
              name="contactEmail"
              onChange={handleChange}
            />
            {/* {allowPhone ? ( */}
            <TextField
              label="Phone Number"
              variant="standard"
              className={classes.editField}
              defaultValue={representative?.value?.contactPhone}
              name="contactPhone"
              onChange={handleChange}
            />{' '}
            {/*
            ) : (
               <CPAddButton
                size="small"
                onClick={() => setAllowPhone(true)}
                className={classes.optionalButton}
              >
                Add Phone Number
            </CPAddButton>
            )} */}
            {/* {allowDescription ? ( */}
            <TextField
              label="Description"
              variant="standard"
              className={classes.editField}
              defaultValue={representative?.value?.contactNote}
              name="contactNote"
              onChange={handleChange}
              multiline={true}
            />{' '}
            {/*
            ) : (
              <CPAddButton
                size="small"
                onClick={() => setAllowDescription(true)}
                className={classes.optionalButton}
              >
                Add Description
              </CPAddButton>
            )} */}
          </FormControl>
        </form>
      </div>
    </>
  ) : (
    <Box className={classes.info}>
      <Box className={classes.top}>
        <LabelBox>{contactRepType}</LabelBox>
        <div className={classes.icon}>
          <CPVisibleIcon dataField={representative} />
        </div>
      </Box>
      <Typography className={classes.infoName} variant="h5">
        {representative?.value?.repName}
      </Typography>
      <Box className={classes.infoContact}>
        <Typography className={classes.infoContactSelf}>
          {representative?.value?.contactPhone ? (
            <>
              <PhoneIcon
                sx={{
                  fontSize: '16px',
                  height: '14.67px',
                  fullWidth: '14.67px',
                }}
              />
              {representative?.value?.contactPhone}
            </>
          ) : null}
        </Typography>
        <Typography className={classes.infoContactSelf}>
          <MailIcon
            sx={{ fontSize: '16px', fullWidth: '16px', height: '13.33px' }}
          />
          {representative?.value?.contactEmail}
        </Typography>
      </Box>
      <Typography>{representative?.value?.contactNote}</Typography>
    </Box>
  );
};

export default Representative;
