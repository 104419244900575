import React, { useEffect, useState } from 'react';
import CPCheckbox from '../../components/CPCheckbox';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CPDialog from '../../components/CPDialog';
import get from 'lodash/get';
import { representative_fields, text_fields } from '../../text_fields';
import useUniqueId from '../../hooks/useUniqueId';

const useStyles = makeStyles((theme) => ({
  root: {},
  pos: {
    marginBottom: 12,
  },
  [theme.breakpoints.up('md')]: {
    list: {
      columnCount: 2,
    },
  },
  listItem: {
    display: 'inline-block', // Prevent wrapping inside the list item
  },
  listAll: {
    display: 'block',
    width: '150px',
    paddingLeft: theme.spacing(3),
  },
}));

const ToggleItem = ({ id, className, field, enabled, onChange }) => {
  return (
    <ListItem className={className}>
      <ListItemText primary={<label htmlFor={id}>{field.label}</label>} />
      <ListItemSecondaryAction>
        <CPCheckbox
          id={id}
          message={
            enabled === 'all'
              ? 'The toggle for all services has been enabled so it cannot be disabled here'
              : undefined
          }
          disabled={enabled === 'all'}
          onChange={() => onChange(field.key)}
          checked={!!enabled}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const validModes = ['ENABLE', 'EDIT'];

function ServicePermissionsDialog({
  mode,
  onClose,
  onEnable,
  supportedFields,
  publisher,
  profile,
  explanationText,
  enableTitleText = 'Enable service',
  enableButtonText = 'Enable',
  editTitleText = 'Edit service',
  editButtonText = 'Save Permissions',
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const id = useUniqueId('servicedialog-');

  if (mode && !validModes.includes(mode)) {
    throw new Error('Invalid mode');
  }

  const customIds = profile.customIds?.value;
  const customLinks = profile.customLinks?.value;
  const representatives = profile.representatives?.value;
  const officialLinks = profile.officialLinks?.value;

  useEffect(() => {
    if (mode) {
      let s = {};
      supportedFields.forEach((f) => {
        const publishersEnabled = get(profile, f.key + '.publishers', []);
        s[f.key] =
          publishersEnabled === 'all'
            ? 'all'
            : publishersEnabled.includes(publisher);
      });
      if (customIds && customIds.length > 0) {
        customIds.forEach((f, i) => {
          s[`customIds.value[${i}]`] =
            f.publishers === 'all'
              ? f.publishers
              : f.publishers?.includes(publisher);
        });
      }
      if (customLinks && customLinks.length > 0) {
        customLinks.forEach((f, i) => {
          s[`customLinks.value[${i}]`] =
            f.publishers === 'all'
              ? f.publishers
              : f.publishers?.includes(publisher);
        });
      }
      if (representatives && representatives.length > 0) {
        representatives.forEach((f, i) => {
          s[`representatives.value[${i}]`] =
            f.publishers === 'all'
              ? f.publishers
              : f.publishers?.includes(publisher);
        });
      }
      if (officialLinks && officialLinks.length > 0) {
        officialLinks.forEach((f, i) => {
          s[`officialLinks.value[${i}]`] =
            f.publishers === 'all'
              ? f.publishers
              : f.publishers?.includes(publisher);
        });
      }
      setState(s);
      setOpen(true);

      let selectAllOnLoad = Object.values(s).every((val) => val === true);
      if (selectAllOnLoad) {
        setSelectAll(true);
      }
    }
  }, [
    mode,
    customIds,
    customLinks,
    officialLinks,
    profile,
    publisher,
    representatives,
    supportedFields,
  ]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleEnable = () => {
    setOpen(false);
    onEnable(
      Object.entries(state)
        .map(([key, published]) => ({ key, published }))
        .filter((v) => v.published !== 'all')
    );
  };

  const handleToggle = (key) => {
    setState((s) => ({
      ...state,
      [key]: s[key] === 'all' ? s : !s[key],
    }));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const stateCopy = { ...state };

    // only change it if it's not "all"
    if (selectAll === true) {
      Object.keys(stateCopy).forEach((key) => {
        if (stateCopy[key] !== 'all') {
          stateCopy[key] = false;
        }
      });
    } else {
      Object.keys(stateCopy).forEach((key) => {
        if (stateCopy[key] !== 'all') {
          stateCopy[key] = true;
        }
      });
    }
    setState(stateCopy);
  };

  const selectAllItem = (
    <List className={classes.listAll}>
      <ListItem>
        <ListItemText primary="Select All" />
        <ListItemSecondaryAction>
          <CPCheckbox
            id="select-all"
            message="Select All"
            onChange={handleSelectAll}
            checked={selectAll}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <CPDialog.Title id="form-dialog-title">
        {mode === 'ENABLE' ? enableTitleText : editTitleText}
      </CPDialog.Title>
      <DialogContent>
        <Box display="flex">
          <Box p={1} flexGrow={1}>
            {explanationText && (
              <DialogContentText>{explanationText}</DialogContentText>
            )}
          </Box>
          <Box>{selectAllItem}</Box>
        </Box>
        {state && (
          <>
            <List className={classes.list}>
              {supportedFields.map((f) => {
                const fieldState = state[f.key];
                return (
                  <ToggleItem
                    id={`${id}-field-${f.key}`}
                    key={f.key}
                    className={classes.listItem}
                    field={f}
                    enabled={fieldState}
                    publisher={publisher}
                    onChange={handleToggle}
                  />
                );
              })}
              {customIds && customIds.length > 0 && (
                <>
                  <ListSubheader>Custom IDs</ListSubheader>
                  {customIds.map((r, i) => {
                    const key = `customIds.value[${i}]`;
                    const fieldState = state[key];
                    return (
                      <ToggleItem
                        id={`${id}-customId-${i}`}
                        key={key}
                        className={classes.listItem}
                        field={{
                          key,
                          label:
                            r.fieldName && r.fieldName !== ''
                              ? r.fieldName
                              : 'Untitled',
                        }}
                        enabled={fieldState}
                        publisher={publisher}
                        onChange={handleToggle}
                      />
                    );
                  })}
                </>
              )}
              {representatives && representatives.length > 0 && (
                <>
                  <ListSubheader>Representatives</ListSubheader>
                  {representatives.map((r, i) => {
                    const niceFieldName =
                      representative_fields[r.fieldName].label;
                    const key = `representatives.value[${i}]`;
                    const fieldState = state[key];
                    return (
                      <ToggleItem
                        id={`${id}-representative-${i}`}
                        key={key}
                        className={classes.listItem}
                        field={{
                          key,
                          label: `${niceFieldName}: ${r.value?.repName}`,
                        }}
                        enabled={fieldState}
                        publisher={publisher}
                        onChange={handleToggle}
                      />
                    );
                  })}
                </>
              )}
              {officialLinks && officialLinks.length > 0 && (
                <>
                  <ListSubheader>Official Links</ListSubheader>
                  {officialLinks.map((r, i) => {
                    const niceFieldName =
                      text_fields.official_links.data_fields.officialLinks[
                        r.fieldName
                      ].label;
                    const key = `officialLinks.value[${i}]`;
                    const fieldState = state[key];
                    return (
                      <ToggleItem
                        id={`${id}-officialLink-${i}`}
                        key={key}
                        className={classes.listItem}
                        field={{
                          key,
                          label: `${niceFieldName}: ${r.value}`,
                        }}
                        enabled={fieldState}
                        publisher={publisher}
                        onChange={handleToggle}
                      />
                    );
                  })}
                </>
              )}
              {customLinks && customLinks.length > 0 && (
                <>
                  <ListSubheader>Custom Official Links</ListSubheader>
                  {customLinks.map((r, i) => {
                    const key = `customLinks.value[${i}]`;
                    const fieldState = state[key];
                    return (
                      <ToggleItem
                        id={`${id}-customOfficialLink-${i}`}
                        key={key}
                        className={classes.listItem}
                        field={{
                          key,
                          label:
                            r.fieldName && r.fieldName !== ''
                              ? r.fieldName
                              : 'Untitled',
                        }}
                        enabled={fieldState}
                        publisher={publisher}
                        onChange={handleToggle}
                      />
                    );
                  })}
                </>
              )}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleEnable} color="primary">
          {mode === 'ENABLE' ? enableButtonText : editButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ServicePermissionsDialog;
