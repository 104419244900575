const reducer = (state, action) => {
  switch (action.type) {
    case 'POPULATE':
      return { ...state, ...action.payload };

    case 'SET_TAB':
      return { ...state, activeTab: action.payload };

    case 'ADD_TAB':
      const existingTab = state.artistProfiles.find(
        (el) => el.id === action.payload.id
      );
      const payload = {
        id: action.payload.id,
        isProfessionalProfile: action.payload.isProfessionalProfile || false,
        type: action.payload.type ?? 'band',
        artistName: action.payload.artistName || '',
        dateOfBirth: {
          value: action.payload.dateOfBirth?.value || '',
          publishers: [],
        },
        city: { value: '', publishers: [] },
        aliasShowBoth: true,
        countryAlpha2: {
          value: action.payload.countryAlpha2?.value || '',
          publishers: [],
        },
        gender: { value: '', publishers: [] },
        musicRoles: {
          value: action.payload.musicRoles?.value || [],
          publishers: [],
        },
        pronoun: { value: '', publishers: [] },
        aliases: { value: [], publishers: [] },
        features: {
          value: action.payload.features?.value || [],
          publishers: [],
        },
        biography: {
          short: {
            value: '',
            publishers: [],
          },
          long: {
            value: '',
            publishers: [],
          },
          headline: {
            value: '',
            publishers: [],
          },
        },
        representatives: { value: [] },
        customIds: { value: [], publishers: [] },
        otherSkills: {
          value: action.payload.skills?.value
            ? action.payload.skills?.value
            : [],
          publishers: [],
        },
        toolsUsed: {
          value: [],
          publishers: [],
        },
        toolsOwned: {
          value: [],
          publishers: [],
        },
        instrumentsOwned: {
          value: [],
          publishers: [],
        },
        instrumentsPlayed: {
          value: [],
          publishers: [],
        },
        favouriteMusicMakers: { value: [], publishers: [] },
        genres: { value: [], publishers: [] },
        spokenLanguages: { value: [], publishers: [] },
        endorsement: { value: [], publishers: [] },
        interestsInspiration: { value: [], publishers: [] },
        charitiesSupported: { value: [], publishers: [] },
        favouriteQuote: { value: [], publishers: [] },
      };
      if (!existingTab) {
        if (action.payload.index === 0) {
          //FIXME: other indices shouldn't fail silently
          const newState = {
            ...state,
            artistProfiles: [payload, ...state.artistProfiles],
          };
          return newState;
        }
        return {
          ...state,
          artistProfiles: [...state.artistProfiles, payload],
        };
      }
      break;

    case 'REMOVE_TAB':
      return {
        ...state,
        artistProfiles: state.artistProfiles.filter(
          (el) => el.id !== action.payload.id
        ),
      };

    case 'PATCH_PROFILE':
      const profileToPatch = state.artistProfiles.find(
        (el) => el.id === action.payload.id
      );
      const indexUpdate = action.payload.index;
      delete action.payload.index;
      if (profileToPatch) {
        let updatedProfiles = state.artistProfiles.map((el) => {
          if (el.id !== action.payload.id) return el;
          const patchedProfile = JSON.parse(JSON.stringify(el));
          for (const key in action.payload) {
            if (
              ['string', 'boolean'].includes(typeof action.payload[key]) ||
              Array.isArray(action.payload[key])
            ) {
              patchedProfile[key] = action.payload[key];
            } else {
              patchedProfile[key] = {
                ...patchedProfile[key],
                ...action.payload[key],
              };
            }
          }
          return patchedProfile;
        });
        if (indexUpdate === 0) {
          updatedProfiles = [
            updatedProfiles.find((up) => up.id === action.payload.id),
            ...updatedProfiles.filter((up) => up.id !== action.payload.id),
          ];
        }
        return {
          ...state,
          artistProfiles: updatedProfiles,
        };
      } else {
        return state;
      }

    case 'PATCH_PERSONAL':
      const patchedState = {
        ...state,
        personalData: { ...state.personalData, ...action.payload },
      };
      return patchedState;

    default:
      return state;
  }
};

export default reducer;
