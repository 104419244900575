import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthProvider';
import useServerProfile from '../hooks/useServerProfile';
import CircularProgress from '@mui/material/CircularProgress';

const PrivateRoute = ({
  children,
  allowDeleted,
  component: Component,
  ...rest
}) => {
  const { authState } = useAuth();
  let redirect;
  let content = null;
  const { isSuccess, profile } = useServerProfile();
  switch (authState) {
    case 'LOGGED_IN':
      if (isSuccess) {
        if (profile && profile.DeleteAt && !allowDeleted) {
          redirect = '/deleted';
        } else {
          content = Component ? <Component /> : children;
        }
      }
      break;
    case 'LOGGED_OUT':
      redirect = '/register';
      break;
    case 'UNCONFIRMED':
      redirect = '/confirm';
      break;
    case 'INIT':
      content = (
        <CircularProgress
          sx={{
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        />
      );
      break;
    default:
      content = (
        <div>
          <p>Unrecognised authentication state</p>
        </div>
      );
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        redirect ? (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location },
            }}
          />
        ) : (
          content
        )
      }
    />
  );
};
export default PrivateRoute;
