import { buttonStyles } from '../../../layout/styles';

const styles = (theme) => ({
  root: {},
  main: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(8),
    padding: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  tab: {
    flexGrow: 1,
    height: '50px',
    padding: '15px',
    textTransform: 'none',
  },
  tabs: {
    width: '100%',
    minHeight: '100%',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden !important',
    marginRight: '1rem',
    marginBottom: '1rem',
  },
  typeIcon: {
    width: '16px',
    marginRight: '1px',
    marginLeft: '-8px',
    marginTop: '2px',
    backgroundColor: 'transparent',
  },
  inputField: {
    width: '100%',
  },
  myLabel: {
    margin: '0',
    marginBottom: theme.spacing(1),
    fontWeight: '600',
    fontSize: '16px',
    color: '#313233',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
  },
  emptyPlaceholder: {
    padding: theme.spacing(1.5),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d3d3d2',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  emptyPlaceholderText: {
    margin: 0,
    width: '90%',
    textAlign: 'center',
    color: '#7d7d7d',
  },
  emptyPlaceholderSVG: {
    fontSize: '25px',
    color: '#d3d2d2',
  },
  flexInput: {
    border: 'none',
    outline: 'none',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '410px',
    },
    height: '100%',
    backgroundColor: 'transparent',
  },
  loc: {
    backgroundColor: '#E5FFF9',
    color: '#313233',
    textTransform: 'capitalize',
    padding: theme.spacing(0.75),
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    border: '#00FFCC solid 2px',
    fontWeight: '600',
  },
  locationTyping: {
    position: 'relative',
    minWidth: theme.spacing(1),
  },
  autocomplete: {
    position: 'absolute',
    zIndex: 6,
    top: '100%',
    left: 0,
    width: theme.spacing(30),
    maxHeight: theme.spacing(30),
    overflowY: 'scroll',
    border: '1px solid #d3d3d2',
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
    backgroundColor: 'white',
  },
  autocompleteItem: {
    display: 'block',
    padding: '8px 6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#02d1a8',
      color: 'white',
    },
  },
  eyes: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  eye: {
    color: '#d5d5d4',
    height: '20px',
    width: '40px',
    cursor: 'pointer',
  },
  eyeActive: {
    backgroundColor: '#dadbdb',
    height: '20px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  locationEyes: {
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
  },
  ...buttonStyles,
});

export default styles;
