import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CPVisibleIcon from '../../CPVisibleIcon';
import LabelBox from '../../LabelBox';

export const Project = ({ item }) => {
  return (
    <>
      {item?.fieldName && (
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: '1rem',
            '@media (max-width: 600px)': {
              borderRadius: '0rem',
            },
            padding: '1.5rem',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <LabelBox>{item.fieldName}</LabelBox>
            <CPVisibleIcon dataField={item} />
          </Stack>
          {item?.value && (
            <Typography
              variant="body1"
              sx={{
                paddingTop: '1rem',
                whiteSpace: 'pre-wrap',
              }}
            >
              {item.value}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
