import Ajv from 'ajv';

export const versionNumbers = {
  'v1.0.0': 0.1,
  'v2.0.0': 2,
};
export const CURRENT_SCHEMA_VERSION = 'v2.0.0';

const getValidator = (schemaVersion, subschema) => {
  const ajv = new Ajv({ allErrors: true });
  let main, passport, persona;
  switch (schemaVersion) {
    case 'v1.0.0':
      main = require('./schemas/main_v1.0.0.json');
      passport = require('./schemas/passport_v1.0.0.json');
      persona = require('./schemas/persona_v1.0.0.json');
      break;
    case 'v2.0.0':
      main = require('./schemas/main_v2.0.0.json');
      passport = require('./schemas/passport_v2.0.0.json');
      persona = require('./schemas/persona_v2.0.0.json');
      break;
    default:
      return null;
  }
  if (subschema === 'all')
    return ajv.addSchema(persona).addSchema(passport).compile(main);
  else if (subschema === 'passport')
    return ajv.addSchema(persona).compile(passport);
  else if (subschema === 'persona') return ajv.compile(persona);
  return null;
};

export const validateAgainstSchema = (
  dataToValidate,
  subschema,
  schemaVersion = CURRENT_SCHEMA_VERSION
) => {
  // TODO: check this replaces as much validation as possible from code validation
  // TODO: everywhere this is called with undefined schema, run auto-update by version number first
  const validator = getValidator(schemaVersion, subschema);
  if (validator === null)
    return {
      valid: false,
      error: "[{message: 'No matching validator found'}]",
    };
  const valid = validator(dataToValidate);
  if (valid) return { valid: true, error: '' };
  return { valid: false, error: JSON.stringify(validator.errors) };
};
