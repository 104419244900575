import React from 'react';
import Bios from './SubComponents/Bios';
import OfficialLinks from './SubComponents/OfficialLinks';
import Profile from './SubComponents/Profile';

const ProfileMainContents = () => {
  return (
    <React.Fragment>
      <Profile />
      <Bios />
      <OfficialLinks />
    </React.Fragment>
  );
};

export default ProfileMainContents;
