import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import styles from './styles';
import {
  useMutatePatchProfile,
  useMutatePostProfile,
} from '../../hooks/useMutateServerProfile';
import CPButton from '../../components/CPButton';
import { Grid, Paper, IconButton, Box, Typography } from '@mui/material';
import Button from '../newuser/button';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import { useProfileContext } from '../passportData/context';
import useDisclosure from '../../hooks/useDisclosure';
import ToolTip from '../../icons/atomsTooltip.js';
import ProfileTypes from '../modals/ProfileTypes';
import ProfileIcon from '../../icons/atomProfile';
// import Header from '../../components/Header';

const useStyles = makeStyles(styles);

const ProProfileMigration = ({ onMigrated }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('init');
  const { state, dispatch } = useProfileContext();
  const { postProfileAsync } = useMutatePostProfile();
  const { mutateAsync: patchProfileAsync } = useMutatePatchProfile();
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  // States: brand-new, new-to-redesign, no-pro-profile, starting-fresh, normal, migration-error
  // const [onboardingState] = React.useState('init');

  const next = (personaId) => {
    if (personaId === 'init') return;
    if (personaId === 'add-new') {
      const profileToAdd = {
        id: uuidv4(),
        index: 0,
        isProfessionalProfile: true,
        artistName:
          state.personalData?.firstName + ' ' + state.personalData?.lastName,
        type: 'solo',
        countryAlpha2: {
          value: state.personalData?.country,
        },
        dateOfBirth: {
          publishers: [],
          value: state.personalData?.dateOfBirth,
        },
      };
      postProfileAsync(profileToAdd)
        .then(() =>
          dispatch({
            type: 'ADD_TAB',
            payload: profileToAdd,
          })
        )
        .then(() => onMigrated())
        .then(() => toast.success('Profile has been updated'))
        .catch(() => toast.error('Error: failed to save profile'));
    } else {
      // actually selecting one
      const profilePatch = {
        id: personaId,
        index: 0,
        isProfessionalProfile: true,
        musicRoles: state.artistProfiles.find((ap) => ap.id === personaId)
          .features,
        features: {},
      };
      patchProfileAsync(profilePatch)
        .then(() =>
          dispatch({
            type: 'PATCH_PROFILE',
            payload: profilePatch,
          })
        )
        .then(() => onMigrated())
        .then(() => toast.success('Profile has been updated'))
        .catch(() => toast.error('Error: failed to save profile'));
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Typography className={classes.title} variant="h2">
          Assign your professional profile
        </Typography>
        <Paper className={classes.paper} elevation="0">
          <div>
            <Typography>
              Select which profile should relate to your legal identity. Any
              other profiles will be considered Artistic Projects.
            </Typography>
          </div>
          <ProfileTypes isOpen={isOpen} onClose={onClose} />
          <div>
            <div>
              <Box className={classes.personasSelector}>
                {state.artistProfiles.map((el) => (
                  <span key={el.id}>
                    {!el.isProfessionalProfile && (
                      <Box
                        className={classes.personas}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab(el.id);
                        }}
                        component="button"
                        backgroundColor={
                          activeTab !== el.id ? '#F7F8F9' : '#E3EEFA'
                        }
                      >
                        <div className={classes.personaName}>
                          {activeTab === el.id && (
                            <ProfileIcon className={classes.typeIcon} />
                          )}
                          {el.artistName}
                        </div>
                      </Box>
                    )}
                  </span>
                ))}
              </Box>
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={onOpen}
                className={classes.link}
              >
                <IconButton
                  disableRipple
                  variant="outlined"
                  aria-label="Help"
                  size="medium"
                >
                  <ToolTip
                    sx={{ height: '16px', width: '16px', color: '#5079A1' }}
                  />
                </IconButton>
                Learn about profile types
              </Typography>
            </div>
          </div>
        </Paper>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <CPButton onClick={() => next('add-new')} className={classes.none}>
              None of these are a professional profile
            </CPButton>
          </Grid>
          <Grid item>
            <Button
              className={classes.next}
              type="button"
              onClick={() => next(activeTab)}
            >
              The selected profile is my professional one
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProProfileMigration;
