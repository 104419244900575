import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CPEditButton from '../../components/CPEditButton';
import CPDeleteButton from '../../components/CPDeleteButton';
import CPMailButton from '../../components/CPMailButton';
import useRequestAccountDeletion from '../../hooks/useRequestAccountDeletion';
import { IconButton, Container, Paper, Box, TextField } from '@mui/material';
import { CgClose } from 'react-icons/cg';
import ChangePassword from '../../containers/modals/ChangePassword.js';
import useDisclosure from '../../hooks/useDisclosure';
import CPButton from '../../components/CPButton';
import { useAuth, useAuthManager } from '../../components/auth/AuthProvider';
import { useProfileContext } from '../../containers/passportData/context';
import toast from 'react-hot-toast';
import { postPassport } from '../../scripts';
import {
  validateAgainstSchema,
  versionNumbers,
  CURRENT_SCHEMA_VERSION,
} from '../../utils/schemaValidation';
import useServerProfile from '../../hooks/useServerProfile';
import get from 'lodash/get';
import { Auth } from 'aws-amplify';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '1rem',
    marginLeft: '4rem',
    marginRight: '4rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
  list: {
    flexGrow: 1,
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  title: {
    padding: '1rem',
  },
  text: {
    padding: '1rem',
    fontSize: 14,
    paddingBottom: theme.spacing(3),
  },
  deleteLink: {
    color: 'red',
  },
  deleteButton: {
    color: '#e60000',
    backgroundColor: '#ffcccc',
  },
  cancelButton: {
    backgroundColor: '#e6fffa',
  },
  dot: {
    height: '7px',
    width: '7px',
    backgroundColor: theme.palette.primary.hint,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '3px',
    marginLeft: '2px',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
});

const Settings = ({ classes, message }) => {
  const authManager = useAuthManager();
  const { user } = useAuth();
  const { profile } = useServerProfile();
  const [email, setEmail] = useState(user?.email || '');

  const onSubmit = async (event) => {
    event.preventDefault();

    // Extract passport data from profile
    const ProfileData = get(profile, 'PassportData');

    // Construct passport to submit
    const passportToSubmit = {
      email,
      artistProfiles: ProfileData.artistProfiles,
      personalData: ProfileData.personalData,
      version: versionNumbers[CURRENT_SCHEMA_VERSION],
    };

    const jwtToken = await authManager.getJwtToken().catch(() => null);

    // Validate passport against schema
    const { valid, error } = validateAgainstSchema(
      passportToSubmit,
      'passport'
    );
    if (!valid) {
      toast.error('Cannot save profile: invalid: ' + error);
      return;
    }

    try {
      // Post passport
      await postPassport(passportToSubmit, jwtToken);

      // Update email in AWS Cognito
      const cognitoAuth = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoAuth, { email });

      // Notify success and update user email
      toast.success('Email updated successfully');
      user.email = email;
      handleEmailDialogClose();
    } catch (error) {
      if (error.message.includes('AWS Cognito')) {
        toast.error(`Error updating the email in AWS Cognito ${error.message}`);
      } else {
        toast.error(`Error updating the email ${error.message}`);
      }
    }
  };

  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useDisclosure(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const { mutate: requestAccountDeletion } = useRequestAccountDeletion();
  const { dispatch } = useProfileContext();
  const history = useHistory();

  const handleShowDelete = () => {
    setDialogOpen(true);
  };
  const handleShowemail = () => {
    setEmailDialogOpen(true);
  };

  const handleConfirmDeleteAccount = async () => {
    setDialogOpen(false);
    await requestAccountDeletion();

    authManager.logout().then((success) => {
      if (success) {
        dispatch({
          type: 'POPULATE',
          payload: { artistProfiles: [] },
        });
        history.push('/signin');
      }
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleEmailDialogClose = () => {
    setEmailDialogOpen(false);
  };

  let DeleteAccountDialog = (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="cp-dialog-map-form"
      open={dialogOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        Delete Creative Passport Account?
      </DialogTitle>
      <DialogContent className={classes.text}>
        If you delete this account you will lose all your data
      </DialogContent>
      <DialogActions>
        <Button
          aria-label="close"
          onClick={handleDialogClose}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          aria-label="close"
          onClick={handleConfirmDeleteAccount}
          className={classes.deleteButton}
        >
          Delete my account
        </Button>
      </DialogActions>
    </Dialog>
  );

  let EmailAccountDialog = (
    <Dialog
      onClose={handleEmailDialogClose}
      aria-labelledby="cp-dialog-map-form"
      open={emailDialogOpen}
      fullWidth
    >
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              className={classes.inputField}
              placeholder="Beyoncé@example.com"
              variant="standard"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>

          <CPButton
            type="submit"
            variant="contained"
            style={{
              boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
              width: '100%',
              verticalAlign: 'middle',
              marginTop: '1em',
            }}
          >
            Set new Email
          </CPButton>
        </form>
        <IconButton
          className={classes.closeButton}
          onClick={handleEmailDialogClose}
        >
          <CgClose />
        </IconButton>
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={0}
              style={{
                padding: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography variant="h5">Email</Typography>

                <Typography>{user?.email}</Typography>
              </Box>
              {EmailAccountDialog}
              <CPEditButton title={message} onClick={handleShowemail} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={0}
              style={{
                padding: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <ChangePassword
                isOpen={isChangePasswordOpen}
                onClose={onChangePasswordClose}
              />
              <Box>
                <Typography variant="h5">Password</Typography>
                <Box>
                  <span className={classes.dot}></span>
                  <span className={classes.dot}></span>
                  <span className={classes.dot}></span>
                  <span className={classes.dot}></span>
                  <span className={classes.dot}></span>
                  <span className={classes.dot}></span>
                </Box>
              </Box>
              <CPEditButton title={message} onClick={onChangePasswordOpen} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={0}
              style={{
                padding: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography variant="h5">Contact Support</Typography>
                <Typography>
                  Email us and we'll direct your message to the right team.
                </Typography>
              </Box>
              <a href="mailto:tech@creativepassport.net" title={message}>
                <CPMailButton title={message} />
              </a>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={0}
              style={{
                padding: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography variant="h5">Delete Account</Typography>
                <Typography>
                  You can restore your data up to 30 days after deletion.
                </Typography>
              </Box>
              {DeleteAccountDialog}
              <CPDeleteButton title={message} onClick={handleShowDelete} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
