import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid } from '@mui/material';
import useServerProfile from '../../hooks/useServerProfile';
import useMutateServerProfile from '../../hooks/useMutateServerProfile';
import GoodSplitsCard from './GoodSplitsCard';
import PublicPageCard from './PublicPageCard';
import get from 'lodash/get';
import { setIn, stringToBool } from '../../utils/utils';
import { services } from '../../services';
import VevaCard from './veva/VevaCard';

const goodSplitsEnabled = stringToBool(
  process.env.REACT_APP_GOOD_SPLITS_ENABLED
);
const vevaEnabled = stringToBool(process.env.REACT_APP_VEVA_ENABLED);

const styles = (theme) => ({
  title: {
    fontSize: '12pt',
    fontWeight: 'bold',
  },
  integrations: {
    marginBottom: theme.spacing(2),
  },
  integration: {
    display: 'flex',
    alignItems: 'stretch',
  },
  shareOptions: {
    marginBottom: theme.spacing(2),
  },
});

function setProfileSharing(service, profile, sharing, serviceOptions) {
  let newProfile = profile;
  for (const { key, published } of sharing) {
    const oldPublished = get(profile, key + '.publishers', []);
    const enabled = oldPublished.includes(service);
    if (oldPublished === 'all' || published === enabled) {
      continue;
    }
    if (published) {
      newProfile = setIn(newProfile, key + '.publishers', [
        ...oldPublished,
        service,
      ]);
    } else {
      newProfile = setIn(
        newProfile,
        key + '.publishers',
        oldPublished.filter((p) => p !== service)
      );
    }
  }
  if (serviceOptions) {
    newProfile = {
      ...newProfile,
      enabledServices: newProfile.enabledServices.map((s) =>
        s.name === service ? { ...s, ...serviceOptions } : s
      ),
    };
  }
  return newProfile;
}

function setServiceTheme(service, profile, sharing) {
  for (const [index, value] of profile.enabledServices.entries()) {
    if (value.name === service) {
      let newProfile = {
        ...profile,
        enabledServices: [...profile.enabledServices],
      };
      newProfile.enabledServices[index] = {
        ...value,
        theme: sharing,
      };
      return newProfile;
    }
  }
  return profile;
}

const ServicesPage = ({ classes }) => {
  const {
    isLoading,
    profile: { PassportData: passportData, PassportId: passportId } = {},
  } = useServerProfile();
  const { mutate } = useMutateServerProfile();
  const servicesData = passportData?.services || {};

  const handleEnable = (service, artistId, sharing, serviceOptions) => {
    const newPassport = {
      ...passportData,
      artistProfiles: passportData.artistProfiles.map((p) =>
        p.id === artistId
          ? {
              ...setProfileSharing(service, p, sharing),
              enabledServices: [
                ...(p.enabledServices || []),
                { ...serviceOptions, name: service },
              ],
            }
          : p
      ),
    };
    mutate(newPassport);
  };

  const handleChangePermissions = (
    service,
    artistId,
    sharing,
    serviceOptions
  ) => {
    const newPassport = {
      ...passportData,
      artistProfiles: passportData.artistProfiles.map((p) =>
        p.id === artistId
          ? setProfileSharing(service, p, sharing, serviceOptions)
          : p
      ),
    };
    mutate(newPassport);
  };

  const handleChangeTheme = (service, artistId, sharing) => {
    const newPassport = {
      ...passportData,
      artistProfiles: passportData.artistProfiles.map((p) =>
        p.id === artistId ? setServiceTheme(service, p, sharing) : p
      ),
    };
    mutate(newPassport);
  };

  const handleDisable = (service, artistId) => {
    const newPassport = {
      ...passportData,
      artistProfiles: passportData.artistProfiles.map((p) =>
        p.id === artistId
          ? {
              ...p,
              enabledServices: (p.enabledServices || []).filter(
                (s) => s.name !== service
              ),
            }
          : p
      ),
    };
    mutate(newPassport);
  };
  let integrationCards = [];
  if (goodSplitsEnabled) {
    integrationCards.push(<GoodSplitsCard />);
  }
  if (vevaEnabled) {
    integrationCards.push(<VevaCard />);
  }

  return (
    <>
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          <Typography
            component="h1"
            variant="h6"
            className={classes.title}
            gutterBottom
          >
            Services
          </Typography>
          {integrationCards.length > 0 && (
            <Grid container className={classes.integrations} spacing={2}>
              {integrationCards.map((v, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={i}
                  className={classes.integration}
                >
                  {v}
                </Grid>
              ))}
            </Grid>
          )}
          <Typography
            component="h2"
            variant="h5"
            className={classes.artistName}
            gutterBottom
          >
            Public Pages
          </Typography>
          <Typography gutterBottom>
            Your public page is an open public webpage where you can display any
            of the data in your passport. Your Public Page link will be publicly
            available and linked to your name if you have joined the Music
            Makers Map.
          </Typography>
          <div className={classes.shareOptions}>
            {passportData.artistProfiles &&
            passportData.artistProfiles.length > 0 ? (
              <Grid container spacing={2}>
                {passportData.artistProfiles.map((p) => (
                  <Grid xs={12} sm={6} item key={p.id}>
                    <PublicPageCard
                      enabled={
                        (p.enabledServices || []).findIndex(
                          (s) => s.name === services.PUBLIC_PAGE
                        ) !== -1
                      }
                      settings={servicesData[services.PUBLIC_PAGE]}
                      passportId={passportId}
                      profile={p}
                      onEnable={(sharing, serviceOptions) => {
                        handleEnable(
                          services.PUBLIC_PAGE,
                          p.id,
                          sharing,
                          serviceOptions
                        );
                      }}
                      onChangePermissions={(sharing, serviceOptions) =>
                        handleChangePermissions(
                          services.PUBLIC_PAGE,
                          p.id,
                          sharing,
                          serviceOptions
                        )
                      }
                      onChangeTheme={(sharing) =>
                        handleChangeTheme(services.PUBLIC_PAGE, p.id, sharing)
                      }
                      onDisable={() =>
                        handleDisable(services.PUBLIC_PAGE, p.id)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>
                You must create a persona before you can enable your public
                page.
              </Typography>
            )}
          </div>
        </>
      )}
    </>
  );
};

ServicesPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ServicesPage);
