import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const propTypes = {
  message: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '1.5rem',
    height: 48,
    color: '#626466',
    fontSize: '12px',
    backgroundColor: theme.palette.background.paper,
    padding: '0 30px',
    [theme.breakpoints.up('md')]: {
      width: '320px',
    },
    boxShadow: '1px 2px 2px 2px rgb(0.08 0.08 0.08 / 0.1)',
  },
  customWidth: {
    maxWidth: 500,
  },
}));

const CPButton = ({
  message,
  variant = 'contained',
  placement = 'bottom',
  ...other
}) => {
  const classes = useStyles();
  const button = (
    <Button
      color="primary"
      className={classes.root}
      variant={variant}
      {...other}
      disableElevation
    />
  );
  if (message != null) {
    return (
      <Tooltip
        title={message}
        classes={{ tooltip: classes.customWidth }}
        placement={placement}
        arrow
      >
        {button}
      </Tooltip>
    );
  } else {
    return button;
  }
};

CPButton.propTypes = propTypes;

export default CPButton;
