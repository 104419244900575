import React from 'react';
import { SvgIcon } from '@mui/material';
import shortid from 'shortid';

function Icon({ hasGradient, sx, ...props }) {
  const { color } = props;
  const gradientId = shortid.generate();

  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? `url(#gradient-${gradientId})` : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-308.000000, -723.000000)"
          fill={color || '#626466'}
        >
          <g id="skills" transform="translate(308.000000, 723.000000)">
            <path
              d="M10,0 L9,1 L6,1 L5.001,2 L5.001,9 L3,9 L2,10 L1,11 L1,15.682 L1,16.061 L7,24 L9,24 L13.062,24 L17.766,24 L19,24 L23,17.002 L23,5 L21,3 L19,3 L19,2 L18,1 L15,1 L14.001,0 L10,0 Z M11,11 L11,10 L11,7 L11,2 L13,2 L13,7 L13,10 L13,11 L15,11 L15,10 L15,7 L15,3 L17,3 L17,7 L17,10 L17,11 L19,11 L19,10 L19,7 L19,5 L21,5 L21,7 L21,10 L21,12 L21,13.797 L21,16.469 L18.411,21 L8,21 L3,14.385 L3,11.829 L3.828,11 L5.001,11 L5.001,12 L5.001,14.4 L7,14.4 L7,13.797 L7,12 L7,10 L7,9 L7,7 L7,3 L9,3 L9,7 L9,10 L9,11 L11,11 Z"
              id="Fill-1"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
