import { useEffect, useState } from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import { Grid, IconButton, Box, TextField, Typography } from '@mui/material';
import TitleInfo from '../../../../components/Title';
import ToolTip from '../../../../icons/atomsTooltip.js';
import CPAddButton from '../../../../components/CPAddButton';
import CPDeleteButton from '../../../../components/CPDeleteButton';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';
import { useProfileContext } from '../../../passportData/context';
import RadioActive from '../../../../icons/atomsRadioActive';
import RadioInactive from '../../../../icons/atomsRadioEmpty';
import Button from '../../button';
import ArtisticProject from '../ArtisticProject';
import useDisclosure from '../../../../hooks/useDisclosure';
import ProfileTypes from '../../../modals/ProfileTypes.js';
import Aliases from '../../../modals/Aliases.js';

const useStyles = makeStyles(styles);

const YourProfile = ({ next, back, artistProfiles, personalData }) => {
  const theme = useTheme();
  const [tempProfiles, setTempProfiles] = useState(artistProfiles || []);
  const [hiddenProfiles, setHiddenProfiles] = useState([]);
  const [tempPersonalData, setTempPersonalData] = useState(personalData || {});
  const classes = useStyles();
  const { dispatch, state: passportState } = useProfileContext();
  const {
    isOpen: isProfileTypesOpen,
    onOpen: onProfileTypesOpen,
    onClose: onProfileTypesClose,
  } = useDisclosure(false);
  const {
    isOpen: isAliasesOpen,
    onOpen: onAliasesOpen,
    onClose: onAliasesClose,
  } = useDisclosure(false);

  useEffect(() => {
    // keep professional profile up to date with personal data
    setTempProfiles((prevProfiles) => {
      const hasTempProProfile = prevProfiles.some(
        (ap) => ap.isProfessionalProfile
      );
      if (!hasTempProProfile) {
        prevProfiles.unshift({
          id: uuidv4(),
          isProfessionalProfile: true,
          aliasShowBoth: true,
        });
      }
      return prevProfiles.map((ap) => {
        if (ap.isProfessionalProfile) {
          return {
            ...ap,
            artistName: `${tempPersonalData.firstName || ''} ${
              tempPersonalData.lastName || ''
            }`,
            gender: { value: tempPersonalData.gender || '' },
            countryAlpha2: { value: tempPersonalData.country || '' },
            dateOfBirth: { value: tempPersonalData.dateOfBirth || '' },
          };
        }
        return ap;
      });
    });
  }, [tempPersonalData]);

  const unhideArtisticProjects = () => {
    let profilesToUnhide = hiddenProfiles;
    if (!profilesToUnhide.length && tempProfiles.every((tp) => tp.artistName)) {
      profilesToUnhide = [
        { id: uuidv4(), isProfessionalProfile: false, type: 'band' },
      ];
    }

    setTempProfiles((prevProfiles) => [...prevProfiles, ...profilesToUnhide]);
    setHiddenProfiles([]);
  };

  const changePage = (options) => {
    if (options.save ?? true) {
      dispatch({
        type: 'POPULATE',
        payload: {
          ...passportState,
          artistProfiles: tempProfiles.filter(
            (ap) => ap.isProfessionalProfile || ap.artistName
          ),
          personalData: tempPersonalData,
        },
      });
    }
    if (options.reverse ?? false) {
      back();
    } else {
      next();
    }
  };

  const professionalProfile = tempProfiles.find(
    (ap) => ap.isProfessionalProfile
  );
  const hasArtisticProject = tempProfiles.some(
    (ap) => !ap.isProfessionalProfile
  );
  const displayBoth = professionalProfile?.aliasShowBoth ?? true;

  return (
    <>
      <ProfileTypes isOpen={isProfileTypesOpen} onClose={onProfileTypesClose} />
      <Aliases isOpen={isAliasesOpen} onClose={onAliasesClose} />
      <Grid mb={2} container justifyContent="space-between" direction="row">
        <Grid item>
          <TitleInfo title="Professional Profile" />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              cursor: 'pointer',
              [theme.breakpoints.only('xs')]: {
                marginRight: '1.5rem',
              },
            }}
            onClick={onAliasesOpen}
            className={classes.link}
          >
            <IconButton
              sx={{ height: '16px', width: '16px', marginRight: '0.5rem' }}
              disableRipple
              variant="outlined"
              aria-label="Help"
              size="medium"
            >
              <ToolTip
                sx={{ height: '16px', width: '16px', color: '#5079A1' }}
              />
            </IconButton>
            What is an alias name?
          </Typography>
        </Grid>
      </Grid>
      <Box mb={4} className={classes.professionalEditor}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          direction="row"
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="First name"
              className={classes.inputField}
              placeholder="Beyoncé"
              variant="standard"
              required
              onChange={(e) => {
                setTempPersonalData((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                }));
              }}
              defaultValue={personalData?.firstName || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last name"
              className={classes.inputField}
              placeholder="Knowles"
              variant="standard"
              required
              onChange={(e) =>
                setTempPersonalData((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                }))
              }
              defaultValue={personalData?.lastName || ''}
            />
          </Grid>
        </Grid>
        {professionalProfile?.aliases?.value?.length ? (
          <>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="space-between"
              direction="row"
            >
              <Grid item xs={10.5} sm={11} md={11.5}>
                <TextField
                  label="Alias"
                  className={classes.inputField}
                  placeholder="Beyoncé"
                  variant="standard"
                  defaultValue={
                    professionalProfile?.aliases?.value?.length
                      ? professionalProfile.aliases.value[0]
                      : ''
                  }
                  onChange={(e) =>
                    setTempProfiles((prevProfiles) =>
                      prevProfiles.map((ap) =>
                        ap.isProfessionalProfile
                          ? { ...ap, aliases: { value: [e.target.value] } }
                          : ap
                      )
                    )
                  }
                />
              </Grid>
              <Grid item>
                <CPDeleteButton
                  onClick={() => {
                    setTempProfiles((prevProfiles) =>
                      prevProfiles.map((ap) =>
                        ap.isProfessionalProfile
                          ? { ...ap, aliases: { value: [] } }
                          : ap
                      )
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              direction="row"
            >
              <Grid item xs={12} sm={6}>
                <Box
                  onClick={(e) =>
                    setTempProfiles((prevProfiles) =>
                      prevProfiles.map((ap) =>
                        ap.isProfessionalProfile
                          ? { ...ap, aliasShowBoth: true }
                          : ap
                      )
                    )
                  }
                  className={`${classes.alias} ${
                    displayBoth ? classes.aliasActive : ''
                  }`}
                >
                  {displayBoth ? <RadioActive /> : <RadioInactive />}
                  <Typography>Display alias with legal name</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  onClick={(e) =>
                    setTempProfiles((prevProfiles) =>
                      prevProfiles.map((ap) =>
                        ap.isProfessionalProfile
                          ? { ...ap, aliasShowBoth: false }
                          : ap
                      )
                    )
                  }
                  className={`${classes.alias} ${
                    !displayBoth ? classes.aliasActive : ''
                  }`}
                >
                  {!displayBoth ? <RadioActive /> : <RadioInactive />}
                  <Typography>Display alias only</Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <CPAddButton
            className={classes.add}
            onClick={() =>
              setTempProfiles((prevProfiles) =>
                prevProfiles.map((ap) =>
                  ap.isProfessionalProfile
                    ? {
                        ...ap,
                        aliases: { value: [''] },
                      }
                    : ap
                )
              )
            }
          >
            Add an Alias Name
          </CPAddButton>
        )}
      </Box>
      <Grid mb={2} container justifyContent="space-between" direction="row">
        <Grid item>
          <TitleInfo title="Artistic Profiles" />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              cursor: 'pointer',
              [theme.breakpoints.only('xs')]: {
                marginRight: '1.5rem',
              },
            }}
            onClick={onProfileTypesOpen}
            className={classes.link}
          >
            <IconButton
              sx={{ height: '16px', width: '16px', marginRight: '0.5rem' }}
              disableRipple
              variant="outlined"
              aria-label="Help"
              size="medium"
            >
              <ToolTip
                sx={{ height: '16px', width: '16px', color: '#5079A1' }}
              />
            </IconButton>
            What is an artistic profile?
          </Typography>
        </Grid>
      </Grid>
      <Box>
        {!hasArtisticProject && (
          <Box className={classes.artisticEditor}>
            <CPAddButton onClick={unhideArtisticProjects}>
              Add an Artistic Profile
            </CPAddButton>
          </Box>
        )}

        {hasArtisticProject && (
          <ArtisticProject
            setProfiles={setTempProfiles}
            profiles={tempProfiles}
          />
        )}
      </Box>
      <Box mt={5} className={classes.buttons}>
        <Button
          isBack
          width={{ xs: '45px', lg: '200px' }}
          type="button"
          onClick={() => changePage({ reverse: true })}
        >
          previous
        </Button>
        <div></div>
        {tempPersonalData?.firstName && tempPersonalData?.lastName ? (
          <Button onClick={() => changePage({})}>next</Button>
        ) : (
          <Button type="button" disabled>
            next
          </Button>
        )}
      </Box>
    </>
  );
};
export default YourProfile;
