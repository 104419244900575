import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#626466">
          <path d="M12.005 0l9 4.619v8.238L11.59 24l-.002-.002-.002.002L3 12.857V4.619l1.931-.991v.003L12.005 0zm0 2.248l-7 3.593v5.111l7.15 9.281 6.85-8.108V5.841l-7-3.593zM16.936 7l-5 7.66-5-7.66h10zm-3.694 2H10.63l1.306 2.001 1.306-2z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
