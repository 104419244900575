import { useQuery } from 'react-query';
import { getImage } from '../scripts';
import { useAuthManager } from '../components/auth/AuthProvider';

export const useFetchImage = (type, personaId) => {
  // type: ['profile', 'background']
  const authManager = useAuthManager();
  const profileImageQuery = useQuery(
    [type + 'Image', personaId],
    () =>
      authManager
        .getJwtToken()
        .then((jwtToken) => getImage(type, jwtToken, personaId)),
    {
      staleTime: 30000,
      retry: false,
    }
  );

  return {
    image: profileImageQuery.data?.url,
    thumbnail: profileImageQuery.data?.thumbnailUrl,
    isFetching: profileImageQuery.isFetching,
  };
};

export default useFetchImage;
