import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import styles from './styles';
import Check from '../../../icons/atomIconsTick';
import { Box, useMediaQuery } from '@mui/material';
import useDisclosure from '../../../hooks/useDisclosure';
import BasicModal from '../../../components/BasicModal';

const useStyles = makeStyles(styles);

const PAGES = ['your profiles', 'basic information', 'public pages'];

const Layout = ({
  page,
  title,
  description,
  link,
  linkText,
  children,
  editable,
  section,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isOpen, onClose } = useDisclosure(false);

  const calculateWidth = (index) => {
    if (index + 1 !== page + 1 && isMobile) return '38px';
    if (index + 1 === page + 1 && isMobile) return '38px';
    if (!isMobile) return '35%';
    return 'auto';
  };

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        onClose={onClose}
        title={linkText}
        section={section}
      />
      <div className={classes.root}>
        <div className={classes.main}>
          <Box mb={2} className={classes.navbar}>
            {PAGES.map((el, index) => (
              <React.Fragment key={el}>
                <div
                  className={
                    index + 1 === page
                      ? classes.navbarElActive
                      : classes.navbarEl
                  }
                  style={{ width: calculateWidth(index) }}
                >
                  <span className={classes.navbarElNum}>
                    {page < index + 1 ? (
                      index + 1
                    ) : (
                      <Check className={classes.check} />
                    )}
                  </span>
                  {/* Show page name if it's not on mobile */}
                  {!isMobile && <p className={classes.navbarElP}>{el}</p>}
                </div>
                {index + 1 !== PAGES.length && (
                  <div className={classes.navbarDiv}></div>
                )}
              </React.Fragment>
            ))}
          </Box>
          {/*
          <h1 className={classes.header}>{title}</h1>
          <div>
            <p className={classes.description}>{description}</p>
            {link && (
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={onOpen}
                className={classes.link}
              >
                {linkText}
              </Typography>
            )}
            {editable && (
              <p className={classes.edit}>
                You can always edit this information later
              </p>
            )}
          </div>
            */}
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
