import * as React from 'react';
import '../styles/PublicPage.css';
import PublicNavbar from '../components/PublicNavbar';
import InfoSection from '../components/InfoSection';
import ProfileInfo from '../components/ProfileInfo';
import { PublicProfileContextProvider } from './public-profile';
import PublicPageStylingOverride from '../themes/publicPageStylingOverride';
import { Container } from '@mui/material';
import { useAuth } from '../components/auth/AuthProvider';
import Header from '../components/Header';

export default function PublicPage() {
  const { authState } = useAuth();
  return (
    <PublicProfileContextProvider>
      <PublicPageStylingOverride>
        {authState === 'LOGGED_IN' ? (
          <Header signedIn={true} />
        ) : (
          <PublicNavbar />
        )}
        <Container>
          <InfoSection />
          <ProfileInfo />
        </Container>
      </PublicPageStylingOverride>
    </PublicProfileContextProvider>
  );
}
