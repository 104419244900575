import { createContext, useContext, useEffect, useState } from 'react';
import { getPublicProfile } from '../../scripts';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const publicProfileContext = createContext(null);

export const PublicProfileContextProvider = ({ children }) => {
  const { passportId, personaId } = useParams();
  const [publicProfileData, setPublicProfileData] = useState(null);
  useEffect(
    () =>
      getPublicProfile(passportId, personaId)
        .then((publicProfile) => setPublicProfileData(publicProfile))
        .catch((err) => toast.error('Could not load profile')),
    [passportId, personaId]
  );

  return (
    <publicProfileContext.Provider value={publicProfileData}>
      {children}
    </publicProfileContext.Provider>
  );
};

export const usePublicProfileContext = () => useContext(publicProfileContext);
