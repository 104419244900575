import { useMutation, useQueryClient } from 'react-query';
import { useAuthManager } from '../components/auth/AuthProvider';
import { cancelDeleteAccount } from '../scripts';

function useCancelAccountDeletion() {
  const queryClient = useQueryClient();
  const authManager = useAuthManager();

  return useMutation(
    () => {
      return authManager
        .getJwtToken()
        .then((jwtToken) => cancelDeleteAccount(jwtToken));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profileData');
      },
    }
  );
}

export default useCancelAccountDeletion;
