import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  DialogContent,
} from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { makeStyles } from '@mui/styles';
import CollectiveIcon from '../../icons/atomGroup';
import ProfileIcon from '../../icons/atomProfileDefault';
import PassportIcon from '../../icons/headerPassport';
import GuestIcon from '../../icons/atomGuest';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  proPanel: {
    backgroundColor: '#E3EEFA',
    padding: '1rem',
    borderRadius: '1rem',
  },
  artPanel: {
    backgroundColor: '#F7F8F9',
    padding: '1rem',
    borderRadius: '1rem',
  },
  panelTitle: {
    marginBottom: '1rem',
  },
  typeIcon: {
    marginRight: '6px',
    height: '16px',
  },
  refreshButton: {
    width: '25px',
    height: '25px',
    marginLeft: '3px',
  },
  refreshIcon: {
    width: '20px',
    height: '20px',
  },
  textWithIconStyle: {
    paddingRight: '30px',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '18px',
  },
  soon: {
    backgroundColor: '#FFD3E0',
    color: '#F0376E',
    borderRadius: '1rem',
    textAlign: 'center',
    float: 'left',
    position: 'relative',
    padding: '0rem 0.3rem',
  },
  new: {
    backgroundColor: '#D9F4ED',
    color: '#00A483',
    borderRadius: '1rem',
    textAlign: 'center',
    float: 'left',
    position: 'relative',
    padding: '0rem 0.3rem',
  },
}));

const AboutProfiles = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">Profiles</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Profiles are a digital version of music makers' identities. The
              Creative Passport helps you create them in a way that best
              represents you. Here's what you can do with them:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.heading}>
            <ProfileIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Professional Profile
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Create a professional profile related to your legal identity. Add
              your full legal name and information related to your career as a
              music professional under that name.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.heading}>
            <CollectiveIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Artistic Profiles
            </Typography>
            <Typography ml={1} className={classes.new}>
              New
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Build a profile for your bands, collective, orchestra, or any
              other project you're a part of. You can create as many as you
              like, and add unique data for each one.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.heading}>
            <PassportIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Public Profiles
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Decide which of your profiles are public or private, and become
              searchable in our portal of other music makers.{' '}
            </Typography>
          </Grid>
          <Grid item xs={9.5} sm={9.5} className={classes.heading}>
            <GuestIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Shared Profiles
            </Typography>
            <Typography ml={1} className={classes.soon}>
              Coming Soon
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Soon you'll be able to invite collaborators to Artistic Profiles,
              so you can manage one between multiple users.
            </Typography>
          </Grid>
        </Grid>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CgClose />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default AboutProfiles;
