import React, { useState } from 'react';
import { Box, Button, Typography, Dialog, DialogActions } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VerifyWithYoti from './VerifyWithYoti';
import VerifyIPN from './VerifyIPN';
import ppl_logo from '../../images/ppl-color.png';
import yoti_logo from '../../images/yoti-color.png';
import Tooltip from '../CPHelpMessage/index';
import CheckMark from '../../icons/checkMark';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem',
    borderRadius: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0rem',
    },
  },
  paper: {
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  yoti: {
    backgroundColor: theme.palette.background.dark,
    fontFamily: 'Roboto, Sans-serif',
    width: '100%',
    padding: '0.5rem',
    borderRadius: '8px',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    alignItems: 'left',
    backgroundColor: theme.palette.background.dark,
    fontSize: '12px',
    width: '100%',
    fontFamily: 'Roboto, Sans-serif',
    color: '#313233',
    borderRadius: '0.75rem',
    boxShadow: 'none',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'start',
  },
  verifiedbutton: {
    alignItems: 'left',
    backgroundColor: theme.palette.background.dark,
    fontSize: '12px',
    width: '100%',
    fontFamily: 'Roboto, Sans-serif',
    color: '#313233',
    borderRadius: '0.75rem',
    boxShadow: 'none',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  ppl_logo: {
    alignItems: 'left',
    width: '25px',
    height: '25px',
    marginRight: theme.spacing(1),
  },
  logo_button: {
    padding: '0.2rem',
    width: '25px',
    height: '25px',
  },
  logo_background: {
    backgroundColor: '#dfe2e9',
    marginRight: theme.spacing(1),
    borderRadius: '4px',
  },
  save: {
    boxShadow: '0px -15px 20px rgba(255, 255, 255, 1)',
    zIndex: '1',
    marginTop: 'auto',
    backgroundColor: '#EDF0F4',
  },
}));

export const Verify = ({ profile }) => {
  const classes = useStyles();

  let yotiVerified = profile?.verifiedFields
    ? Object.values(profile.verifiedFields).some((f) => f.source === 'Yoti')
    : false;

  let ipnVerified = profile?.verifiedFields.ipn != null;
  const [yotiOpen, setYotiOpen] = useState(false);
  const [ipnOpen, setIPNOpen] = useState(false);

  const showVerifyIPN = () => {
    setIPNOpen(true);
  };

  const handleYotiSection = () => {
    setYotiOpen(true);
  };

  const handleYotiClose = () => {
    setYotiOpen(false);
  };

  const handleIPNClose = () => {
    setIPNOpen(false);
  };

  return (
    <Box flexDirection="column" className={classes.root}>
      <Box>
        <Box className={classes.topHeader}>
          <Typography variant="h4">Verifications</Typography>
        </Box>
        <Typography
          data-tip
          data-for="verification"
          variant="help"
          sx={{ mt: '1rem', mb: '1rem' }}
        >
          <Tooltip
            message="Verifications are needed to create a safer community by ensuring trusted accounts are connected to each other and trustworthy."
            sx={{ paddingTop: '5px', marginLeft: '5px' }}
            title="Why should I verify?"
          />
        </Typography>
      </Box>
      <Box className={classes.paper}>
        {yotiVerified ? (
          <>
            <Button
              className={classes.verifiedbutton}
              onClick={handleYotiSection}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1.5,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.logo_background}>
                  <img
                    src={yoti_logo}
                    className={classes.logo_button}
                    alt="YOTI"
                  />
                </div>
                <p>Yoti Verified</p>
              </div>
              <CheckMark sx={{ maxWidth: '14.67px', maxHeight: '14.67px' }} />
            </Button>

            <Dialog open={yotiOpen}>
              <VerifyWithYoti />
              <DialogActions className={classes.save}>
                <Button onClick={handleYotiClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <>
            <Button
              className={classes.button}
              onClick={handleYotiSection}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1.5,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.logo_background}>
                  <img
                    src={yoti_logo}
                    className={classes.logo_button}
                    alt="YOTI"
                  />
                </div>
                <p>Verify with Yoti</p>
              </div>
            </Button>
            <Dialog open={yotiOpen}>
              <VerifyWithYoti />
              <DialogActions className={classes.save}>
                <Button onClick={handleYotiClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        )}

        {ipnVerified ? (
          <>
            <Button
              className={classes.verifiedbutton}
              onClick={showVerifyIPN}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.logo_background}>
                  <img
                    src={ppl_logo}
                    className={classes.logo_button}
                    alt="PPL"
                  />
                </div>
                <p>PPL Verified</p>
              </div>
              <CheckMark sx={{ maxWidth: '14.67px', maxHeight: '14.67px' }} />
            </Button>

            <Dialog open={ipnOpen}>
              <VerifyIPN />
              <DialogActions className={classes.save}>
                <Button onClick={handleIPNClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <>
            <Button className={classes.button} onClick={showVerifyIPN}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.logo_background}>
                  <img
                    src={ppl_logo}
                    className={classes.logo_button}
                    alt="PPL"
                  />
                </div>
                <p>Verify IPN with PPL</p>
              </div>
            </Button>
            <Dialog open={ipnOpen}>
              <VerifyIPN />
              <DialogActions className={classes.save}>
                <Button onClick={handleIPNClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Verify;
