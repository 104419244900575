import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import SkillsIcon from '../../icons/atomsSkills';
import LanguagesIcon from '../../icons/languages';
import RolesIcon from '../../icons/atomsRoles';
import InstrumentsIcon from '../../icons/instruments';
import ToolsAndGearIcon from '../../icons/toolsAndGear';
import InspirationsIcon from '../../icons/inspirations';
import FavoriteArtistsIcon from '../../icons/favoriteArtist';
import EndorsementIcon from '../../icons/endorsement';
import GenresIcon from '../../icons/genres';
import SupportedCauses from '../../icons/supportedCauses';
import QuotesIcon from '../../icons/quotes';
import FeaturesIcon from '../../icons/atomsFeatures';
import { usePublicProfileContext } from '../../containers/public-profile';
import { useTheme } from '@mui/material/styles';
import TitleInfo from '../Title';

const Profile = () => {
  const publicProfileData = usePublicProfileContext();
  const theme = useTheme();
  if (!publicProfileData) return null;
  if (
    !publicProfileData.musicRoles &&
    !publicProfileData.features &&
    !publicProfileData.otherSkills &&
    !publicProfileData.instrumentsPlayed &&
    !publicProfileData.instrumentsOwned &&
    !publicProfileData.toolsUsed &&
    !publicProfileData.toolsOwned &&
    !publicProfileData.favouriteMusicMakers &&
    !publicProfileData.endorsement &&
    !publicProfileData.genres &&
    !publicProfileData.spokenLanguages &&
    !publicProfileData.interestsInspiration &&
    !publicProfileData.charitiesSupported &&
    !publicProfileData.favouriteQuote
  )
    return null;
  return (
    <React.Fragment>
      {/* Public Profile Page Info Section */}
      <TitleInfo title="Profile Info" />
      <Grid
        mb={4}
        container
        gap={4}
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '1rem',
          [theme.breakpoints.down('sm')]: {
            borderRadius: '0rem',
          },
          p: 3,
        }}
      >
        {publicProfileData.isProfessionalProfile
          ? publicProfileData.musicRoles && (
              <InfoComponent
                title={'Roles'}
                skills={publicProfileData.musicRoles}
                Icon={RolesIcon}
              />
            )
          : publicProfileData.features && (
              <InfoComponent
                title={'Keywords'}
                skills={publicProfileData.features}
                Icon={FeaturesIcon}
              />
            )}
        {publicProfileData.otherSkills && (
          <InfoComponent
            title={'Skills'}
            skills={publicProfileData.otherSkills}
            Icon={SkillsIcon}
          />
        )}
        {publicProfileData.instrumentsPlayed && (
          <InfoComponent
            title={'Instruments Played'}
            skills={publicProfileData.instrumentsPlayed}
            Icon={InstrumentsIcon}
          />
        )}
        {publicProfileData.instrumentsOwned && (
          <InfoComponent
            title={'Instruments Owned'}
            skills={publicProfileData.instrumentsOwned}
            Icon={InstrumentsIcon}
          />
        )}
        {publicProfileData.toolsUsed && (
          <InfoComponent
            title={'Tools Used'}
            skills={publicProfileData.toolsUsed}
            Icon={ToolsAndGearIcon}
          />
        )}
        {publicProfileData.toolsOwned && (
          <InfoComponent
            title={'Tools Owned'}
            skills={publicProfileData.toolsOwned}
            Icon={ToolsAndGearIcon}
          />
        )}
        {publicProfileData.favouriteMusicMakers && (
          <InfoComponent
            title={'Favorite Artists'}
            skills={publicProfileData.favouriteMusicMakers}
            Icon={FavoriteArtistsIcon}
          />
        )}
        {publicProfileData.endorsement && (
          <InfoComponent
            title={'Endorsements'}
            skills={publicProfileData.endorsement}
            Icon={EndorsementIcon}
          />
        )}
        {publicProfileData.genres && (
          <InfoComponent
            title={'Genres'}
            skills={publicProfileData.genres}
            Icon={GenresIcon}
          />
        )}
        {publicProfileData.spokenLanguages && (
          <InfoComponent
            title={'Languages'}
            skills={publicProfileData.spokenLanguages}
            Icon={LanguagesIcon}
          />
        )}
        {publicProfileData.interestsInspiration && (
          <InfoComponent
            title={'Interests / Inspirations'}
            skills={publicProfileData.interestsInspiration}
            Icon={InspirationsIcon}
          />
        )}
        {publicProfileData.charitiesSupported && (
          <InfoComponent
            title={'Supported Causes'}
            skills={publicProfileData.charitiesSupported}
            Icon={SupportedCauses}
          />
        )}
        {publicProfileData.favouriteQuote && (
          <InfoComponent
            title={'Quotes'}
            skills={publicProfileData.favouriteQuote}
            Icon={QuotesIcon}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default Profile;

const InfoComponent = ({ title, skills, Icon }) => {
  const skill_array = typeof skills === 'string' ? [skills] : skills;
  const theme = useTheme();
  return (
    <Grid item>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
        }}
      >
        <Icon
          color={theme.palette.primary.hint}
          sx={{ mr: 1.5, width: '24px', height: '24px' }}
        />{' '}
        {title}
      </Typography>
      <Stack direction="row" rowGap={1} columnGap={1} flexWrap={'wrap'}>
        {skill_array.map((skill, i) => (
          <Typography
            key={skill}
            sx={{
              bgcolor: theme.palette.green.lighter,
              border: `2px solid ${theme.palette.green.main}`,
              color: theme.palette.green.contrastText,
              px: 2,
              py: 0.5,
              textTransform: 'uppercase',
              fontWeight: '500',
              fontSize: '0.8125rem',
              borderRadius: '0.25rem',
            }}
          >
            {skill}
          </Typography>
        ))}
      </Stack>
    </Grid>
  );
};
