import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  Button,
  Container,
  Paper,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material';
import YouTubeIcon from '../icons/logos/youtube.svg';
import DiscordIcon from '../icons/logos/discord.svg';
import TwitterIcon from '../icons/logos/twitter.svg';
import InstagramIcon from '../icons/logos/instagram.svg';
import { BsPlus } from 'react-icons/bs';
import FeedIcon from '@mui/icons-material/Feed';
import CPMailButton from '../components/CPMailButton';
import RightArrow from '../icons/arrowRight';
import IntegrationSuggestion from './modals/IntegrationSuggestion.js';
import useDisclosure from '../hooks/useDisclosure';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    marginLeft: '4rem',
    marginRight: '4rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
  title: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    width: '100%',
    padding: '1rem',
    margin: '0',
  },
  newsletter: {
    width: '470px',
    height: '250px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100vh',
    },
  },
  button: {
    backgroundColor: '#FFF',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
  socialButton: {
    borderRadius: '0.5rem',
    width: '32px',
    height: '32px',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    transition: '0.3s',
    '&:hover': {
      filter: 'brightness(80%)',
      WebkitFilter: 'brightness(80%)',
      transition: 'all 0.1s ease',
      WebkitTransition: 'all 0.1s ease',
    },
  },
}));

const GetInvolved = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleNewsLetterOpen = () => {
    setOpen(true);
  };

  const handleNewsLetterClose = () => {
    setOpen(false);
  };

  const {
    isOpen: isIntegrationSuggestionOpen,
    onOpen: onIntegrationSuggestionOpen,
    onClose: onIntegrationSuggestionClose,
  } = useDisclosure(false);

  const submissionForm = (
    <Dialog
      onClose={handleNewsLetterClose}
      aria-labelledby="cp-dialog-map-form"
      open={open}
    >
      <Box>
        <iframe
          title="Newsletter sign-up form"
          src="/sendInblue_form.html"
          className={classes.newsletter}
          frameBorder="0"
          id="sib_1"
        />
      </Box>

      <DialogActions>
        <Button aria-label="close" onClick={handleNewsLetterClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container>
      <IntegrationSuggestion
        isOpen={isIntegrationSuggestionOpen}
        onClose={onIntegrationSuggestionClose}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Paper
            elevation={0}
            style={{
              padding: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.blue.lighter,
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={8} sm={8}>
                <Typography variant="h5">
                  Join The Creative Passport community.
                </Typography>
                <Typography mr={1}>
                  Learn about new features, follow developement, access
                  resources, and connect with fellow creatives.
                </Typography>
              </Grid>

              <Grid item>
                <Grid container spacing={1.5}>
                  <Grid item>
                    <a
                      href="https://discord.gg/HrygquyfGB"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className={classes.socialButton}
                        src={DiscordIcon}
                        alt="Discord"
                      />
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://twitter.com/TheCreativePass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className={classes.socialButton}
                        src={TwitterIcon}
                        alt="X"
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://www.instagram.com/thecreativepass/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className={classes.socialButton}
                        src={InstagramIcon}
                        alt="X"
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://www.youtube.com/channel/UCBiRD9v1H1nhLHupE40NpPQ/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className={classes.socialButton}
                        src={YouTubeIcon}
                        alt="YouTube"
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={0}
            style={{
              padding: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h5">FAQ</Typography>
              <Typography mr={1}>
                We provide answers to some of the most frequently asked
                questions from the community.
              </Typography>
            </Box>
            <a
              href="https://www.creativepassport.net/faq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip arrow>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  className={classes.button}
                  size="medium"
                >
                  <RightArrow sx={{ fontSize: '16px' }} />
                </IconButton>
              </Tooltip>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={0}
            style={{
              padding: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h5">Suggest an integration</Typography>
              <Typography mr={1}>
                We're working to add more integrations, so if you dont find one
                you were looking for, let us know!
              </Typography>
            </Box>

            <Tooltip arrow onClick={onIntegrationSuggestionOpen}>
              <IconButton
                color="theme.palette.primary.hint"
                aria-label="menu"
                className={classes.button}
                size="small"
              >
                <BsPlus style={{ fontSize: '26px' }} />
              </IconButton>
            </Tooltip>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={0}
            style={{
              padding: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h5">Newsletter</Typography>
              <Typography mr={1}>
                Sign up to our newsletter to recieve occasional updates fromm
                The Creative Passport team, and news about the project.
              </Typography>
            </Box>
            <Tooltip arrow onClick={handleNewsLetterOpen}>
              <IconButton
                color="inherit"
                aria-label="menu"
                className={classes.button}
                size="medium"
              >
                <FeedIcon
                  sx={{
                    fontSize: '18px',
                    color: theme.palette.primary.hint,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={0}
            style={{
              padding: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h5">Get in touch</Typography>
              <Typography mr={1}>
                Want to get involved, or have a suggestion? Email us and we'll
                direct your message to the right team.
              </Typography>
            </Box>
            <a href="mailto:info@creativepassport.net">
              <CPMailButton />
            </a>
          </Paper>
        </Grid>
      </Grid>
      {submissionForm}
    </Container>
  );
};

export default GetInvolved;
