import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import { Grid, TextField, Typography, Box, Link } from '@mui/material';
import CPVisibleIcon from '../../CPVisibleIcon';
import CPVisibleToggle from '../../CPVisibleToggle';
import CPDeleteButton from '../../CPDeleteButton';
import { text_fields } from '../../../text_fields';

const useStyles = makeStyles(styles);

const officialLinksDisplayInfo =
  text_fields['official_links']['data_fields']['officialLinks'];

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
}

const logos_links = importAll(
  require.context('../../../icons/logos', false, /\.(png|jpe?g|svg)$/)
);

const absolutifyUrl = (userUrl) => {
  if (!/^https?:\/\//i.test(userUrl)) {
    return 'http://' + userUrl;
  }
  return userUrl;
};

const OfficialLink = ({
  linkData,
  editMode,
  onDelete,
  onDataChange,
  onPrivacy,
}) => {
  const classes = useStyles();

  let logoIcon = (
    <img
      src={logos_links['website.svg']}
      className={classes.icon}
      alt={linkData.fieldName}
      draggable="false"
    />
  );

  // TODO try get favicon.ico for custom links

  if (!linkData.custom && `${linkData.fieldName}.png` in logos_links) {
    logoIcon = (
      <img
        src={
          logos_links[`${linkData.fieldName}.svg`] ||
          logos_links[`${linkData.fieldName}.png`]
        }
        className={classes.icon}
        alt={linkData.fieldName}
        draggable="false"
      />
    );
  }

  const handleChange = (key, value) => {
    onDataChange({ ...linkData, [key]: value });
  };

  return (
    <>
      {editMode ? (
        <>
          {linkData.custom ? (
            <Box>
              <Box className={classes.editRoot}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Typography className={classes.editName} variant="h6">
                    Custom link
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <CPVisibleToggle
                    darker={true}
                    onClick={() => onPrivacy(linkData)}
                    dataField={linkData}
                  />
                  <CPDeleteButton
                    onClick={() =>
                      onDelete({
                        ...linkData,
                      })
                    }
                  />
                </Box>
              </Box>
              <TextField
                label="Website Label"
                fullWidth
                variant="standard"
                className={classes.editField}
                value={linkData.fieldName}
                name={linkData.fieldName}
                placeholder="E.g. Portfolio, Personal, Webstore"
                onChange={(e) => handleChange('fieldName', e.target.value)}
              />
              <TextField
                label="Website URL"
                fullWidth
                variant="standard"
                className={classes.editField}
                value={linkData.value}
                name={linkData.fieldName}
                placeholder="example.com"
                onChange={(e) => handleChange('value', e.target.value)}
              />
            </Box>
          ) : (
            <>
              <Box className={classes.editRoot}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  {logoIcon}
                  <Typography className={classes.editName} variant="h4">
                    {linkData?.fieldName &&
                    linkData.fieldName in officialLinksDisplayInfo
                      ? officialLinksDisplayInfo[linkData.fieldName].label
                      : linkData?.fieldName}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <CPVisibleToggle
                    darker={true}
                    onClick={() => onPrivacy(linkData)}
                    dataField={linkData}
                  />
                  <CPDeleteButton
                    onClick={() =>
                      onDelete({
                        ...linkData,
                      })
                    }
                  />
                </Box>
              </Box>
              <TextField
                label="Profile URL"
                fullWidth
                variant="standard"
                className={classes.editField}
                value={linkData.value}
                name={linkData.fieldName}
                placeholder="example.com/@thecreativepassport"
                onChange={(e) => handleChange('value', e.target.value)}
              />
            </>
          )}
        </>
      ) : (
        <Grid container>
          <Grid item className={classes.officialinktile}>
            <Link
              target="_blank"
              href={absolutifyUrl(linkData.value)}
              rel="noreferrer"
              className={classes.dashIcon}
            >
              {React.cloneElement(logoIcon, {
                style: {
                  width: '48px',
                },
              })}
            </Link>
            <Typography
              className={classes.infoLabel}
              sx={{ textAlign: 'center' }}
            >
              {linkData?.fieldName &&
              linkData.fieldName in officialLinksDisplayInfo
                ? officialLinksDisplayInfo[linkData.fieldName].label
                : linkData?.fieldName}
            </Typography>
            <Typography>{linkData.value?.value}</Typography>
            <CPVisibleIcon dataField={linkData} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OfficialLink;
