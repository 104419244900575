import React, { useState } from 'react';
import { DialogContent, Dialog, DialogActions, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './styles';
import Checkmark from '../../icons/tick';
import Button from '../Button';
import CPDeleteButton from '../CPDeleteButton';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      easing={{ enter: 'cubic-bezier(0, 1.3, .4, 1)', exit: 'linear' }}
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const SummaryEdit = ({
  handleSubmit,
  children,
  isOpen,
  handleCancel,
  onDelete,
  isLoading,
  isProfessionalProfile,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isDeleting, setIsDeleting] = useState(false);

  const startDelete = () => {
    setIsDeleting(true);
    onDelete();
  };

  return (
    <Dialog
      fullScreen={isSmallScreen ? true : false}
      open={isOpen}
      onClose={handleCancel}
      TransitionComponent={Transition}
    >
      <form
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        className={classes.form}
      >
        <DialogContent>{children}</DialogContent>
        <DialogActions className={classes.save}>
          {!isProfessionalProfile && (
            <>
              {isSmallScreen ? (
                <CPDeleteButton onClick={startDelete} />
              ) : isDeleting ? (
                <Button aria-label="close" className={classes.deleteButton}>
                  Deleting...
                </Button>
              ) : (
                <Button
                  aria-label="close"
                  onClick={startDelete}
                  className={classes.deleteButton}
                >
                  Delete project
                </Button>
              )}
            </>
          )}
          <Button
            size="large"
            className={classes.cancelButton}
            onClick={handleCancel}
            data-testid={`cancel-button`}
          >
            Cancel
          </Button>
          {isLoading ? (
            <Button
              size="large"
              className={classes.saveButton}
              data-testid="saving-button"
            >
              Saving...
            </Button>
          ) : (
            <Button
              size="large"
              type="submit"
              className={classes.saveButton}
              data-testid="save-button"
            >
              <Checkmark sx={{ fontSize: 12 }} />
              Save
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SummaryEdit;
