import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Verification</title>
      <defs>
        <linearGradient
          id="GradientActive"
          gradientUnits="userSpaceOnUse"
          x1="100%"
          y1="100%"
          x2="0%"
          y2="0%"
          gradientTransform="rotate(0)"
        >
          <stop offset="0%" stopColor="#FF2667" stopOpacity="1" />
          <stop offset="51%" stopColor="#73AEE6" stopOpacity="1" />
          <stop offset="100%" stopColor="#00FFCC" stopOpacity="1" />
        </linearGradient>
      </defs>
      <g
        id="PWA---UI"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-309.000000, -832.000000)"
          fill="#626466"
        >
          <g
            id="verification"
            transform="translate(309.000000, 832.000000)"
            fill="url(#GradientActive)"
          >
            <polygon
              id="Fill-1"
              points="6.443 0 1 5.443 0 6.443 0 15.557 6.443 22 15.556 22 22 15.557 22 6.443 21.991 6.434 20 8.426 20 14.727 15.727 19 7.443 19 2 13.556 2 7.272 7.272 2 14.728 2 16.896 4.168 8.112 12.953 5.707 10.548 4.293 11.963 8.829 16.5 19.736 5.593 19.735 4.18 19.49 3.933 15.556 0"
            ></polygon>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
