import React from 'react';
import { SvgIcon } from '@mui/material';
import shortid from 'shortid';

function Icon({ hasGradient, sx, ...props }) {
  const { color } = props;
  const gradientId = shortid.generate();

  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? `url(#gradient-${gradientId})` : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <title>Instruments</title>
      <g
        id="PWA---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-212.000000, -508.000000)"
          fill={color || '#626466'}
        >
          <g id="Fill-1" transform="translate(212.000000, 507.000000)">
            <path d="M2,1 L1.0125234e-13,3 L1.0125234e-13,21 L2,23 L8,23 L9,22 L10,23 L16,23 L17,22 L18,23 L24.001,23 L24.001,20 L18,20 L18,14 L19,13 L19,3 L24.001,3 L24.001,1 L2,1 Z M10,14 L11,13 L11,3 L15.001,3 L15.001,13 L16,14 L16,20 L10,20 L10,14 Z M3,3 L7,3 L7,13 L8,14 L8,20 L3,20 L3,3 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
