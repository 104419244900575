import React from 'react';
import { Box } from '@mui/system';
import Bio from '../Bio';
import Representatives from '../Representatives';
import Mediums from '../AllMedium';
import MediumsArtistic from '../AllMediumArtistic';
import PersonalInfo from '../PersonalInfo';
import OfficialLinks from '../OfficialLinks';
import IDs from '../IDs';
import Projects from '../Projects';
import TabSummary from '../TabSummary';

// import Milestones from '../Milestones';

const TabComponent = ({
  id,
  artistProfile,
  personalData,
  biography,
  representatives,
  officialLinks,
  isProfessionalProfile,
  onDelete,
  customIds,
  customProjects,
  isDeleting,
  isNew,
  onNoLongerNew,
  profile,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        gap: '2rem',
        mb: '3rem',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <TabSummary
        id={artistProfile.id}
        artistProfile={artistProfile}
        personalData={personalData}
        isProfessionalProfile={isProfessionalProfile}
        onDelete={onDelete}
        isDeleting={isDeleting}
        isNew={isNew}
        onNoLongerNew={onNoLongerNew}
      />
      {artistProfile.isProfessionalProfile ? (
        <Mediums artistProfile={artistProfile} profile={profile} />
      ) : (
        <MediumsArtistic artistProfile={artistProfile} />
      )}
      <PersonalInfo id={artistProfile.id} artistProfile={artistProfile} />
      <Bio id={artistProfile.id} data={biography} />
      {/* <Milestones id={id} data={[]} /> */}
      <Projects id={id} customProjects={customProjects} />
      <IDs artistProfile={artistProfile} />
      <Representatives artistProfile={artistProfile} />
      <OfficialLinks artistProfile={artistProfile} />
    </Box>
  );
};

export default TabComponent;
