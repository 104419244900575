import { Snackbar } from '@mui/material';
import React, { useCallback, useReducer } from 'react';
import Alert from '../Alert';

export const NotificationDispatchContext = React.createContext();

function notificationReducer(state, action) {
  switch (action.type) {
    case 'show': {
      // Received a new notification
      if (!state.active) {
        // No active notificaion, just show it
        return { ...state, active: action.payload };
      } else {
        // There is an active notification, so we add it to the queue
        return { ...state, queue: [...state.queue, action.payload] };
      }
    }
    case 'close': {
      // The notification is closing
      return { ...state, active: { ...state.active, open: false } };
    }
    case 'exit': {
      // The notification has finished closing
      if (state.queue.length > 0) {
        // Show the next one
        return {
          ...state,
          active: { ...state.queue[0] },
          queue: state.queue.splice(1),
        };
      } else {
        // No more notifications, just clear active
        return { ...state, active: undefined };
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function useNotification() {
  const dispatch = React.useContext(NotificationDispatchContext);
  if (dispatch === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  function showNotificationAction(message, severity = 'success') {
    dispatch({
      type: 'show',
      payload: { message, key: new Date().getTime(), severity, open: true },
    });
  }

  return showNotificationAction;
}

function NotificationProvider({ children }) {
  const [state, dispatch] = useReducer(notificationReducer, {
    active: undefined,
    queue: [],
  });
  const handleClose = useCallback(() => dispatch({ type: 'close' }), []);
  const handleExit = useCallback(() => dispatch({ type: 'exit' }), []);

  return (
    <NotificationDispatchContext.Provider value={dispatch}>
      {state.active && (
        <Snackbar
          key={state.active.key}
          open={state.active.open}
          autoHideDuration={2000}
          onClose={handleClose}
          TransitionProps={{
            onExited: handleExit,
          }}
        >
          <Alert onClose={handleClose} severity={state.active.severity}>
            {state.active.message}
          </Alert>
        </Snackbar>
      )}
      {children}
    </NotificationDispatchContext.Provider>
  );
}

export default NotificationProvider;
