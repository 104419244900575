const styles = (theme) => ({
  '@keyframes slideBgPositionOnLoad': {
    //ideally it would have backwards animation too
    '0%': {
      backgroundPosition: 'calc(((100vw - 4rem) / 4) * -1)', //kinda rough calculation
    },
    '100%': {
      backgroundPosition: '0px',
    },
  },

  root: {
    display: 'flex',
    minHeight: 'inherit',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
  },
  main: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1536px',
    },
    width: '100%',
    margin: '0rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      margin: '0rem 1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      margin: '0rem 0rem',
    },
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    [theme.breakpoints.only('sm')]: {
      margin: '1rem 0rem',
    },
    [theme.breakpoints.only('xs')]: {
      margin: '0rem 1rem',
    },
  },
  navbarEl: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    backgroundColor: theme.palette.background.dark,
    border: '2px solid #dee1e5',
    borderRadius: '0.75rem',
    height: '38px',
    minWidth: '38px',
    textTransform: 'capitalize',
    color: '#777777',
    [theme.breakpoints.down('md')]: {
      width: '38px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  navbarElActive: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: '38px',
    minWidth: '38px',
    textTransform: 'capitalize',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: '#313233',
    backgroundColor: '#fff',
    borderRadius: '0.75rem',
    fontSize: '14px',
    textStroke: '0.3px',
    WebkitTextStroke: '0.3px',
    strokeLinejoin: 'round',
    zIndex: 5,
    border: '2px solid transparent',
    [theme.breakpoints.only('xs')]: {
      background:
        'linear-gradient(315deg, #FF2667 0%, #73AEE6 49%, #00FFCC 100%) border-box',
    },

    [theme.breakpoints.only('sm')]: {
      background:
        'linear-gradient(315deg, #FF2667 0%, #73AEE6 49%, #00FFCC 100%) border-box',
    },

    [theme.breakpoints.up('md')]: {
      backgroundSize: '200% 1px',
      background:
        'linear-gradient(315deg, #dfe1e5 0%, #dfe1e5 40%, #FF2667 50%, #73AEE6 75%, #00FFCC 100%) border-box',
      transition: 'backgroundPosition 0.3s ease',
      backgroundPosition: '0px',
      animation: '$slideBgPositionOnLoad 0.3s forwards',
    },

    //'-webkit-mask':
    //  ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    //'-webkit-mask-composite': 'destination-out',
    //'mask-composite': 'exclude',
    '&::after': {
      content: "''",
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: -1,
      top: 0,
      left: 0,
      background: '#fff',
      borderRadius: '11px',
    },
    [theme.breakpoints.down('md')]: {
      width: '38px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  navbarElP: {
    width: 'calc(100% - 36px)',
    textAlign: 'center',
  },
  navbarElNum: {
    width: '18px',
    height: '18px',
    fontSize: '14px',
    fontWeight: '700',
    alignSelf: 'center',
    color: '#626466',
  },
  check: {
    width: '18px',
    fontSize: '18px',
    alignSelf: 'center',
    margin: '-0px -2px',
    [theme.breakpoints.down('md')]: {
      margin: '0px',
    },
  },
  navbarDiv: {
    backgroundColor: '#dbdbdb',
    height: '2px',
    [theme.breakpoints.up('md')]: {
      width: '12%',
    },
    width: '50%',
  },
  header: {
    fontSize: '24px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '15px',
  },
  link: {
    display: 'block',
    color: '#5079A1',
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
  },
  edit: {
    fontSize: '12px',
    color: '#313233',
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
});

export default styles;

export const buttonStyles = {
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '20px',
  },
  button: {
    display: 'flex',
    borderRadius: '20px',
    height: '40px',
    minWidth: '200px',
    border: '2px solid #a2a2a2',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    color: 'inherit',
    cursor: 'pointer',
  },
  buttonSolid: {
    backgroundColor: '#a2a2a2',
    color: 'white',
    fontWeight: 'bold',
  },
  buttonsNext: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  buttonsNextP: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#5079a1',
    marginRight: '20px',
  },
};
