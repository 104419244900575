import { Box, Input, Stack, Typography, Button } from '@mui/material';

const Confirm = ({ register, onCodeResend, onCancel }) => {
  return (
    <Box>
      <Typography variant="h2">Verification email sent</Typography>
      <Stack
        sx={{
          width: '100%',
          marginTop: '45px',
        }}
        spacing={{
          xs: '3rem',
          lg: '3rem',
        }}
      >
        <Input
          {...register('email')}
          type="email"
          placeholder="insert your email address"
        />
        <Input
          {...register('code')}
          id="single-factor-code-text-field"
          autocomplete="one-time-code"
          placeholder="insert verification code"
        />
        <Box>
          <Button
            sx={{
              width: '48%',
              height: '40px',
              backgroundColor: '#FFFFFF',
              color: '#000000',
              borderRadius: '28px',
              textTransform: 'capitalize',
              fontSize: '15px',
              boxShadow: '0px 0px 6px rgba(0,0,0,0.1)',
            }}
            size="small"
            variant="contained"
            type="button"
            onClick={onCodeResend}
          >
            Resend Code
          </Button>
          <Button
            sx={{
              marginLeft: '4%',
              width: '48%',
              height: '40px',
              backgroundColor: '#FFFFFF',
              color: '#000000',
              borderRadius: '28px',
              textTransform: 'capitalize',
              fontSize: '15px',
              boxShadow: '0px 0px 6px rgba(0,0,0,0.1)',
            }}
            size="small"
            variant="contained"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Confirm;
