import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip, Typography, Button, Box, Stack } from '@mui/material';
import shortid from 'shortid';
import styles from './styles';
import toast from 'react-hot-toast';
import Modal from '../ModalNoForm';
import IconTextButton from '../IconTextButton';
import Plus from '../../icons/atomsIconsAdd';
import { useMutatePatchProfile } from '../../hooks/useMutateServerProfile';
import { useProfileContext } from '../../containers/passportData/context';
import OfficialLink from './OfficialLink';
import OfficialLinkIcon from '../../icons/officialLink';
import { text_fields } from '../../text_fields';
import { toggleIsPublished } from '../../services.js';
import TitleInfo from '../Title';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles(styles);

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
}

const logos = importAll(
  require.context('../../icons/logos', false, /\.(png|jpe?g|svg)$/)
);

const OfficialLinks = ({ artistProfile }) => {
  const classes = useStyles();
  const { dispatch } = useProfileContext();
  const { mutateAsync, isLoading } = useMutatePatchProfile();
  const [editMode, setEditMode] = useState(false);
  const [insideData, setInsideData] = useState(
    artistProfile?.officialLinks?.value || []
  );

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }

    const newInsideData = Array.from(insideData);
    const [reorderedItem] = newInsideData.splice(source.index, 1);
    newInsideData.splice(destination.index, 0, reorderedItem);

    setInsideData(newInsideData);
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const handleSave = async () => {
    const payload = {
      id: artistProfile.id,
      officialLinks: {
        value: insideData,
      },
    };
    await mutateAsync(payload)
      .then(() =>
        dispatch({
          type: 'PATCH_PROFILE',
          payload,
        })
      )
      .then(() => {
        toast.success('Official links updated successfully');
        setEditMode(false);
      })
      .catch((err) => {
        const message = err.response?.data?.Error || err.message;
        toast.error(message);
      });
  };

  const handleAdd = (name) => {
    const custom = name === 'website';
    const newLink = {
      uniqueKey: shortid.generate(),
      fieldName: custom ? '' : name,
      value: '',
      publishers: [],
      custom: custom,
    };
    setInsideData((prevState) => [newLink, ...prevState]);
  };

  const handleDataChange = (item) => {
    setInsideData((prevState) => [
      ...prevState.map((el) => {
        if (el.uniqueKey === item.uniqueKey) {
          return item;
        }
        return el;
      }),
    ]);
  };

  const handleDelete = (item) => {
    const filteredAry = insideData.filter(
      (el) => el.uniqueKey !== item.uniqueKey
    );
    setInsideData(filteredAry);
  };

  // handle privacy
  const handlePrivacy = (item) => {
    setInsideData((prevState) =>
      prevState.map((el) => {
        if (el.uniqueKey === item.uniqueKey) {
          return {
            ...el,
            publishers: toggleIsPublished(el),
          };
        }
        return el;
      })
    );
  };

  const officialLinksList = Object.keys(
    text_fields['official_links']['data_fields']['officialLinks']
  );

  const buttons = officialLinksList.map((name) => {
    let icon = (
      <img
        src={logos[`${name}.svg`] || logos[`${name}.png`]}
        className={classes.icon}
        alt={name}
        draggable="false"
      />
    );

    let label =
      text_fields['official_links']['data_fields']['officialLinks'][name].label;

    return (
      <Tooltip title={label} classes={{ tooltip: classes.customWidth }} arrow>
        <Button
          data-testid={name}
          key={name}
          onClick={() => handleAdd(name)}
          value={name}
          className={classes.button}
        >
          {icon}
        </Button>
      </Tooltip>
    );
  });

  return (
    <div className={classes.root}>
      {/** DISPLAY MODE */}
      <TitleInfo
        title="Official Links"
        isNotEmpty={insideData?.length > 0}
        message="Edit Official Links"
        onClick={() => setEditMode(true)}
      />
      {!insideData || insideData.length === 0 ? (
        <Box className={classes.empty}>
          <Stack
            gap={4}
            direction="column"
            alignItems="center"
            justifyContent="center"
            height="250px"
          >
            <OfficialLinkIcon sx={{ width: '32px' }} />
            <Typography variant="body1">
              Make sure your official links are always available and up to date
            </Typography>
            <IconTextButton
              onClick={() => setEditMode(true)}
              size="standard"
              icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
            >
              Add your official links
            </IconTextButton>
          </Stack>
        </Box>
      ) : (
        <Box className={classes.items}>
          {insideData.map((item) => (
            <div key={item.uniqueKey}>
              <OfficialLink
                linkData={item}
                editMode={false}
                onDataChange={handleDataChange}
                onDelete={handleDelete}
                onPrivacy={handlePrivacy}
              />
            </div>
          ))}
        </Box>
      )}
      {/** EDIT MODE */}
      <Modal
        onSave={() => handleSave()}
        open={editMode}
        onClose={handleClose}
        isLoading={isLoading}
      >
        <div>
          <div className={classes.buttonBed}>
            <div className={classes.buttonContainer}>{buttons}</div>
          </div>
          {insideData && insideData.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {insideData.map((item, index) => (
                      <Draggable
                        key={item.uniqueKey}
                        draggableId={item.uniqueKey}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className={classes.linkpanel}>
                              <OfficialLink
                                linkData={item}
                                onDelete={handleDelete}
                                onDataChange={handleDataChange}
                                editMode={true}
                                onPrivacy={handlePrivacy}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default OfficialLinks;
