import React from 'react';
import { SvgIcon } from '@mui/material';
import shortid from 'shortid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = shortid.generate();
  return (
    <SvgIcon
      sx={{
        ...sx,
        '&': {
          g: {
            fill: hasGradient ? `url(#gradient-${gradientId})` : '',
          },
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={hasGradient ? null : '#626466'}>
          <path d="M11 2L11 11 2 11 2 13 11 13 11 22 13 22 13 13 22 13 22 11 13 11 13 2z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
