import React from 'react';
import { Input, Stack, Box, Link, Typography } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import { MyCheckbox } from '../register';
const Login = ({ register }) => {
  const [keepMeLoggedIn, setKeepMeLoggedIn] = React.useState(false);
  return (
    <Box>
      <Stack
        sx={{
          width: '100%',
          marginTop: '45px',
        }}
        spacing={{
          xs: '48px',
          lg: '48px',
        }}
      >
        <Input
          {...register('email')}
          type="email"
          placeholder="your email address"
        />
        <Box>
          <Input
            sx={{
              display: 'block',
            }}
            {...register('password')}
            type="password"
            placeholder="your password"
          />
          <Stack direction="row" alignItems="center" spacing="1px">
            <Stack
              direction="row"
              alignItems="center"
              spacing="7px"
              sx={{
                borderRadius: '8px',
                padding: '8px 10px 8px 8px',
                backgroundColor: 'rgba(247,248,249, 1)',
                marginTop: { xs: '45px', lg: '35px' },
              }}
            >
              <MyCheckbox
                onChange={(e) => setKeepMeLoggedIn(e.target.checked)}
                value={keepMeLoggedIn}
              />
              <Typography
                fontSize={{
                  xs: '14px',
                  lg: '14px',
                  whiteSpace: 'nowrap',
                }}
              >
                Keep me logged in
              </Typography>
            </Stack>
            <Link
              sx={{
                textAlign: 'right',
                width: '100%',
                display: 'block',
                marginTop: {
                  xs: '45px!important',
                  md: '45px!important',
                  lg: '35px!important',
                },
              }}
              component={ReactLink}
              to="/reset_password"
            >
              Forgot your password?
            </Link>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default Login;
