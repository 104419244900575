import { Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Delete from '../../../icons/atomsIconsDelete';
import Tooltip from '../../CPHelpMessage/index';
import CPVisibleToggle from '../../CPVisibleToggle';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const EditID = ({
  id,
  type,
  value,
  handleChange,
  onDelete,
  isCustom,
  setIsShow,
  dataField,
  message,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{
        backgroundColor: '#F7F8F9',
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Stack
        alignItems="start"
        direction="row"
        justifyContent="space-between"
        sx={isCustom ? { cursor: cursor } : {}}
      >
        {!isCustom ? (
          <Stack direction="row" spacing={0.7} alignItems="center">
            <Typography
              sx={{
                color: '#626466',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {type}
            </Typography>
            <Tooltip
              message={message}
              sx={{ paddingTop: '5px', marginLeft: '5px' }}
            />
          </Stack>
        ) : (
          <Stack>
            <Typography
              sx={{
                lineHeight: '32px',
                color: '#626466',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Custom ID
            </Typography>

            <TextField
              variant="standard"
              label={isCustom ? 'ID Label' : 'Name'}
              type="text"
              value={type}
              onChange={(e) => handleChange(e, { isType: true })}
              placeholder={isCustom ? 'ID Label' : ''}
              sx={{
                width: '100%',
                mt: 1,
              }}
            />
          </Stack>
        )}

        <Stack direction="row" spacing={1.5} alignItems="center">
          <CPVisibleToggle
            darker={true}
            onClick={() => setIsShow()}
            dataField={dataField}
          />
          <Box
            sx={{
              backgroundColor: 'white',
              boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
              height: '35px',
              width: '35px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}
            onClick={() => onDelete(type)}
          >
            <Delete
              sx={{
                fontSize: '40px',
              }}
            />
          </Box>
        </Stack>
      </Stack>

      <TextField
        type="text"
        label={isCustom ? 'ID Code' : ''}
        variant="standard"
        value={value}
        onChange={(e) => handleChange(e)}
        placeholder={isCustom ? 'ID Code' : 'E.g 0000 0000 0000 0000'}
        sx={{
          width: '100%',
          mt: 3,
        }}
        disabled={!type}
      />
    </Box>
  );
};
