import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useAuthManager } from '../../components/auth/AuthProvider';
import { useHistory, useLocation } from 'react-router';
import withRedirectOnNotAuth from '../../hocs/withRedirectOnNotAuth';

import { getIPNNumber } from '../../scripts';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useVerifyPPL() {
  const authManager = useAuthManager();
  return useMutation((pplToken) =>
    authManager
      .getJwtToken()
      .then((jwtToken) => getIPNNumber(pplToken, jwtToken))
  );
}

function PPLCallback() {
  const history = useHistory();
  const search = useQuery();
  const { mutateAsync: verifyPPL } = useVerifyPPL();
  const pplToken = search.get('token');
  useEffect(() => {
    verifyPPL(pplToken)
      .then(() => history.push('/'))
      .catch((err) => {
        console.error(err);
        history.push('/');
      });
  }, [pplToken, verifyPPL, history]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <Typography component="h1" variant="h5">
          Verifying with PPL, please wait...
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withRedirectOnNotAuth(PPLCallback);
