import { useMutation } from 'react-query';
import { useAuthManager } from '../components/auth/AuthProvider';
import { getYOTIData } from '../scripts';

function useVerifyYoti() {
  const authManager = useAuthManager();
  return useMutation((yotiToken) =>
    authManager
      .getJwtToken()
      .then((jwtToken) => getYOTIData(yotiToken, jwtToken))
  );
}

export default useVerifyYoti;
