import { Grid, Box, Stack, Typography, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CPAddButton from '../../../../components/CPAddButton';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';
import AtomSoloProject from '../../../../icons/atomSoloProject';
import AtomBand from '../../../../icons/atomDrum';
import AtomOrchestra from '../../../../icons/atomOrchestra';
import AtomGroup from '../../../../icons/atomGroup';
import Puzzle from '../../../../icons/atomPuzzle';
import CPDeleteButton from '../../../../components/CPDeleteButton';

const useStyles = makeStyles(styles);

const ArtisticProject = ({ profiles, setProfiles }) => {
  const classes = useStyles();

  const artisticProjects = profiles.filter((ap) => !ap.isProfessionalProfile);

  const projectTypes = [
    {
      name: 'solo',
      nice_name: 'Solo project',
      icon: <AtomSoloProject sx={{ fontSize: '24px' }} />,
      iconActive: <AtomSoloProject hasGradient={true} />,
    },
    {
      name: 'band',
      nice_name: 'Band',
      icon: <AtomBand sx={{ fontSize: '24px' }} />,
      iconActive: <AtomBand hasGradient={true} />,
    },
    {
      name: 'orchestra',
      nice_name: 'Orchestra',
      icon: <AtomOrchestra sx={{ fontSize: '24px' }} />,
      iconActive: <AtomOrchestra hasGradient={true} />,
    },
    {
      name: 'collective',
      nice_name: 'Collective',
      icon: <AtomGroup sx={{ fontSize: '24px' }} />,
      iconActive: <AtomGroup hasGradient={true} />,
    },
    {
      name: 'other',
      nice_name: 'Other',
      icon: <Puzzle sx={{ fontSize: '24px' }} />,
      iconActive: <Puzzle hasGradient={true} />,
    },
  ];

  const addProject = () => {
    const idx = uuidv4();
    setProfiles((prevProfiles) => [
      ...prevProfiles,
      {
        id: idx,
        isProfessionalProfile: false,
        type: 'band',
      },
    ]);
  };

  const removeProject = (project) => {
    setProfiles((prevProfiles) =>
      prevProfiles.filter((ap) => ap.id !== project.id)
    );
  };

  const handleType = (type, profileToChange) => {
    setProfiles((prevProfiles) => {
      const returnArray = prevProfiles.map((ap) =>
        ap.id === profileToChange.id ? { ...profileToChange, type } : ap
      );
      return returnArray;
    });
  };

  return (
    <div>
      <Stack mb={3} spacing={3} direction="column">
        {profiles
          .filter((ap) => !ap.isProfessionalProfile)
          .map((artistProfile) => (
            <Box
              className={classes.root}
              key={artistProfile.id}
              p={2}
              backgroundColor="#f7f8f9"
              borderRadius="10px"
              sx={{
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '37px',
                  right: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CPDeleteButton onClick={() => removeProject(artistProfile)} />
              </Box>
              <Grid container direction="row" className={classes.types}>
                {projectTypes.map((p) => (
                  <>
                    <Grid
                      item
                      component="button"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={1}
                      py={1}
                      mx="0.25rem"
                      px={1.5}
                      backgroundColor={
                        artistProfile.type === p.name
                          ? '#f7f8f9'
                          : 'transparent'
                      }
                      color={
                        artistProfile.type === p.name ? '#ff31323' : '#626466'
                      }
                      borderRadius="12px"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#f7f8f9',
                        },
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleType(p.name, artistProfile);
                      }}
                    >
                      {artistProfile.type === p.name ? (
                        <>{p.iconActive}</>
                      ) : (
                        <>{p.icon}</>
                      )}
                      <Typography>{p.nice_name}</Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
              <Box>
                <TextField
                  label="Profile Name"
                  required
                  placeholder="Destiny's Child"
                  sx={{ width: '100%' }}
                  variant="standard"
                  defaultValue={artistProfile.artistName}
                  onChange={(e) => {
                    setProfiles((prevProfiles) =>
                      prevProfiles.map((ap) =>
                        ap.id === artistProfile.id
                          ? { ...ap, artistName: e.target.value }
                          : ap
                      )
                    );
                  }}
                />
              </Box>
            </Box>
          ))}
      </Stack>
      <Box className={classes.root}>
        <CPAddButton className={classes.add} onClick={() => addProject()}>
          {artisticProjects.length
            ? 'Add another Artistic Profile'
            : 'Add an Artistic Profile'}
        </CPAddButton>
      </Box>
    </div>
  );
};

export default ArtisticProject;
