import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 26 26"
      {...props}
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="100%"
          x2=".091%"
          y1="100%"
          y2=".091%"
        >
          <stop offset=".091%" stopColor="#FF2667"></stop>
          <stop offset="50.981%" stopColor="#73AEE6"></stop>
          <stop offset="100%" stopColor="#0FC"></stop>
        </linearGradient>
        <circle id="path-2" cx="12" cy="12" r="6"></circle>
        <filter
          id="filter-3"
          width="266.7%"
          height="266.7%"
          x="-66.7%"
          y="-66.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="2"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-640 -495)">
          <g transform="translate(628 487)">
            <g transform="translate(12 8)">
              <circle
                cx="12"
                cy="12"
                r="12"
                fill="url(#linearGradient-1)"
              ></circle>
              <g>
                <use
                  fill="#000"
                  filter="url(#filter-3)"
                  xlinkHref="#path-2"
                ></use>
                <use fill="#FFF" xlinkHref="#path-2"></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
