import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Box,
  Stack,
  Typography,
  Link as MuiLink,
  IconButton,
} from '@mui/material';
import { TbRefreshAlert } from 'react-icons/tb';
import Login from './login';
import Register from './register';
import Confirm from './confirm';
import AtomsIconsPlay from '../../icons/atomsIconsRarr';
import Logo from '../../icons/cpLogoWithTextWhite';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  useAuthManager,
  useSetUnconfirmedUser,
} from '../../components/auth/AuthProvider';
import { toast } from 'react-hot-toast';
import { BiLoaderAlt } from 'react-icons/bi';
import useDisclosure from '../../hooks/useDisclosure';
import AboutCreativePassport from '../../containers/modals/AboutCreativePassport.js';

const activeButtonStyle = {
  fontWeight: '500',
  color: 'rgba(49, 50, 51, 1)',
  backgroundColor: 'rgba(255,255,255, 1)',
  transition: '0.3s',
};

const passiveButtonStyle = {
  backgroundColor: 'transparent',
  color: '#fff',
  fontWeight: '400',
};

const AuthPage = () => {
  const {
    isOpen: isAboutCreativePassportOpen,
    onOpen: onAboutCreativePassportOpen,
    onClose: onAboutCreativePassportClose,
  } = useDisclosure(false);
  const authManager = useAuthManager();
  const [pageState, setPageState] = useState('');
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const { register, handleSubmit, watch } = useForm();
  const history = useHistory();
  const match = useRouteMatch();
  const setUnconfirmedUser = useSetUnconfirmedUser();

  const email = watch('email', '');
  const password = watch('password', '');

  useEffect(() => {
    if (match.url === '/signin') {
      setPageState('SIGN_IN');
    } else {
      setPageState('SIGN_UP');
    }
  }, [match]);

  const onCodeResend = () => {
    Auth.resendSignUp(email.toLowerCase())
      .then(() => {
        toast.success('Code Resent');
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const onCancel = () => {
    setPageState('SIGN_IN');
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (pageState === 'SIGN_IN') {
      authManager
        .login(data.email, data.password)
        .then(() => {
          toast.success('Successful Sign In');
          history.push('/');
        })
        .catch((error) => {
          if (error && error.code === 'UserNotConfirmedException') {
            setUnconfirmedUser(data.email);
            setPageState('CONFIRM');
          } else {
            toast.error(error.message);
          }
        })
        .finally(() => setLoading(false));
    } else if (pageState === 'SIGN_UP') {
      if (agree) {
        Auth.signUp({
          username: data.email.toLowerCase(),
          password: data.password,
        })
          .then((action) => {
            setUnconfirmedUser(action.user.username);
            setPageState('CONFIRM');
          })
          .catch((error) => {
            toast.error(error.message);
          })
          .finally(() => setLoading(false));
      } else {
        toast.error("You need to accept Creative Passport's privacy policy");
        setLoading(false);
      }
    } else {
      Auth.confirmSignUp(data.email.toLowerCase(), data.code)
        .then((user) => {
          toast.success('Your confirmation code was correct');
          setPageState('SIGN_IN');
        })
        .catch((err) => {
          toast.error(err.message);
          if (!err.message) {
            console.error('Error when entering confirmation code: ', err);
          } else {
            console.error(
              'Error when entering confirmation code: ',
              err.message
            );
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Box
      sx={{
        height: { xs: '', sm: '', md: '100vh', lg: '100vh' },
        minheight: { xs: '', sm: '', md: '768px', lg: '768px' },
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AboutCreativePassport
        isOpen={isAboutCreativePassportOpen}
        onClose={onAboutCreativePassportClose}
      />
      <Box
        sx={{
          height: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          borderRadius: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
          padding: {
            xs: '0px',
            sm: '20px',
            md: '20px',
            lg: '20px',
          },
          backgroundImage: {
            xs: 'linear-gradient(328deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
            m: 'linear-gradient(328deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
            md: 'linear-gradient(-54deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
            lg: 'linear-gradient(-54deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
          },
          boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
          '& > div': {
            height: {
              xs: 'auto',
              sm: 'auto',
              md: '100%',
              lg: '100%',
            },
            width: {
              xs: '100%',
              sm: '512px',
              md: '100%',
              lg: '50%',
            },
          },
        }}
      >
        {/* Logo Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: {
              xs: '30%',
              sm: '30%',
              md: '100%',
              lg: '100%',
            },
          }}
        >
          <Logo
            sx={{
              fontSize: '180px',
              width: '256px',
              margin: {
                xs: '32px',
                sm: '32px',
              },
              height: 'auto',
            }}
          />
          <Typography
            marginTop={{ md: '70px', lg: '100px' }}
            marginBottom={{ md: '70px', lg: '100px' }}
            color="white"
            textAlign="center"
            variant="h2"
            display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}
          >
            Empowering the music <br /> makers community
          </Typography>
          {/* Footer Link Desktop */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MuiLink
              sx={{
                transition: '0.3s',
                color: 'white',
                fontSize: '14px',
                textDecorationColor: 'rgba(255,255,255,0.5)!important',
                '&:hover': {
                  textDecorationColor: 'rgba(255,255,255,1)!important',
                },
                textDecoration: 'underline',
                display: { xs: 'none', sm: 'none', md: 'inline', lg: 'inline' },
              }}
              onClick={onAboutCreativePassportOpen}
            >
              About the project
            </MuiLink>
            <Typography
              sx={{
                color: 'white',
                fontSize: '14px',
                margin: '0 5px',
                opacity: '0.5',
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'inline',
                  lg: 'inline',
                },
              }}
            >
              |
            </Typography>
            <a href="mailto:support@creativepassport.net">
              <MuiLink
                sx={{
                  transition: '0.3s',
                  color: 'white',
                  fontSize: '14px',
                  textDecorationColor: 'rgba(255,255,255,0.5)!important',
                  '&:hover': {
                    textDecorationColor: 'rgba(255,255,255,1)!important',
                  },
                  textDecoration: 'underline',
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'inline',
                    lg: 'inline',
                  },
                }}
              >
                Trouble logging in?
              </MuiLink>
            </a>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '0.9rem',
              backgroundColor: '#ffffff90',
              borderRadius: '0.75rem',
              width: { xs: 'calc(100% - 2rem)', sm: '100%', md: '256px' },
            }}
          >
            <IconButton
              disableRipple
              size="small"
              sx={{
                cursor: 'default',
                backgroundColor: '#FFD4E1',
                alignSelf: 'flex-start',
                '&:hover': {
                  backgroundColor: '#FFD4E1',
                },
              }}
            >
              <TbRefreshAlert color="#FF3575" />
            </IconButton>
            <Box sx={{ flexGrow: 1, padding: '10px' }}>
              <Typography variant="h5">Migrating from our old app?</Typography>
              <Typography variant="subtitle1">
                Login with your email and password, and begin migrating your
                profiles. Any new data after 01 Nov 2023 will not be migrated.
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Account Section */}
        <Box
          sx={{
            paddingLeft: {
              xs: '0px',
              sm: '0px',
              md: '0px',
              lg: '40px',
            },
            paddingRight: {
              xs: '0px',
              sm: '0px',
              md: '20px',
              lg: '40px',
            },
            height: {
              lg: '80%!important',
              md: '80%!important',
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              mb: '16px',
              paddingX: {
                xs: '15px',
                sm: '0px',
                md: '0px',
                lg: '0px',
              },
              button: {
                width: '48.6%',
                height: '40px',
                borderRadius: '13px',
                cursor: 'pointer',
                fontSize: {
                  xs: '15px',
                  sm: '15px',
                  md: '15px',
                  lg: '15px',
                },
              },
            }}
          >
            <Box
              sx={{
                '&:hover': activeButtonStyle,
                ...(pageState === 'SIGN_UP'
                  ? activeButtonStyle
                  : passiveButtonStyle),
              }}
              component="button"
              type="button"
              onClick={() => {
                setPageState('SIGN_UP');
                history.push('/register');
              }}
            >
              Sign up
            </Box>
            <Box
              sx={{
                '&:hover': activeButtonStyle,
                ...(pageState === 'SIGN_IN'
                  ? activeButtonStyle
                  : passiveButtonStyle),
              }}
              component="button"
              type="button"
              onClick={() => {
                setPageState('SIGN_IN');
                history.push('/signin');
              }}
            >
              Login
            </Box>
          </Stack>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              minHeight: '474px',
              height: {
                xs: '474px',
                sm: '474px',
                md: 'calc(100% - 45px)',
                lg: 'calc(100% - 45px)',
              },
              backgroundColor: '#fff',
              borderRadius: { xs: '0px', sm: '13px', md: '13px', lg: '13px' },
              padding: '24px',
              boxShadow: '0px 0px 24px rgba(0,0,0, 0.1)',
              position: 'relative',
            }}
          >
            <Typography variant="h2">
              {pageState === 'SIGN_UP'
                ? 'Create an account'
                : pageState === 'SIGN_IN'
                ? 'Login to your account'
                : ''}
            </Typography>
            {pageState === 'SIGN_IN' ? (
              <Login register={register} />
            ) : pageState === 'SIGN_UP' ? (
              <Register register={register} agree={agree} setAgree={setAgree} />
            ) : (
              <Confirm
                onCodeResend={onCodeResend}
                onCancel={onCancel}
                register={register}
              />
            )}
            <Box
              component="button"
              type="submit"
              disabled={
                pageState === 'SIGN_UP' ? !agree || !password : !password
              }
              sx={{
                position: 'absolute',
                bottom: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: loading ? 'center' : 'space-between',
                backgroundColor: 'rgba(255,255,255, 1)',
                width: 'calc(100% - 40px)',
                height: '40px',
                padding: '10px',
                borderRadius: '28px',
                boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
                transition: '0.3s',
                cursor: 'pointer',
                border: '1px solid rgba(0,0,0,0)',
                '&:disabled': {
                  border: '1px solid rgba(0,0,0,0.08)',
                  opacity: 0.6,
                  cursor: 'not-allowed',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255, 1)',
                  },
                },
                '&:hover': {
                  backgroundColor: '#D3D3D3',
                },
              }}
            >
              {loading ? (
                <BiLoaderAlt className="loader" />
              ) : (
                <>
                  <Typography
                    textAlign="center"
                    fontSize={{
                      xs: '15px',
                      sm: '15px',
                      md: '15px',
                      lg: '15px',
                    }}
                    fontWeight="500"
                    color="#313233"
                    sx={{
                      width: '100%',
                    }}
                  >
                    {pageState === 'SIGN_UP'
                      ? 'Create an account'
                      : pageState === 'SIGN_IN'
                      ? 'Go to passport'
                      : 'Confirm'}
                  </Typography>
                  <AtomsIconsPlay sx={{ fontSize: '18px' }} />{' '}
                </>
              )}
            </Box>
          </Box>
        </Box>
        {/* Footer Link Desktop */}
        <MuiLink
          sx={{
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
              lg: 'none',
            },
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '10vh !important',
            textDecorationColor: 'rgba(255,255,255,0.5)!important',
            '&:hover': {
              textDecorationColor: 'rgba(255,255,255,1)!important',
            },
            transition: '0.3s',
            color: 'white',
            fontSize: '14px',
            textDecoration: 'underline',
          }}
          onClick={onAboutCreativePassportOpen}
        >
          About the project
        </MuiLink>
      </Box>
    </Box>
  );
};
export default AuthPage;
