const styles = (theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    transform: 'uppercase',
    fontSize: '18pt',
    textAlign: 'center',
  },
  title_box: {
    padding: '1em',
    '& .MuiIconButton-root': {
      float: 'right',
      position: 'absolute',
      right: '0.2em',
      top: '0.2em',
      marginBottom: '0.5em',
    },
  },
  dialog_text: {
    textTransform: 'none',
    textAlign: 'justify',
    marginTop: '1em',
  },
});

export default styles;
