import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../Header';
import Footer from '../Footer';
import AnnouncementDialog from '../AnnouncementModal';
import NewUser from '../../containers/newuser';
import useOptinPassport from '../../hooks/useOptinPassport';
import ProProfileMigration from '../../containers/ProProfileMigration';
import { useProfileContext } from '../../containers/passportData/context';
import useServerProfile from '../../hooks/useServerProfile';
import { useAuthManager } from '../auth/AuthProvider';
import { getIsOptlessProfile } from '../../scripts';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    //Dashboard background colour
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '1rem',
    },
    paddingBottom: '2rem',
    width: '100vw',
  },
  loadingContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
}));

const OptinsWrapper = (props) => {
  const classes = useStyles();
  const authManager = useAuthManager();
  const { requestProfileOptin, isLoadingOptin } = useOptinPassport();
  const { profile, isLoading: isLoadingProfile } = useServerProfile();
  const { dispatch } = useProfileContext();
  const queryClient = useQueryClient();
  // States: init, brand-new, new-to-redesign, no-pro-profile, starting-fresh, normal, migration-error
  const [onboardingState, setOnboardingState] = React.useState('init');
  const devOptinOverride = process.env.REACT_APP_OPTIN_OVERRIDE === 'true';

  // Runs once on mount, set initial user state (who just opened the page?)
  useEffect(() => {
    const setInitialStateAsync = async () => {
      const isOptlessData = await authManager
        .getJwtToken()
        .then((jwtToken) => getIsOptlessProfile(jwtToken))
        .then((responseData) => responseData.data);

      setOnboardingState((prevState) => {
        if (prevState !== 'init') {
          toast.error('Error: user state already initialised');
          return prevState;
        }
        if (isOptlessData.hasOptinProfile && !devOptinOverride) {
          // Here we load Passport data into state
          // TODO save VerifiedFields to state, stop using serverProfile
          dispatch({
            type: 'POPULATE',
            payload: profile?.PassportData,
          });
          if (
            (profile?.PassportData?.artistProfiles || []).some(
              (ap) => ap.isProfessionalProfile
            )
          ) {
            return 'normal';
          } else {
            return 'no-pro-profile';
          }
        } else {
          // !hasOptinProfile
          if (isOptlessData.hasOldProfile) {
            return 'new-to-redesign';
          } else {
            // !hasOptinProfile && !hasOldProfile
            return 'brand-new';
          }
        }
      });
    };
    setInitialStateAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const agreeToOptin = async () => {
    if (onboardingState !== 'new-to-redesign') {
      toast.error(`Error: cannot optin from state: ${onboardingState}`);
      return;
    }
    try {
      setOnboardingState('no-pro-profile');
      // Here we load passport data into state on optin
      const newServerData = await requestProfileOptin();
      dispatch({
        type: 'POPULATE',
        payload: newServerData.PassportData,
      });
      await queryClient.invalidateQueries('profileData');
    } catch (err) {
      setOnboardingState('migration-error');
      toast.error(
        'Could not migrate profile. Please contact the support team.'
      );
    }
  };

  const disagreeOptin = () => {
    setOnboardingState((prevState) => {
      if (prevState !== 'new-to-redesign') {
        toast.error(`User state error: declining optin from ${prevState}`);
        return prevState;
      }
      return 'starting-fresh';
    });
  };

  const isLoading =
    isLoadingProfile || isLoadingOptin || onboardingState === 'init';
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header signedIn={onboardingState === 'normal'} />
      <div className={classes.container}>
        <main className={classes.content}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : ['brand-new', 'starting-fresh'].includes(onboardingState) ? (
            <NewUser />
          ) : onboardingState === 'new-to-redesign' ? (
            <AnnouncementDialog
              onAgree={agreeToOptin}
              onDisagree={disagreeOptin}
            />
          ) : onboardingState === 'no-pro-profile' ? (
            <ProProfileMigration
              onMigrated={() => setOnboardingState('normal')}
            />
          ) : onboardingState === 'normal' ? (
            <div>{props.children}</div>
          ) : onboardingState === 'migration-error' ? (
            'Error migrating profile, please contact support'
          ) : (
            `Error: unknown user state: ${onboardingState}`
          )}
        </main>
        <Footer />
      </div>
    </div>
  );
};

OptinsWrapper.displayName = 'OptinsWrapper';

export default OptinsWrapper;
