import { Box, Stack, Typography } from '@mui/material';
import TitleInfo from '../Title';
import IconTextButton from '../IconTextButton';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import CPAddButton from '../CPAddButton';
import Plus from '../../icons/atomsIconsAdd';
import { useMutatePatchProfile } from '../../hooks/useMutateServerProfile';
import toast from 'react-hot-toast';
import Modal from '../ModalNoForm';
import shortid from 'shortid';
import { Project } from './Project';
import { toggleIsPublished } from '../../services.js';
import { useProfileContext } from '../../containers/passportData/context';
import PassportIcon from '../../icons/idIcon';
import DraggableProjectItem from './DraggableProjectItem';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  main: {
    width: '100%',
    padding: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '18px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  edit: {
    padding: '0.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    gap: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
      margin: 'auto',
    },
  },
}));

const Projects = ({ id, customProjects }) => {
  const projectList = customProjects?.value || [];
  const [localProjects, setLocalProjects] = useState(projectList);
  const [edit, setEdit] = useState(false);
  const { mutateAsync: patchProfileAsync, isLoading: isPatchingProfile } =
    useMutatePatchProfile();
  const classes = useStyles();
  const { dispatch } = useProfileContext();

  const handleClose = () => {
    setEdit(false);
  };

  const addCustomField = () => {
    const newProject = {
      uniqueKey: shortid.generate(),
      fieldName: '',
      value: '',
      publishers: [],
    };
    setLocalProjects([...localProjects, newProject]);
  };

  const handleSubmit = async () => {
    if (localProjects.some((lp) => !lp.fieldName)) {
      return toast.error('Required field: Project Name');
    }
    const payload = {
      id,
      customProjects: { value: localProjects },
    };

    try {
      dispatch({ type: 'PATCH_PROFILE', payload: payload });
    } catch (err) {
      return toast.error('Error saving profile (local)');
    }

    try {
      await patchProfileAsync(payload);
    } catch (err) {
      return toast.error('Error saving profile (remote)');
    }

    handleClose();
    toast.success('Profile info updated successfully');
  };

  const handlePrivacy = (key) => {
    setLocalProjects((state) =>
      state.map((el) => {
        if (el.uniqueKey === key) {
          return {
            ...el,
            publishers: toggleIsPublished(el),
          };
        }
        return el;
      })
    );
  };

  const handleChange = (patch) =>
    setLocalProjects((state) =>
      state.map((proj) => {
        if (proj.uniqueKey === patch.uniqueKey) {
          return { ...proj, ...patch };
        }
        return proj;
      })
    );

  const handleDelete = (uniqueKey) =>
    setLocalProjects((state) =>
      state.filter((proj) => proj.uniqueKey !== uniqueKey)
    );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // Adjust this value as needed
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = localProjects.findIndex(
        (proj) => proj.uniqueKey === active.id
      );
      const newIndex = localProjects.findIndex(
        (proj) => proj.uniqueKey === over.id
      );

      setLocalProjects((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <>
      <Modal
        open={edit}
        onClose={handleClose}
        onSave={handleSubmit}
        isLoading={isPatchingProfile}
        aria-labelledby="edit/create Project"
        aria-describedby="editing and creating Projects"
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={localProjects.map((el) => el.uniqueKey)}
            strategy={verticalListSortingStrategy}
          >
            {localProjects.map((el, index) => (
              <DraggableProjectItem
                key={el.uniqueKey}
                id={el.uniqueKey}
                projectData={el}
                handleChange={handleChange}
                handleDelete={handleDelete}
                handlePrivacy={handlePrivacy}
              />
            ))}
          </SortableContext>
        </DndContext>
        <CPAddButton size="small" onClick={addCustomField}>
          ADD A CUSTOM PROJECT
        </CPAddButton>
      </Modal>
      <Box className={classes.root}>
        <TitleInfo
          title="Projects"
          isNotEmpty={projectList.some((el) => el.fieldName)}
          message="Edit Projects"
          onClick={() => setEdit(true)}
        />
        {projectList.some((el) => el.fieldName) ? (
          <Stack spacing={2}>
            {projectList.map((el) => (
              <Project key={el.uniqueKey} item={el} />
            ))}
          </Stack>
        ) : (
          <Box className={classes.main}>
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <PassportIcon sx={{ width: '32px' }} />
              <Typography variant="body1">
                If you have any custom projects you can list them here
              </Typography>
              <IconTextButton
                onClick={() => setEdit(true)}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your custom projects
              </IconTextButton>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Projects;
